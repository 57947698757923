import styled from 'styled-components';

export const HeaderWrapper = styled.div`
    width: 100%;
    padding: 2rem;
    h3{
        text-align: center;
    }
    .back-arrow{
        cursor: pointer;
    }
    @media screen and (min-width: 768px) {
        font-size: 2rem;
    }
    
`;