import React, { useContext, useState } from "react";
import QRCode from "../../components/QRCode";
import QRCodeGen from "qrcode";
import { UserContext } from "../../store/userStore/user.context";
import { PaymentContainer } from "./styles";
// import FileSaver from "file-saver";
import { toast } from "react-toastify";

import enviroment from "../../environment";
const { appUrl } = enviroment;

const Payment = () => {
  const user = useContext(UserContext);
  const { userName } = user;
  const [qrCode, setQrCode] = useState(null);
  const [paymentQrCode, setPaymentQrCode] = useState(null);
  const [showPaymentCopyIcon, setShowPaymentCopyIcon] = useState(true);

  const userUrl = `${appUrl}/${userName}`;
  const userPaymentUrl = `${appUrl}/@${userName}`;

  QRCodeGen.toDataURL(userUrl)
    .then((url) => {
      setQrCode(url);
    })
    .catch((err) => {
      console.error(err);
    });

  QRCodeGen.toDataURL(userPaymentUrl)
    .then((url) => {
      setPaymentQrCode(url);
    })
    .catch((err) => {
      console.error(err);
    });

  // const downloadToGallery = () => {
  //   FileSaver.saveAs(qrCode, user.storeName + "swiftpay.png");
  // };

  const downloadToGallery = () => {
    const qrCodeURL = document.getElementById('qrCodeEl').getAttribute('src')
      // .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = `${user.storeName}swiftpay.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const handleCopyToClipboard = (text) => async () => {
    try {
      if (navigator?.clipboard) {
        await navigator.clipboard.writeText(text);
      } else {
        const tempInput = document.createElement("input");
        tempInput.style = "opacity: 0";
        tempInput.value = text;

        document.body.appendChild(tempInput);
        tempInput.select();

        document.execCommand("copy");
        document.body.removeChild(tempInput);
      }
      // newer browsers
      toast.success("Payment link copied successfully");
      setShowPaymentCopyIcon(false);
    } catch {
      setShowPaymentCopyIcon(true);
    }
  };


  // const downloadToGalleryPaymentQR = () => {
  //   FileSaver.saveAs(paymentQrCode, user.storeName + "swiftpay.png");
  // };

  const downloadToGalleryPaymentQR = () => {
    const qrCodeURL = document.getElementById('qrCodeEl').getAttribute('src')
      // .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download =`${user.storeName}swiftpay.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const handlePaymentCopyToClipboard = (text) => async () => {
    try {
      if (navigator?.clipboard) {
        await navigator.clipboard.writeText(text);
      } else {
        const tempInput = document.createElement("input");
        tempInput.style = "opacity: 0;";
        tempInput.value = text;

        document.body.appendChild(tempInput);
        tempInput.select();

        document.execCommand("copy");
        document.body.removeChild(tempInput);
      }
      // newer browsers
      toast.success("Payment link copied successfully");

      setShowPaymentCopyIcon(false);
    } catch {
      setShowPaymentCopyIcon(true);
    }
  };

  const paymentDetails = {
    type: "product",
    text: "Copy and share this link to get your customers to view all your products and make payments.",
    url: userUrl,
    qrImage: qrCode,
    download: downloadToGallery,
    copy: handleCopyToClipboard(userUrl),
  };

  const paymentLink = {
    type: "payment",
    text: "Click and share your payment link with your customers to receive payments.",
    url: userPaymentUrl,
    qrImage: paymentQrCode,
    download: downloadToGalleryPaymentQR,
    copy: handlePaymentCopyToClipboard(userPaymentUrl),
  };

  return (
    <PaymentContainer>
      <QRCode details={paymentDetails} />
      <QRCode details={paymentLink} />
    </PaymentContainer>
  );
};

export default Payment;
