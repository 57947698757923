import React, { useState, useContext, useEffect } from "react";
import {
  PaymentCheckoutContainer,
  PaymentCheckoutWrapper,
} from "./checkoutstyles";
import { MdPayment } from "react-icons/md";
// import { AiOutlineBank } from "react-icons/ai";
import { RiFolderTransferLine } from "react-icons/ri";
import { FaMobileAlt } from "react-icons/fa";
// import { BiBarcodeReader } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../../store/userStore/user.context";
import { LocalSetAccNum, LocalSetPaymentId } from "../../utils/localStorage";
import TitleComponent from "../../components/TitleComponent";
import environment from "../../environment";

const PaymentCheckout = ({ ...props }) => {
  const test = props.location?.state?.detail;
  const searchParams = props.location?.search;
  const user = useContext(UserContext);
  const { vendor, product, goto, totalAmount } = test;
  const [useUSSDPaymentType, setUseUSSDPaymentType] = useState(false);

  let amount =
    goto === "sendmoney" || goto === "adhocpayment"
      ? product?.amount
      : totalAmount;

  useEffect(() => {
    if (
      !useUSSDPaymentType &&
      amount <= environment.ussdConfig.MAXIMUM_USSD_AMOUNT
    ) {
      setUseUSSDPaymentType(true);
    }
  }, [amount, totalAmount, useUSSDPaymentType]);
  let history = useHistory();
  const handleCancelPayment = () => {
    if (user) {
      history.push("/dashboard/products");
    } else {
      window.location.href = `${vendor.secondaryUrl}`;
    }
  };

  const userDetail = vendor?.transactionCurrency
    ? vendor?.transactionCurrency?.toUpperCase()
    : user?.transactionCurrency?.toUpperCase();

  return (
    <PaymentCheckoutWrapper>
      <PaymentCheckoutContainer>
        <div className="container">
          <TitleComponent title="Payment Method" />
          {userDetail === "INTERNATIONAL" ||
          (userDetail === "LOCAL" && product?.currency === "NGN") ? (
            <>
              <div className="payment-title">SELECT PAYMENT TYPE</div>
              <p className="payment-text">
                Use one of the payment methods below to pay {product?.currency}{" "}
                {totalAmount || amount} to
                <span className="text-capitalize">{vendor.storeName}</span>.
              </p>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  let path = null;
                  if (goto === "sendmoney") {
                    path = "cardpayment";
                  } else if (goto === "adhocpayment") {
                    path = "adhocpayment";
                  } else {
                    path = `/${
                      user?.defaultUrl || vendor?.defaultUrl
                    }/continue`;
                    // path = 'cardpayment'
                  }

                  history.push({
                    pathname: path,
                    search: `${searchParams}`,
                    state: { detail: test },
                  });
                }}
              >
                <div className="payment-type1">
                  <MdPayment />
                  <span>Pay with Card</span>
                </div>
              </Link>
              {/* {product?.currency !== "USD" ? (
                <Link
                  to={{
                    pathname: `/${
                      user?.defaultUrl || vendor?.defaultUrl
                    }/vnuban`,

                    search: `${searchParams}`,

                    state: { detail: test },
                  }}
                >
                  <div className="payment-type2">
                    <AiOutlineBank />
                    <span>Pay with Transfer</span>
                  </div>
                </Link>
              ) : (
                <>
                  <Link
                    to={{
                      pathname: `/${
                        user?.defaultUrl || vendor?.defaultUrl
                      }/checkout`,
                      search: `${searchParams}`,
                      state: { detail: test },
                    }}
                    onClick={(e) => {
                      LocalSetAccNum(null);
                    }}
                  >
                    <div className="payment-type2">
                      <AiOutlineBank />
                      <span>Pay with Transfer</span>
                      <p
                        style={{
                          color: "#ffa500",
                          fontSize: "12px",
                          paddingTop: "5px",
                          paddingLeft: "1.5rem",
                        }}
                      >
                        {" "}
                        You can only pay for products in NGN with this payment
                        type
                      </p>
                    </div>
                  </Link>
                </>
              )} */}

              {product?.currency !== "USD" ? (
                <Link
                  to={{
                    pathname: `/${
                      user?.defaultUrl || vendor?.defaultUrl
                    }/acct-debit`,

                    search: `${searchParams}`,

                    state: { detail: test },
                  }}
                >
                  <div className="payment-type3">
                    <RiFolderTransferLine />
                    <span>Pay with Account Debit</span>
                  </div>
                </Link>
              ) : (
                <Link
                  to={{
                    pathname: `/${
                      user?.defaultUrl || vendor?.defaultUrl
                    }/checkout`,

                    search: `${searchParams}`,

                    state: { detail: test },
                  }}
                >
                  <div className="payment-type3">
                    <RiFolderTransferLine />
                    <span>Pay with Account Debit</span>
                    <p
                      style={{
                        color: "#ffa500",
                        fontSize: "12px",
                        paddingTop: "5px",
                        paddingLeft: "1.5rem",
                      }}
                    >
                      {" "}
                      You can only pay for products in NGN with this payment
                      type
                    </p>
                  </div>
                </Link>
              )}

              {/* {useUSSDPaymentType ? product?.currency !== "USD" ? (
                <Link
                  to={{
                    pathname: `/${
                      user?.defaultUrl || vendor?.defaultUrl
                    }/ussd-pay`,
                    search: `${searchParams}`,
                    state: { detail: test },
                  }}
                >
                  <div className="payment-type4">
                    <FaMobileAlt />
                    <span>Pay with USSD </span>
                  </div>
                </Link>
              ) : (
                <Link
                  to={{
                    pathname: `/${
                      user?.defaultUrl || vendor?.defaultUrl
                    }/checkout`,
                    search: `${searchParams}`,
                    state: { detail: test },
                  }}
                  onClick={(e) => {
                    LocalSetPaymentId(null);
                  }}
                >
                  <div className="payment-type4">
                    <FaMobileAlt />
                    <span>Pay with USSD </span>
                    <p
                      style={{
                        color: "#ffa500",
                        fontSize: "12px",
                        paddingTop: "5px",
                        paddingLeft: "1.5rem",
                      }}
                    >
                      {" "}
                      You can only pay for products in NGN with this payment
                      type
                    </p>
                  </div>
                </Link>
              ): <></>} */}

              {/* <Link to="/progress">
                <div className="payment-type5">
                  <BiBarcodeReader />
                  <span> Pay with NQR Code </span>
                </div>
              </Link> */}
            </>
          ) : (
            <p className="payment-text">
              This Vendor does not accept {product?.currency} at the moment
            </p>
          )}
          <br />
          <br />
        </div>

        <div className="checkout-btn" onClick={handleCancelPayment}>
          {" "}
          Cancel Payment
        </div>

        <div className="footer">
          <span>
            <img
              src="/favicon.ico"
              style={{ width: "40px", height: "40px" }}
              alt="access logo"
            />
            <i>Verified by Access</i>
          </span>
        </div>
      </PaymentCheckoutContainer>
    </PaymentCheckoutWrapper>
  );
};
export default PaymentCheckout;
