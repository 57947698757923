import $http from '../../services/http'
import { decryptPayload } from '../../utils/helpers';

/**
 *
 * @param response
 * @returns {HttpResponse}
 * @constructor
 */

function HttpResponse (response) {
   let decrytedResponse =response?.data && decryptPayload(response?.data?.data || response?.data || response)
  try {
    this.response = decrytedResponse;
    const { session, signature } =  this.response;
    this.encrypted = this.response;
    this.session = session;
    this.signature = signature;
  } catch (e) {
    this.response = e.response?.data || e.error;
  }

  this.decrypt = function(){
    if (this.session && this.signature) {
      const resp = "{}" // aes.decrypt(this.session , this.signature)
      this.response = JSON.parse (resp || [resp]);
      // if (typeof this.response === 'string') {
      //   this.response = { message: this.response };
      // }
    }
    return this.response;
  }
  return this;
}
// encryption can be disabled for get requests
export const getRequest = async  (url, encrypt=true, decrypt=true) => {
  let headers = {}
  if  (encrypt ) {
    headers = { 'secured-space': new Date().getTime() }
  }
  const httpResp = new HttpResponse(await $http.get(url, {
    headers
  }));
  return decrypt ? httpResp.decrypt() : httpResp;
}

// all post request must be encrypted
export const postRequest = async  (url, data, decrypt=true) => {
  try {
  
    // data = aes.createAesMessage(data);
    // data = { session: data.base64, signature: data.payload }
  
    const httpResp = new HttpResponse(await $http.post(url, data));
    return decrypt ? httpResp.decrypt() : httpResp;
  } catch (e) {
    return {}
  }
}
export default HttpResponse;
