import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import { Link } from "react-router-dom";
import { injectStyle } from "react-toastify/dist/inject-style";
import defaultImage from "../../assets/svg/user.svg";
import Button from "../../components/Button";
import useAxios from "../../components/customHook/useAxios";
import CustomSelect from "../../components/CustomSelect";
import Error from "../../components/Error";
import ImgPrev from "../../components/ImagePreview";
import InputField from "../../components/Input";
import { toastify } from "../../components/Toast";
import environment from "../../environment";
import { updateProfileSchema } from "../../schema/auth";
import AuthService, { getToken } from "../../services/authentication";
import { UserContext } from "../../store/userStore/user.context";
import categories from "../../utils/categories";
import { Header, InputContainer, UpdateProfileContainer } from "./styles";

if (typeof window !== "undefined") {
  injectStyle();
}

const UpdateProfile = () => {
  const user = useContext(UserContext);

  const [loginUser, { data, error }, isLoading] = useAxios("post");

  const [profileImage, setProfileImage] = useState("");

  const [options, setOptions] = useState();

  useEffect(() => {
    const mappedCategories = categories.map((currentVal) => ({
      value: currentVal,
      label: `${currentVal[0].toUpperCase()}${currentVal.slice(1)}`,
    }));

    setOptions(mappedCategories);
  }, []);

  const imgSrc =
    user.logo !== false && user.logo !== undefined && user.logo !== ""
      ? environment.BACKEND_URL + "/utils/image/" + user.logo
      : defaultImage;

  const [imgError, setImgError] = useState("");

  const handleImage = (value) => {
    if (value.size > 1000000) {
      setImgError("Image size should be less than 1MB");
      return;
    } else {
      setProfileImage(value);
      setImgError("");
    }
  };

  // if (data && !isLoading && !error) {
  //   let remember = false;
  //   if (localStorage.getItem("dpAuth")) {
  //     remember = true;
  //   }
  //   AuthService({ ...data, remember, token: getToken() });

  // }

  const [errorMessage] = useState("");
  const formik = useFormik({
    initialValues: {
      ...user,
    },
    validationSchema: updateProfileSchema,
    onSubmit: async (values) => {
      const data = Object.keys(values);
      const formData = new FormData();
      if (imgError) return;
      if (!profileImage && !user.logo) {
        setImgError("Profile Image is required");
        return;
      }
      data.forEach((key) => formData.append(key, values[key]));
      formData.set("logo", profileImage || user.logo);
      if (!isLoading && formData && data) {
        try {
          await loginUser(
            "/account/vendor/update",
            formData,
            false,
            null,
            true
          );
          if (!error && !isLoading && data.length) {
            // toastify("Profile Updated Successfully", "success");
          } else {
            toastify("Profile Update Failed", "error");
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },
  });

  if (data && !isLoading && !error) {
    let remember = false;
    if (localStorage.getItem("dpAuth")) {
      remember = true;
    }
    AuthService({ ...data, remember, token: getToken() });
    // if (!error) {
    // toastify("Profile updated successfully!", "success");
    // } else {
    //   toastify("Profile updated failed!", "error");
    // }
  }

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        style={{ background: "#fff", boxShadow: "none" }}
      >
        <UpdateProfileContainer>
          <Header className="update-profile-header">Update Profile</Header>
          <ImgPrev
            name="logo"
            imgs={handleImage}
            imgSrc={imgSrc}
            defaultImage={defaultImage}
            className="image-wrapper"
          />
          {/* {formik.touched.logo && formik.errors.logo ? (
            <div>{formik.errors.logo}</div>
          ) : null} */}

          {imgError && <div className="img-error">{imgError}</div>}
          <InputContainer>
            <InputField
              inputTitle="Access Bank Account Number"
              className="input-field"
              type="number"
              name="accountNumber"
              id="accountNumber"
              // value={accountNumber}
              onChange={formik.handleChange}
              value={formik.values.accountNumber}
              disabled
            />
            {formik.touched.accountNumber && formik.errors.accountNumber ? (
              <div>{formik.errors.accountNumber}</div>
            ) : null}

            <InputField
              name="storeName"
              id="storeName"
              inputTitle="Store or business name"
              // value={storeName}
              // onChange={handleChange}
              onChange={formik.handleChange}
              value={formik.values.storeName}
              disabled
            />

            <CustomSelect
              options={options}
              name="businessType"
              label="Business category"
              value={formik.values.businessType}
              onChange={(value) =>
                formik.setFieldValue("businessType", value.value)
              }
              isSearch
            />
            <InputField
              name="userName"
              id="userName"
              inputTitle="Unique id/username"
              // value={userName}
              // onChange={handleChange}
              onChange={formik.handleChange}
              value={formik.values.userName}
              disabled
            >
              <p className="colored-span">(Alphabet Only)</p>
            </InputField>
            <InputField
              name="email"
              id="email"
              inputTitle="Your email address"
              placeholder="example@email.com"
              // defaultValue={email}
              // onChange={handleChange}
              onChange={formik.handleChange}
              defaultValue={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <Error>{formik.errors.email || errorMessage}</Error>
            ) : null}
          </InputContainer>

          {error && <Error>{error.error}</Error>}

          <Button
            type="submit"
            className="profile-update-button"
            text={
              isLoading ? (
                <Spinner
                  animation="grow"
                  role="status"
                  variant="light"
                  size="sm"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Save changes"
              )
            }
            disabled={error}
          />
          <p className="password-link">
            <Link
              to={{
                pathname: "/change-password",
                state: user.id,
              }}
            >
              Change Password
            </Link>
          </p>
        </UpdateProfileContainer>
      </form>
    </>
  );
};

export default UpdateProfile;
