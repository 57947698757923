import styled from "styled-components";

export const Container = styled.div`
    margin-bottom: 1.5rem;
  label {
    font-size: 1em;
    width: 100%;
    text-align: left;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: baseline;
  }
`;
