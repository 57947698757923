import React, { useContext, memo } from "react";
import { NavLink } from "react-router-dom";
import {
  Container,
  Greetings,
  HamburgerWrapper,
  HeaderContainer,
  Top,
  FullScreen,
  Title,
  LogOutWrapper,
} from "./Header.styles";
import Hamburger from "../Hamburger";
import { UserContext } from "../../store/userStore/user.context";
import { Logout } from "../../services/authentication";
import { AiOutlineLogout } from "react-icons/ai";
import Sidebar from "../Sidebar";

function Header({ storeName }) {
  const user = useContext(UserContext);
  return (
    <Container>
      <FullScreen>
        <HamburgerWrapper>
          <Sidebar user={user} />
        </HamburgerWrapper>
      </FullScreen>
      <HeaderContainer>
        <Top>
          <Greetings>{storeName}</Greetings>
          <HamburgerWrapper>
            <Hamburger user={user} />
          </HamburgerWrapper>
          <Title>{storeName}</Title>
          
          <LogOutWrapper>
            {  user ?<NavLink
              to="/login"
              activeClassName="active-navbar-link"
              className="navbar-links"
              style={{ textDecoration: "none", padding:'0 0.2rem' }}
            >
              <AiOutlineLogout className="navbar-icons" />
              <span className="navbar-text" onClick={Logout}>
                Logout
              </span>
            </NavLink>:<div></div> }
            
          </LogOutWrapper>
          
          
        </Top>
      </HeaderContainer>
    </Container>
  );
}

export default memo(Header);
