import styled from 'styled-components';

export const Container = styled.div`
    /* width: 12rem;
    height: 12rem;
    margin: auto;
    margin-bottom: 3rem; */
    cursor: pointer;
    /* height: 20rem; */
`;

export const IsShowingWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid blue; */
    /* text-align: center; */
    margin-bottom: 1.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    max-width: 20rem
    
`;
// export const IsHiddenWrapper = styled.div`
//     display: flex;
//     /* align-items: center; */
//     flex-direction: column;
//     justify-content: center;
//     /* border: 1px solid blue; */
//     /* text-align: center; */
//     margin-bottom: 1.5rem;

//     .link{
//         a{
//             font-size: 0.6rem;
//         }
//     }
// `;

export const ImagePreviewWrapper = styled.div`
    width: 8rem;
    height: 8rem;
    margin: 1rem 0;

    /* border: 1px solid red; */

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    @media screen and (min-width: 800px) {
        width: 10rem;
        height: 10rem;
    }
`;
export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
   cursor: pointer;
   margin-bottom: 1rem;
   
`;

export const TextWrapper = styled.div`
display: flex;
flex-direction: row;
column-gap: 0.5rem;

    font-size: 1rem;
    font-weight: 600;

    @media screen and (min-width: 371px) {
        font-size: 0.8rem;
       h5{
        font-size: 0.8rem;
       font-weight: 600;
       } 
   }
   @media screen and (min-width: 800px) {
        font-size: 0.8rem;
       h5{
        font-size: 1rem;
        font-weight: 600;
       } 
   }
`
