import styled from "styled-components";

export const PaymentCheckoutWrapper = styled.div`
  display: grid;
  place-content: center;
  margin: auto;
  padding-top: 5rem;
`;

export const PaymentCheckoutContainer = styled.div`
  max-width: 25rem;
  /* margin: 0 auto; */

  .container {
    padding: 1.25rem 1.25rem 0rem;
    background-color: #f8f8ff;
    color: #003883;
  }
  .payment-title {
    padding-bottom: 0.625rem;
    font-weight: bold;
    font-size: 1.125rem;
  }
  .payment-text {
    opacity: 0.6;
    padding-bottom: 0.313rem;
  }

  .payment-type1 {
    border: 1px solid #dcdcdc;
    padding: 0.938rem 0rem;
    border-left: none;
    border-right: none;
  }
  .payment-type2 {
    border: 1px solid #dcdcdc;
    padding: 0.938rem 0rem;
    border-top: none;
    border-left: none;
    border-right: none;
  }
  .payment-type3 {
    border: 1px solid #dcdcdc;
    padding: 0.938rem 0rem;
    border-top: none;
    border-left: none;
    border-right: none;
  }
  .payment-type4 {
    border: 1px solid #dcdcdc;
    padding: 0.938rem 0rem;
    border-top: none;
    border-left: none;
    border-right: none;
  }
  .payment-type5 {
    border: 1px solid #dcdcdc;
    padding: 0.938rem 0rem;
    border-top: none;
    border-left: none;
    border-right: none;
  }

  span {
    padding-left: 0.625rem;
    font-weight: bold;
  }

  .checkout-btn {
    margin: 1.875rem 1.25rem;
    color: #f44336;
    justify-content: center;
    padding: 0.938rem 0rem;
    display: flex;
    border: 1px solid #dcdcdc;
    border-radius: 0.625rem;
    cursor: pointer;
  }
  .footer {
    margin: 5rem 0;
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;
