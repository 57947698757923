import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import axios from "axios";
import { injectStyle } from "react-toastify/dist/inject-style";
import Button from "../../components/Button";
import InputField from "../../components/Input";
import environment from "../../environment";
import { isValidHttpUrl } from "../../schema/auth";
import { StyleApi, InputContainer, UpdateProfileContainer } from "./styles";

if (typeof window !== "undefined") {
  injectStyle();
}

const API = () => {
  const [credentials, setCredentials] = useState(null);

  const [callbackUrl, setCallbackUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [hasRequestedForApiCredentials, setHasRequestedForApiCredentials] =
    useState(false);
  const [hasRequestedToBeACluster, setHasRequestedToBeACluster] =
    useState(false);
  const [apiStatus, setAPIStatus] = useState(null);
  const [clusterStatus, setClusterStatus] = useState(null);
  const [clusterCode, setClusterCode] = useState(null);

  const handleClusterRequest = async () => {
    try {
      setLoading(true);
      await axios.get(
        `${environment.BACKEND_URL}/account/vendor/cluster/request`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dpAuth")}`,
          },
        }
      );
      setHasRequestedToBeACluster(true);
      setClusterStatus("PENDING");
      handleGetVendorProfile();
      return;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // handleGetCallback();
    // handleGetUserCredentials();
    handleGetVendorProfile();
  }, []);

  useEffect(() => {
    if (apiStatus === "APPROVED") {
      handleGetUserCredentials();
    }
  }, [apiStatus]);

  const handleGetUserCredentials = async () => {
    try {
      const apiCredentials = await axios.get(
        `${environment.BACKEND_URL}/account/vendor/api-credential/retrieve`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dpAuth")}`,
          },
        }
      );

      setCredentials(apiCredentials.data.data);
    } catch (error) {}
  };

  const handleAPICredentialsRequest = async () => {
    try {
      await axios.get(
        `${environment.BACKEND_URL}/account/vendor/api-credential/request`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dpAuth")}`,
          },
        }
      );
      setHasRequestedForApiCredentials(true);
      setAPIStatus("PENDING");
      handleGetVendorProfile();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleGetCallback = async () => {
    try {
      const callback = await axios.get(
        `${environment.BACKEND_URL}/account/vendor/callback/retrieve`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dpAuth")}`,
          },
        }
      );
      setCallbackUrl(callback.data.data.secondaryUrl);
    } catch (error) {}
  };

  const handleGetVendorProfile = async () => {
    try {
      const vendor = await axios.get(
        `${environment.BACKEND_URL}/account/vendor`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dpAuth")}`,
          },
        }
      );
      setHasRequestedForApiCredentials(
        vendor.data.data.apiRequestStatus ? true : false
      );
      setHasRequestedToBeACluster(
        vendor.data.data.clusterRequestStatus ? true : false
      );
      setAPIStatus(vendor.data.data.apiRequestStatus);
      setClusterStatus(vendor.data.data.clusterRequestStatus);
      setClusterCode(vendor.data.data.clusterCode)
    } catch (error) {}
  };

  const onChangeCallbackUrl = (e) => {
    const Callback = e.target.value;
    setCallbackUrl(Callback);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      setMessage("");
      if (!isValidHttpUrl(callbackUrl)) {
        setMessage("Invalid callback url");
        setIsLoading(false);
        return;
      }
      await axios.post(
        `${environment.BACKEND_URL}/account/vendor/callback/update`,
        { callbackUrl },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dpAuth")}`,
          },
        }
      );
      setIsLoading(false);
    } catch (error) {
      setMessage(error.response.data.error);
      handleGetCallback();
      setIsLoading(false);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{ background: "#fff", boxShadow: "none" }}
      >
        <UpdateProfileContainer>
          <InputContainer>
            <StyleApi>
              <div className="d-flex" style={{ marginBottom: "20px" }}>
                <label>API details</label>
                <div
                  style={{ margin: "auto", marginRight: "0px" }}
                  onClick={handleAPICredentialsRequest}
                >
                  <button
                    className={`btn btn-md ${
                      apiStatus === "APPROVED" ? "btn-success" : "btn-primary"
                    }`}
                    disabled={hasRequestedForApiCredentials}
                  >
                    {hasRequestedForApiCredentials ? apiStatus : "Request"}
                  </button>
                </div>
              </div>
              <div className="d-flex">
                <label>Request to be a cluster</label>
                <div
                  style={{ margin: "auto", marginRight: "0px" }}
                  onClick={handleClusterRequest}
                >
                  <button
                    className={`btn btn-md ${
                      clusterStatus === "APPROVED"
                        ? "btn-success"
                        : "btn-primary"
                    }`}
                    disabled={hasRequestedToBeACluster}
                  >
                    {hasRequestedToBeACluster ? clusterStatus : "Request"}
                  </button>
                </div>
              </div>
            </StyleApi>
            {clusterStatus === "APPROVED" && (
              <InputField
                name="clusterCode"
                id="clusterCode"
                inputTitle="Cluster Code"
                value={clusterCode ? clusterCode : ""}
                disabled
              />
            )}

            {apiStatus === "APPROVED" && (
              <>
                <InputField
                  name="aesKey"
                  id="aesKey"
                  inputTitle="AES Key"
                  value={credentials ? credentials.aesKey : ""}
                  disabled
                />

                <InputField
                  name="ivKey"
                  id="ivKey"
                  inputTitle="IV Key"
                  value={credentials ? credentials.ivKey : ""}
                  disabled
                ></InputField>
                <InputField
                  name="apiKey"
                  id="apiKey"
                  inputTitle="API Key"
                  value={credentials ? credentials.apiKey : ""}
                  disabled
                />
                <InputField
                  name="callback_url"
                  id="callback_url"
                  inputTitle="Callback URL"
                  onChange={onChangeCallbackUrl}
                  value={callbackUrl}
                />
                <span style={{ color: "red" }}>{message}</span>

                <Button
                  type="submit"
                  className="profile-update-button"
                  text={
                    isLoading ? (
                      <Spinner
                        animation="grow"
                        role="status"
                        variant="light"
                        size="sm"
                      >
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Update Callback URL"
                    )
                  }
                />
              </>
            )}
          </InputContainer>
        </UpdateProfileContainer>
      </form>
    </>
  );
};

export default API;
