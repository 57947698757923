import { yupResolver } from "@hookform/resolvers/yup";
import React, { memo, useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import defaultImage from "../../assets/store-icon.svg";
import CustomButton from "../../components/CustomButton";
import Input from "../../components/CustomInput";
import Header from "../../components/dashboardHeader";
import ImgPrev from "../../components/ImagePreview";
import InputField from "../../components/Input";
import Heading from "../../components/Utils/Heading";
import environment from "../../environment";
import http from "../../services/http";
import { UserContext } from "../../store/userStore/user.context";
import { decryptPayload, sessionManager } from "../../utils/helpers";
import {
  BodyWrapper,
  Container,
  FormSelect,
  FormWrapper,
  ImageWrapper,
  InputTitleWrapper,
  SelectTextWrapper,
  TextArea,
  TextWrapper,
} from "./EditProduct.styles";

function EditProduct({ ...props }) {
  let test = props.location?.state?.detail;
  const user = useContext(UserContext);

  const [message, setMessage] = useState(false);
  const [, setErrorMessage] = useState("");
  const [loading, setIsloading] = useState(false);

  if (typeof window !== "undefined") {
    sessionManager();
  }
  const [forms, setForms] = useState(test?.product.currency);

  const productSchema = yup.object().shape({
    name: yup
      .string()
      .min(2, 'Product name should be more than 1 character')
      .matches(/^[a-z\d\-_\s]+$/i, "ProductName must contain alphanumeric only")
      .required()
      .default(test?.product.name),

    description: yup
      .string()
      .min(5, 'Description should be more than 5 characters')
      .max(50, 'Description should not be more than 50 characters')
      .required(),

    price: yup
      .string()
      .min(1, 'Minimum price should be 1.')
      .matches(
        /\d{1,3}(,\d{3})*(\.\d\d)?|\.\d\d/,
        'Price cannot contain invalid characters.'
      )
      .matches(/^\d+(,\d+)*$/, 'Enter a valid Price.')
      .required()
      .default(test?.product.price),

    qty: yup
      .string()
      .min(1, 'Minimum quantity should be 1')
      // .matches(/(?:\/)([^#]+)(?=#|$)/, 'Enter a valid quantity.')
      .matches(
        /\d{1,3}(,\d{3})*(\.\d\d)?|\.\d\d/,
        'Quantity must contain only numbers'
      )
      .matches(/^\d+(,\d+)*$/, 'Enter a valid quantity.')
      .required()
      .default(`${test?.product.qty}`),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(productSchema),
  });

  const [productImage, setProductImages] = useState(test?.product.image);

  const img =
    test?.product.image !== "false" && test?.product.image !== "undefined"
      ? environment.BACKEND_URL + "/utils/image/" + productImage ||
        environment.BACKEND_URL + "/utils/image/" + test?.product.image
      : productImage || test?.product.image || defaultImage;
  const { push } = useHistory();

  const handleChange = (event) => {
    setForms(event.target.value);
  };

  const handlePhoto = (value) => {
    setProductImages(value);
  };

  const submitForm = async (data) => {
    try {
      if (!Object.keys(data).length) return;
      const formData = new FormData();
      formData.append("name", data.name || test?.product.name);
      formData.append(
        "description",
        data.description || test?.product.description
      );
      formData.append(
        "price",
        data.price.replace(/[^0-9]/gi, "") ||
          test?.product.price.replace(/[^0-9]/gi, "")
      );
      formData.append(
        "qty",
        Number.parseInt(data.qty) || Number.parseInt(test?.product.qty)
      );
      formData.append("vendorId", user.id);
      formData.append("image", productImage);
      formData.append("currency", forms);
      setIsloading(true);
      const updatedProduct = await http.post(
        "/product/edit/" + test.product.code,
        formData
      );

      if (updatedProduct.data && updatedProduct.data) {
        setIsloading(false);
        setMessage("Product has been updated.");
        toast.success("Product has been updated.");
        setTimeout(() => {
          push("/dashboard/products");
        }, 2000);
      }
    } catch (e) {
      setIsloading(false);
      // let decryptError = decryptPayload(e?.response?.data?.error)
      if (e?.response?.status === 401) {
        setErrorMessage(e.response.data.error);
        toast.error("Your session expired. Re-Login.");

        push("/login");
      } else if (e?.response?.data?.error) {
        setErrorMessage(e.response.data.error.message);
        toast.error(e?.response?.data?.error);
      } else {
        setErrorMessage("Could not update product. Check your input");
        toast.error("could not update product.");
      }
    }
  };
  function isNumberKey(evt) {
    if (
      (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
      evt.which > 57
    ) {
      evt.preventDefault();
    }
  }

  const [numberStates, setNumberStates] = useState({
    price: test?.product.price,
    qty: test?.product.qty,
  });

  const checkInput = (e) => {
    const { value, name } = e.currentTarget;
    const re = /^[0-9\b]+$/;

    if (value === "" || re.test(value)) {
      setNumberStates((prev) => ({ ...prev, [name]: value }));
      setError(name, {
        type: "manual",
        message: "",
      });
    }
  };
  return (
    <Container>
      <Helmet>
        <title>Edit Product</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Header storeName={user?.storeName} />
      <BodyWrapper>
        <TextWrapper className="update-profile-header">
          <Heading text="Edit Product" />
        </TextWrapper>

        <FormWrapper onSubmit={handleSubmit(submitForm)}>
          <ImageWrapper style={{ marginBottom: "1rem" }}>
            <ImgPrev
              imgs={handlePhoto}
              imgSrc={img}
              defaultImage={img}
              defaultText={test?.product.image}
            />
          </ImageWrapper>
          <Input
            id="name"
            name="name"
            defaultValue={test?.product.name}
            onChange={() => {
              setError("name", {
                type: "manual",
                message: "Enter Product Name!",
              });
            }}
            {...register("name")}
            label={"Name"}
            placeholder="Product Name e.g Iphone 6"
          />
          {errors.name && (
            <div style={{ marginTop: "-1rem" }} className="text-danger">
              {errors.name.message[0].toUpperCase() +
                errors.name.message.slice(1)}
            </div>
          )}
          <SelectTextWrapper>
            <InputTitleWrapper>
              <span>Description (50 words max)</span>
            </InputTitleWrapper>
            <TextArea
              id="description"
              name="description"
              defaultValue={test?.product.description}
              onChange={() => {
                setError("description", {
                  type: "manual",
                  message: "Enter product description!",
                });
              }}
              {...register("description")}
              size="4"
              placeholder="Description"
              className="mb-4"
            />
            {errors.description && (
              <div style={{ marginTop: "-1rem" }} className="text-danger">
                {errors.description.message[0].toUpperCase() +
                  errors.description.message.slice(1)}
              </div>
            )}
          </SelectTextWrapper>

          <SelectTextWrapper style={{ marginBottom: "1rem" }}>
            <InputTitleWrapper>
              <span>Currency</span>
            </InputTitleWrapper>
            <FormSelect
              style={{ padding: ".9rem 0.2rem" }}
              name="currency"
              value={forms}
              onChange={handleChange}
            >
              <option value="NGN">NGN</option>
              {user?.transactionCurrency?.toUpperCase() === "INTERNATIONAL" && (
                <option value="USD">USD</option>
              )}
            </FormSelect>
          </SelectTextWrapper>
          <InputField
            id="price"
            name="price"
            defaultValue={Number.parseInt(test?.product.price)}
            value={numberStates.price}
            {...register("price")}
            onChange={(e) => {
              checkInput(e);
              setError("price", {
                type: "manual",
                message: "",
              });
            }}
            inputTitle="Price"
            placeholder="1000"
          />
          {errors.price && (
            <div
              style={{ marginTop: "-1rem", marginBottom: "1rem" }}
              className="text-danger"
            >
              {errors.price.message}
            </div>
          )}
          <InputField
            id="qty"
            name="qty"
            defaultValue={test?.product.qty}
            value={numberStates.qty}
            {...register("qty")}
            onChange={(e) => {
              checkInput(e);
              setError("qty", {
                type: "manual",
                message: "",
              });
            }}
            inputTitle="Quantity"
            placeholder="eg 10"
          />
          {errors.qty && (
            <div
              style={{ marginTop: "-1rem", marginBottom: "1rem" }}
              className="text-danger"
            >
              {errors.qty.message}
            </div>
          )}
          <div
            className="m-3 text-success d-block"
            style={{
              fontSize: "1rem",
              marginTop: "2rem",
              marginBottom: "1rem",
            }}
          >
            {message}
          </div>
          <CustomButton
            shape=""
            width="100%"
            style={{
              padding: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={()=>console.log()}
            type="submit"
          >
            {loading ? (
              <Spinner animation="grow" role="status" variant="light" size="sm">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              " Update Product"
            )}
          </CustomButton>
        </FormWrapper>
      </BodyWrapper>
    </Container>
  );
}

export default memo(EditProduct);
