import axios from 'axios';
import environment from '../environment';
import generate from '../utils/generaterandom';
import { encryptPayload } from '../utils/helpers';
import http, { httpHeaders, httpv2 } from './http';

const base = '/auth/vendor';

export async function signup(request) {
  if (!Object.keys(request)) {
    return (new Error('Request body not found'))
  }
  return  await http.post(base + '/signup', request);
}

export async function login(request) {
  if (!Object.keys(request)) {
    return (new Error('Request body not found'))
  }
  return  await http.post(base + '/login', request);
}

export async function verifyOTP(request, url = '') {
  if (request.reset) url = 'reset';
  return http.post('/auth/verify/otp/'+url, request)
}

export async function resetPassword(request) {
  return await http.post('/auth/resetpassword/', request)
}

export async function changePassword(request) {
  return await http.post('/auth/changePassword/', request)
}

export async function post(request, url, config={}) {
  return await http.post('/auth/' + url, request, config)
}

export async function logout() {
  return await http.get('/auth/vendor/logout');
}

export async function createTransaction (payload) {

  return http.post('/transactions/create', {data: encryptPayload(payload)});
}

export async function checkDailyTransactionLimit (payload) {
  return http.post('/transactions/check-limit', {data: encryptPayload(payload)});
}

export async function updateTransaction(payload) {
  try {
    const payloadData = {
      data: payload
    }
    return await http.post('/transactions/update', payloadData);
  } catch (err) {
    return {error: err.response.data}
  }
  
}

export async function getTransactionReceipt (payload) {
  return await http.post('/transaction/getvendorreceipt', payload);
}

export async function make3DPayment(payload, merchantref) {
  try {
    const payloadData = {
      data: payload
    }
    const paym = await httpHeaders({
      sessionid: generate(10),
      merchantref
    }).post('/transactions/makepayment', payloadData);
    return {data: paym.data}
  } catch (error) {
    if (error?.response?.data) {
      return {error: error.response.data}
    }
    return {error: 'invalid.'}
  }
}

export async function postACSUrl (payload) {
  try {
    const postAcs = await axios({
      method: 'post',
      url: payload.acsUrl,
      data: payload.data,
    });
    return {
      data: postAcs
    }
  } catch (error) {
    if (error.response) {
      return {
        data: error.response.data,
      };
    }
    return {
      error: error.message
    }
  }
}

export async function getTransactionPaymentStatus (data) {
  try {
    const getTransactionStatus = await axios({
      method: 'post',
      url: environment.transactionStatus,
      data,
      timeout: 60000,
      headers: {
        apiKey: environment.apiKey,
      }
    });
    return {
      data: getTransactionStatus.data
    }
  } catch (error) {
    if (error.response) {
      return {
        data: error.response.data,
      };
    }
    return {
      error: error.message
    }
  }
}

export async function updateProductQuantity(payload) {
  try {
    const payloadData = {
      code: payload[0],
      quantity: payload[1]
    }
    return await http.put('products/updateQuantity', payloadData);
  } catch (err) {
    return {error: err.response.data}
  }
}

export const getLogo = async (stringLogo) => {
  return httpHeaders({
    type: 'image/*'
  }).get(stringLogo)
}

export async function getAdHocInfo (payload) {
  return await httpv2.post(`/transaction/adhoc/find`, payload);
}
