import React from "react"
import MobileLayout, { LogoHeader } from "../../components/MobileLayout";
import Button from "../../components/Button";
import { LandingPageContainer } from "./landingpagestyles";
import Url from "../../assets/png/bg-mobile.png";
import TitleComponent from "../../components/TitleComponent";


const LandingPage = () => {
  return (
    <>
      <TitleComponent />
      <LandingPageContainer>
        <div
          alt="Avatar"
          className="bg-image"
          style={{
            maxWidth: "400px",
            margin: "0 auto",
            background: `linear-gradient(to bottom, rgba(0,0,0, 0.52), 
      rgba(0,0,0, 0.73)), url("${Url}")`,
          }}
        >
          <MobileLayout>
            <LogoHeader />
            <br />
            <br />
            <br />
            <br />
            <div
              className="text-white"
              style={{
                fontSize: "2.1rem",
                lineHeight: "1.8rem",
                fontWeight: "bold",
              }}
            >
              Connect with your customers from anywhere
            </div>
            <div style={{ height: "100px" }} />
            <Button
              isLink="true"
              text="REGISTER YOUR STORE"
              className="btn text-white bg-orange"
              url="register"
            />
            <div className="p-1" />
            <Button
              isLink="true"
              text="Login"
              className="btn text-orange"
              url="login"
            />
          </MobileLayout>
        </div>
      </LandingPageContainer>
    </>
  );
};
export default LandingPage;
