import React, { useState, useRef } from "react";
import { FormInputContainer } from "./forminputstyles";
const FormInput = React.forwardRef((props, ref) => {
  const [isFocus, setIsFocused] = useState(null);
  const [inputStyle, setInputStyle] = useState({});

  const {
    labelName,
    labelClassName,
    name,
    maxLength,
    pattern,
    className,
    type,
    onChange,
    // onKeyUp
  } = props;

  const handleChange = (event) => {
    event.preventDefault();
    onChange(event);
  };

  const setLabel = (input, isClicked = true) => {
    if (input && input.value !== "" && isClicked) {
      setIsFocused("focus");
      setInputStyle({ paddingTop: "20px", paddingBottom: "5px" });
    }
    if (!isClicked && input.type !== "checkbox") {
      setIsFocused("focus");
      setInputStyle({ paddingTop: "13" });
    }
  };

  return (
    <FormInputContainer>
      <div className="form-input">
        <label className={`${isFocus} ${labelClassName}`}> {labelName} </label>
        <input
          name={name}
          pattern={pattern || null}
          className={`${className || ""}`}
          type={type || "text"}
          maxLength={maxLength}
          onKeyUp={(e) => setLabel(e.target)}
          ref={ref}

          style={inputStyle}
          onChange={handleChange}
          autoComplete="off"
        />
        <br />
      </div>
    </FormInputContainer>
  );
});

FormInput.defaultProps = {
  type: "text",
  label: "input element",
  style: {},
  className: "",
};

export default FormInput;
