import styled from "styled-components";

export const ProfileContainer = styled.div`
  width: 100%;
  /* height: 100%; */
  background: none;

  @media screen and (min-width: 800px) {
    width: 75vw;
    margin-left: 25vw;
  }
`;
export const ProfileHeaderWrapper = styled.div`
  width: 100%;
  padding-top: 2rem;
  border-bottom: 1px solid #bebebe;
  display: flex;
  .active {
    border-bottom: 2px solid #003883;
  }
  margin-top: 5rem;
`;

export const ProfileHeader = styled.button`
  padding: 0.5rem;
  width: 6rem;
  border: none;
  background-color: transparent;
`;
export const ProfileWrapper = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
`;
