import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import placeHolder from "../../../assets/success-tick.svg";
import { useHistory } from "react-router-dom";

export default function SuccessfulAccount({ message, title, noIcon }) {
  const [show, setShow] = useState(false);

  let history = useHistory();

  const handleClick = () => {
    history.push("/login");
  };
  return (
    <Modal
      show={() => {
        setShow(true);
      }}
      className="w3-animate-zoom"
      onHide={() => {
        setShow(false);
      }}
      style={{
        width: "100vw",
        maxWidth: "100vw",
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="login-alert py-5 px-3">
        {!noIcon && (
          <img
            alt={"Friendly John Doe Placeholder"}
            src={placeHolder}
            className=" modal-image"
            style={{ height: "50px" }}
          />
        )}

        <div
          className="info mt-4"
          style={{ fontSize: "24px", fontWeight: "600px" }}
        >
          {title}
        </div>
        <p
          style={{ fontSize: "16px", paddingTop: "20px", paddingBottom: "5px" }}
        >
          {message}
        </p>

        <button
          className="btn default mb-5"
          onClick={handleClick}
          style={{
            textAlign: "center",
            fontStyle: "normal",
            fontWeight: "bold",
            width: "100%",
            display: "block",
            position: "relative",
            color: "#fff",
            border: "none",
            background: "#003883",
            ":hover": { background: "#01224d" },
            padding: "13px 0",
            // maxWidth: "400px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          Proceed
        </button>
      </div>
    </Modal>
  );
}

SuccessfulAccount.propTypes = {
  classes: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
};
SuccessfulAccount.defaultProps = {
  title: "Account successfully created",
  message:
    "Account number and account name has been sent to your registered number",
};
