import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/Button";
import Navbar from "../../components/dashboardHeader";
import { Input } from "../../components/Input/styles";
// import Notification from "../../components/Utils/Notification";
import TransactionNotification from "../../components/TransactionNotification";
import { UserContext } from "../../store/userStore/user.context";
import useAxios from "../../components/customHook/useAxios";
import {
  SearchTransactions,
  TransactionsContainer,
  FilterDropdown,
} from "./styles";
import { encryptAccount, searchArrayOfObject } from "../../utils/helpers";
import moment from "moment";
import TitleComponent from "../../components/TitleComponent";
// import ReactPaginate from "react-paginate";
import Pagination from "../../components/Pagination";
// import "./paginationstyles.css";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

// import { Redirect } from "react-router";

const filterValues = ["7-days", "1-month", "6-month", "1y", "All"];

const Transactions = () => {
  const user = useContext(UserContext);
  const history = useHistory();

  const [getTransaction, { data, error }, isLoading] = useAxios("get");

  const [getData, setData] = useState("data || []");
  const [transactions, setTransaction] = useState(["reload"]);

  const [showFilterModal, setShowFilterModal] = useState(false);
  const transactionsPerPage = 10;
  const [pageVisited, setPageVisited] = useState(0);

  const handleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  const search = (event) => {
    setTransaction(searchArrayOfObject(event.target.value, data));
  };

  const filter = (type = "all") => {
    const filtered = data?.filter((tran) => {
      const now = moment();
      const createdAt = moment(tran.createdAt);
      if (type === "7-days") return now.diff(createdAt, "days") <= 7;
      if (type === "1-month") return now.diff(createdAt, "months") <= 1;
      if (type === "6-month") return now.diff(createdAt, "months") <= 6;
      if (type === "1y") return now.diff(createdAt, "years") < 1;

      return tran;
    });
    setTransaction(filtered);
    handleFilterModal();
  };

  useEffect(() => {
    if (
      user &&
      !isLoading &&
      !Array.isArray(data) &&
      !error &&
      getData &&
      transactions[0] &&
      transactions[0] === "reload"
    ) {
      getTransaction("/transaction/all/" + encryptAccount(user.accountNumber));
      setData(null);
    }
  }, [isLoading]);
  if (
    user &&
    !isLoading &&
    data &&
    !error &&
    !getData &&
    transactions[0] === "reload"
  ) {
    setTransaction(data);
    setData(null);
  }
  const getPropsOfPage = (value) => {
    setPageVisited(value);
  };

  if (error?.status === 401) {
    toast.error(error?.error);
    history.push("/login");
  } else {
    toast.error(error?.error);
  }

  return (
    <>
      <TitleComponent title="Transactions on Swift Pay" />
      <Navbar storeName={user?.storeName} />
      <TransactionsContainer>
        <h5 className="title">Transaction History</h5>
        <SearchTransactions>
          <Input
            className="input"
            placeholder="Search transactions"
            onChange={search}
          />
          <div style={{ width: "max-content", height: "100%" }}>
            <Button
              onClick={handleFilterModal}
              className="filter-button"
              text="Filter"
              style={{ border: "none " }}
            />
            {showFilterModal ? (
              <FilterDropdown onClick={(e) => filter(e.target.textContent)}>
                {filterValues.map((filter, index) => (
                  <p key={index}>{filter}</p>
                ))}
              </FilterDropdown>
            ) : null}
          </div>
        </SearchTransactions>
        <div className="transaction-info">
          <p>Total Transactions: {data && data.length}</p>
          <p>
            Current Filter Transactions: {transactions && transactions.length}
          </p>
        </div>

        <div className="transaction-notification-wrapper">
          {transactions &&
            transactions[0] !== "reload" &&
            transactions
              .slice(pageVisited, pageVisited + transactionsPerPage)
              .map(
                (
                  {
                    status,
                    paymentFrom,
                    productName,
                    productCode,
                    reconciliationId,
                    id,
                    narration,
                    totalAmount,
                    createdAt,
                    currency,
                    description,
                  },
                  index
                ) => (
                  <TransactionNotification
                    user={paymentFrom}
                    productName={productName}
                    productCode={productCode}
                    currency={currency}
                    description={description}
                    reconciliationId={reconciliationId || id}
                    price={totalAmount}
                    time={createdAt}
                    narration={narration}
                    type={status.toLowerCase()}
                    key={index}
                  />
                )
              )}
        </div>
        <br />

        {transactions.length > transactionsPerPage ? (
          <Pagination
            perPageCount={transactionsPerPage}
            getPropsOfPage={getPropsOfPage}
            data={transactions}
          />
        ) : (
          ""
        )}
      </TransactionsContainer>
    </>
  );
};

export default Transactions;
