import styled from 'styled-components';

export const Container = styled.div`
    padding-top: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    /* height: 100%; */
    @media screen and (min-width: 768px) {
        padding-top: 3rem;
        /* justify-content: space-between; */
    }
`;

export const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
   
    width: 100%;
    /* margin: 0 auto;
    max-width: 450px; */

    @media screen and (min-width: 768px) {
        flex-direction: row;
        align-items: center;
    }
`;

export const ImageWrapper = styled.div`
    
    width: 18.55rem;
    height: 12rem;
    margin: auto;
    margin: 1rem;

    img {
      width: 100%;
      height: 100%;
    }

    @media screen and (min-width: 768px) {
        height: 20rem;
    }
`;

export const DescriptionWrapper = styled.div`
        display: flex;
        flex-direction: column;
        padding: 0rem 1rem;
        justify-content: space-between;

        .title{
            font-size: 1.2rem;
            font-weight: 600;
        }
        .title-content{
            font-size: 1rem;
            text-transform: capitalize;
        }
        .title-contents{
            font-size: 1rem;
        }
        .style-link{
            color: #003883;
            cursor: pointer;
        }
        @media screen and (min-width: 768px) {
            .title{
            font-size: 1.4rem;
            font-weight: 600;
        }
        .title-content{
            font-size: 1.25rem;
            text-transform: capitalize;
        }
        .title-contents{
            font-size: 1.25rem;
        }
       
        .style-link{
            font-size: 1.25rem;
        }    
        
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 1rem;
    margin: 0rem 1rem 1rem 1rem;

    .style-link{
            color: blue;
            cursor: pointer;
        }
`;