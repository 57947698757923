const getTotalAmount = (transactions) => {
    if (!transactions?.length) {
      return {
        NGN: 0,
        USD: 0
      }
    }
    const USDFilter = transactions.filter((transaction) => (transaction.currency === 'USD' && (transaction.status === 'SUCCESSFUL' || transaction.status === 'AUTHORIZED')))
  
    const NGNFilter = transactions.filter((transaction) => transaction.currency === 'NGN' && (transaction.status === 'SUCCESSFUL' || transaction.status === 'AUTHORIZED'))
    return {
      NGN: addSum(NGNFilter),
      USD: addSum(USDFilter)
    }
    
  }
  
  function addSum(total) {
    return total.reduce((t, i) => {
      return t + i.totalAmount
    }, 0);
  }
  
  export default getTotalAmount;
  