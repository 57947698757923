import styled from "styled-components";

export const ImagePrevContainer = styled.div`
  .image-container {
    width: 12rem;
    height: 12rem;
    margin: auto;
    margin-bottom: 3rem;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .input-container {
    /* width: 80%; */
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.19);
    background: #f8f8f8;
    border-radius: 3px;
    padding: 0.5rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;

    input {
      overflow: hidden;
    }
  }
`;
