export const generateV2Payload = (payload) => {
  const {
    currency,
    amount,
    card,
    cvv,
    expireDate,
    paymentFrom,
    cardType,
    merchantRef,
    ReferenceId,
    SessionId,
    spTransactionId,
    productName,
    vendor,
    source
  } = payload;
  const [expirationMonth, expirationYear] = expireDate.split("/");
  const [firstName, lastName] = paymentFrom.split(" ");

  return {
    currency,
    amount,
    merchantRef,
    cardType,
    cardNumber: card,
    expirationYear: `20${expirationYear}`,
    expirationMonth,
    cvv,
    ReferenceId,
    SessionId,
    billTo: {
      firstName,
      lastName,
    },
    vendor: {
      id: vendor?.id?.toString(),
      storeName: vendor?.storeName,
      transactionCurrency: vendor?.transactionCurrency,
      businessType: vendor?.businessType,
      websiteLink: vendor?.userName,
    },
    spTransactionId,
    product: {
      productName,
    },
    source
  };
};
