import { toast } from "react-toastify";


export const toastify = (content, type)=>{
    const options = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        }
    
    if(type==="success"){
        toast.success(content, options);
    }else{
        toast.error(content, options);
    }
}