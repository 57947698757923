import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    p{
        font-size: 1.5rem;
    }
    span{
        font-size: 0.8rem;
        font-weight: bold;
    }
    .buttons{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
        column-gap: 2rem;
        .btn{
            background-color: #003883;
            width: 100%;
            text-align: center;
            color: #ffffff;
            padding: 0.5rem;
            border-radius: 0.2rem;
            box-sizing: border-box;
            span{
                    font-size: 1rem;
                }
            @media screen and (min-width: 768px) {
                font-size: 1.2rem;
                width: 25rem;
                padding: 0.8rem;
                span{
                    font-size: 1.6rem;
                }
            }
        }
    }
`;