import React from "react";
import { InputWrapper, Input, InputTitle } from "./styles";

const InputField = ({
  inputTitle = "",
  placeholder = "",
  type = "text", 
  children,
  className,
  disabled,
  name,
  value,
  onChange,
  search,
...restProps}) => {
  // const handleChange = (event) => {
  //   event.preventDefault();
  //   onChange(event);
  // }
  return (
    <InputWrapper className={className}>
      <InputTitle>
        {inputTitle}
        <span>{children}</span>
      </InputTitle>

      <Input
        // onChange={handleChange}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        value={value}
        type={type}
        disabled={disabled}
        onKeyUp={search}
        {...restProps}
      />
    </InputWrapper>
  );
};

export default InputField;
