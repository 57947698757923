import React, { useEffect, useState, useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import Input from "../CustomInput";
import PasswordInput from "../CustomInput/PasswordInput";
import CustomButton from "../CustomButton";
import Spinner from "react-bootstrap/Spinner";
import validCardType from "../../utils/card";
import AccessImage from "../../assets/img/access.png";

import {
  Container,
  HeaderWrapper,
  BodyWrapper,
  InputFieldWrapper,
  SelectTextWrapper,
  InputTitleWrapper,
  FooterWrapper,
  TextArea,
  CvvExpDateWrapper,
  FormSelect,
} from "./payment.styles";
import { cardSchema } from "./schema";
import { useHistory } from "react-router";
import { BiArrowBack } from "react-icons/bi";

import {
  formatCardNumber,
  formatExpiration,
  formatCurrency,
  decryptPayload,
  redirect,
} from "../../utils/helpers";
import generate from "../../utils/generaterandom";
import { toast } from "react-toastify";
import {
  checkDailyTransactionLimit,
  createTransaction,
} from "../../services/auth.http";
import {
  initCardinal,
  sendToCardinal,
} from "../../services/payment/cardinalutilhook";

function Payment(props) {
  const location = props.location?.state?.detail;
  const state = { ...location };

  const [errorMessage, setErrorMessage] = useState("");
  const [cardType, setCardType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { push } = useHistory();
  const vendor = state?.vendor;
  const product = state?.product;

  const backToSendMoney = useCallback(() => {
    if (state?.from === "adhoc") {
      push({
        pathname: `/${vendor?.defaultUrl}/checkout`,
        state,
      });
    } else {
      // push({
      //   pathname: `/${vendor?.defaultUrl}/send-money`,
      //   state,
      // });
      setTimeout(() => {
        redirect(`/@${vendor?.defaultUrl}`);
      }, 1500);
    }
  });

  const currency = state?.product?.currency;

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(cardSchema(state?.product)),
  });

  const handleCardType = ({ target }) => {
    const getCard = validCardType(target.value);
    if (getCard) {
      setCardType(getCard + "Icon");
    }
  };

  const successAlert = toast.success;
  const errorAlert = toast.error;

  const makePayment = useCallback(
    async (formData) => {
      setIsLoading(true);
      const amount = formData?.amount?.replaceAll(",", "");
      const currency = formData.currency;
      const reconciliationId = `SWIFTCRD${generate(10)}`;
      const payload = {
        ...formData,
        cardType,
        amount,
        currency,
      };

      const checkLimitPayload = {
        vendorId: Number(vendor.id),
        amount : Number(amount),
      };

      const checkLimit = await checkDailyTransactionLimit(checkLimitPayload);
      const decryptLimit = decryptPayload(decryptPayload(checkLimit?.data?.data));

      if (checkLimit?.status === 200 && decryptLimit?.exceedLimit === false) {
        const {
          ReferenceId,
          SessionId,
          error: initError,
        } = await initCardinal({ currency, amount: amount.toString() });

        if (initError) return errorAlert("Payment failed.");

        const addTrans = await createTransaction({
          merchantRef: reconciliationId, // TODO: remove
          accountNumber: vendor.accountNumber,
          storeName: vendor.storeName,
          amount,
          totalAmount: amount,
          vendorId: Number(vendor.id),
          currency,
          reconciliationId,
          refId: reconciliationId,
          productName: product?.name || state?.from?.toUpperCase() || "N/A",
          productCode: product?.code || "N/A",
          narration: payload?.narration,
          channel: payload?.channel || "CARD",
          paymentFrom: payload?.paymentFrom,
          transactionCurrencyType:
            currency.toLowerCase() === "usd" ? "INTERNATIONAL" : "LOCAL",
          transactionRef: reconciliationId,
        });

        const decrypt = decryptPayload(decryptPayload(addTrans?.data?.data));

        if ([200, 201].includes(addTrans.status)) {
          // Start Cardinal stuffs...
          await sendToCardinal(
            {
              card: payload?.number,
              cvv: payload?.cvv,
              expireDate: payload?.expirationDate,
              accountNumber: vendor?.accountNumber,
              amount: amount.toString(),
              vendorId: Number(vendor?.id),
              currency: product?.currency || payload?.currency,
              narration: payload?.narration,
              channel: payload?.channel || "CARD",
              paymentFrom: payload?.paymentFrom,
              payerName: payload?.paymentFrom,
              merchantRef: reconciliationId,
              ReferenceId,
              spTransactionId: decrypt.transactionRef,
              productName:
                product?.name || state?.from?.toUpperCase() || "SEND-MONEY",
              cardType,
              vendor,
              SessionId,
            },
            {
              errorAlert,
              setIsLoading,
              successAlert,
              back: backToSendMoney,
            }
          );
        } else {
          errorAlert("Server Error Occured.. ");
        }
      } else {
        setIsLoading(false);
        setErrorMessage("Daily limit exceeded..");
        return errorAlert("Daily limit exceeded.. ");
      }
    },
    [cardType]
  );

  return (
    <Container>
      <HeaderWrapper>
        <div className="back-arrow" onClick={backToSendMoney}>
          <BiArrowBack className="icon" />
        </div>
        <h3>{vendor?.storeName}</h3>
      </HeaderWrapper>
      <BodyWrapper>
        <InputFieldWrapper>
          <form onSubmit={handleSubmit(makePayment)}>
            <SelectTextWrapper>
              <InputTitleWrapper>
                <span>Currency</span>
              </InputTitleWrapper>
              <FormSelect
                name="currency"
                defaultValue={currency || "currency"}
                onChange={(e) => {
                  setError("currency", {
                    type: "manual",
                    message: "Select a currency",
                  });
                }}
                disabled
                {...register("currency")}
              >
                <option value="NGN">NGN</option>
                {vendor?.transactionCurrency?.toUpperCase() ===
                  "INTERNATIONAL" && <option value="USD">USD</option>}
              </FormSelect>
            </SelectTextWrapper>
            <Input
              id="amount"
              data-name="amount"
              name="amount"
              defaultValue={state?.product?.amount}
              onChange={(e) => {
                setError("amount", {
                  type: "manual",
                  message: "Select a currency",
                });
              }}
              {...register("amount")}
              label={
                <>
                  Amount <span className="optional">(comma seperated)</span>
                </>
              }
              placeholder="1,000"
              onKeyUp={formatCurrency}
              disabled
            />
            {errors.amount && (
              <div
                className="text-danger"
                style={{
                  textAlign: "left",
                  marginTop: "-1rem",
                  marginBottom: "0.7rem",
                }}
              >
                {errors.amount.message}
              </div>
            )}
            <Input
              id="paymentFrom"
              name="paymentFrom"
              defaultValue={state?.product?.paymentFrom}
              onChange={(e) => {
                setError("paymentFrom", {
                  type: "manual",
                  message: "Dont Forget Your Username Should Be Cool!",
                });
              }}
              disabled
              {...register("paymentFrom")}
              label={"Depositor’s fullname"}
              placeholder="Fullname eg. John Doe"
            />
            {errors.paymentFrom && (
              <div
                className="text-danger"
                style={{
                  textAlign: "left",
                  marginTop: "-1rem",
                  marginBottom: "0.7rem",
                }}
              >
                {errors.paymentFrom.message}
              </div>
            )}
            <input
              type="hidden"
              value={vendor?.id}
              onChange={(e) => {
                setError("vendorId", {
                  type: "manual",
                  message: "No vendorId!",
                });
              }}
              name="vendorId"
              {...register("vendorId")}
            />
            {errors.vendorId && (
              <div className="text-danger">{errors.vendorId.message}</div>
            )}
            <Input
              id="narration"
              name="narration"
              onChange={(e) => {
                setError("narration", {
                  type: "manual",
                  message: "Narration field cannot be empty!",
                });
              }}
              {...register("narration")}
              label={"Narration"}
              placeholder="Comment to vendor"
            />
            {errors.narration && (
              <div
                className="text-danger"
                style={{
                  textAlign: "left",
                  marginTop: "-1rem",
                  marginBottom: "0.7rem",
                }}
              >
                {errors.narration.message}
              </div>
            )}
            <label htmlFor="deliveryAddress">
              <span>Delivery address </span>{" "}
              <span className="optional"> (Optional)</span>
            </label>
            <TextArea
              id="deliveryAddress"
              name="delivery"
              {...register("delivery")}
              onChange={(e) => {
                setError("delivery", {
                  type: "manual",
                  message: "Enter an Address!",
                });
              }}
              size="4"
              placeholder="Delivery Address"
              className="mb-4"
            />
            {/* {errors.delivery && <div className="text-danger">{errors.delivery.message}</div>} */}
            <Input
              name="number"
              onChange={(e) => {
                setError("number", {
                  type: "manual",
                  // message: "Enter a valid card number!"
                });
              }}
              {...register("number")}
              label={"Credit card number"}
              icon={"CreditCardIcon"}
              iconType={cardType}
              placeholder="0000 0000 0000 0000"
              onKeyUp={formatCardNumber}
              onBlur={handleCardType}
              style={{ fontSize: "1.05rem", color: "#000000" }}
            />
            {errors.number && (
              <div
                className="text-danger"
                style={{
                  textAlign: "left",
                  marginTop: "-1rem",
                  marginBottom: "0.7rem",
                }}
              >
                {errors.number.message}
              </div>
            )}
            <CvvExpDateWrapper>
              <div>
                <Input
                  id="expirationDate"
                  name="expirationDate"
                  label={"Expiry date"}
                  placeholder="MM/YY"
                  onKeyUp={formatExpiration}
                  onChange={(e) => {
                    setError("expirationDate", {
                      type: "manual",
                      message: "The expiring date is invalid!",
                    });
                  }}
                  {...register("expirationDate")}
                  size="5"
                  style={{ fontSize: "1.05rem", color: "#000000" }}
                />
                {errors.expirationDate && (
                  <div
                    className="text-danger"
                    style={{
                      textAlign: "left",
                      marginTop: "-1rem",
                      marginBottom: "0.7rem",
                    }}
                  >
                    {errors.expirationDate.message}
                  </div>
                )}
              </div>
              <div>
                <Input
                  id="cvv"
                  name="cvv"
                  size="4"
                  label={"CVV/CVN"}
                  onChange={(e) => {
                    setError("cvv", {
                      type: "manual",
                      message: "The Cvv is invalid!",
                    });
                  }}
                  {...register("cvv")}
                  placeholder="****"
                  type="password"
                  style={{ fontSize: "1.05rem", color: "#000000" }}
                />
                {errors.cvv && (
                  <div
                    className="text-danger"
                    style={{
                      textAlign: "left",
                      marginTop: "-1rem",
                      marginBottom: "0.7rem",
                    }}
                  >
                    {errors.cvv.message}
                  </div>
                )}
              </div>
            </CvvExpDateWrapper>

            {cardType === "VerveCardIcon" && (
              <PasswordInput
                name="pin"
                label={
                  <>
                    PIN{" "}
                    <small className="text-danger">(verve cards only)</small>
                  </>
                }
                placeholder="****"
                {...register("pin")}
                size="4"
                isRequired={false}
              />
            )}

            {cardType === "VerveCardIcon" && (
              <Input
                id="email"
                name="email"
                {...register("email")}
                label={"Email"}
                placeholder="Email"
              />
            )}
            {errors.email && (
              <div
                className="text-danger"
                style={{
                  textAlign: "left",
                  marginTop: "-1rem",
                  marginBottom: "0.7rem",
                }}
              >
                {errors.email.message[0].toUpperCase() +
                  errors.email.message.slice(1)}
              </div>
            )}

            {cardType === "VerveCardIcon" && (
              <Input
                id="phoneNumber"
                name="phoneNumber"
                {...register("phoneNumber")}
                label={"Phone Number"}
                placeholder="Phone Number"
              />
            )}
            {errors.phoneNumber && (
              <div
                className="text-danger"
                style={{
                  textAlign: "left",
                  marginTop: "-1rem",
                  marginBottom: "0.7rem",
                }}
              >
                {errors.phoneNumber.message}
              </div>
            )}
            <br />

            <div className="text-danger">{errorMessage}</div>

            <CustomButton
              shape=""
              width="100%"
              style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => console.log()}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  animation="grow"
                  role="status"
                  variant="light"
                  size="sm"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Pay now"
              )}
            </CustomButton>
          </form>
        </InputFieldWrapper>
      </BodyWrapper>
      <FooterWrapper>
        <span>
          <img
            src={AccessImage}
            style={{ width: "40px", height: "40px" }}
            alt="access logo"
          />
          <i>Verified by Access</i>
        </span>
      </FooterWrapper>
    </Container>
  );
}

export default Payment;
