import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import moment from "moment";

import { Container,BodyWrapper, DisplayWrapper, NoTransaction, HeaderWrapper } from "./Daily.styles";
import Header from "../../../components/dashboardHeader";
import InputField from "../../../components/Input";
import CustomButton from "../../../components/CustomButton";

import {
  sessionManager,
  encryptAccount,
  searchArrayOfObject,
} from "../../../utils/helpers";
import useAxios from "../../../components/customHook/useAxios";
import downloadCSV from "../../../utils/downloadCSV";
import { UserContext } from "../../../store/userStore/user.context";
import TransactionList from '../../../components/TransactionList'
import { Helmet } from 'react-helmet';
import  Pagination from '../../../components/Pagination';

function DailyTransactions() {
  const user = useContext(UserContext);
  if (typeof window !== "undefined") {
    sessionManager();
  }

  const [getTransaction, { data, error }, isLoading, ] = useAxios("get");
  const [getData, setData] = useState("data || []");
  const [transactions, setTransaction] = useState(["reload"]);
  //   const [dailyTransactions, setDailyTransactions] = useState(false);
  const [filtered, setFiltered] = useState(null);
  const pagePerCount = 10;
  const [pageVisited, setPageVisited] = useState(0)

  const search = (event) => {
    setTransaction(searchArrayOfObject(event.target.value, data));
  };

  const handleDownloadCSV = (type) => {
    if (data.length) {
      let filteredTransactions;

      switch (type) {
        case "monthly":
          filteredTransactions = data.filter((transaction) => {
            const createdAt = moment(transaction.createdAt);
            return createdAt.isSame(new Date(), "month");
          });
          break;
        case "daily":
          filteredTransactions = data.filter((transaction) => {
            const createdAt = moment(transaction.createdAt);
            return createdAt.isSame(new Date(), "day");
          });
          break;
        default:
          break;
      }

      if (filteredTransactions.length) {
        downloadCSV({
          fileName: `REPORT${moment().format("YYYYMMDDhhmmss")}`,
          data: filteredTransactions,
        });
        toast.success(`${type} report exported`);
        toast.clearWaitingQueue();
      } else {
        toast.error("No transactions today!");
        toast.clearWaitingQueue();
      }
    } else {
      toast.error(`No ${type} report`);
      toast.clearWaitingQueue();
    }
  };

  const filter = (type = "all") => {
    const filtered = data?.filter((tran) => {
      const now = moment();
      const createdAt = moment(tran.createdAt);
      if (type === "daily") return moment(now).diff(createdAt, "days") <= 1;
      return tran;
    });
    setTransaction(filtered);
    setFiltered(filtered);
  };

  useEffect(() => {
    if (
      user &&
      !isLoading &&
      !Array.isArray(data) &&
      !error &&
      getData &&
      transactions[0] &&
      transactions[0] === "reload"
    ) {
      getTransaction("/transaction/all/" + encryptAccount(user.accountNumber));
      setData(null);
    }
  }, [isLoading]);
  if (
    user &&
    !isLoading &&
    data &&
    !error &&
    !getData &&
    transactions[0] === "reload"
  ) {
    setTransaction(data);
    setData(null);
    filter("daily");
  }
  const getPropsOfPage = (value) => {
    setPageVisited(value)
  }
  return (
    <Container>
      <Helmet>
      <title>Daily Transactions</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <HeaderWrapper>
      <Header storeName={user?.storeName} />
      </HeaderWrapper>
      
<BodyWrapper>
      <div className="title">
        <h5> Today's Transactions </h5>
      </div>
      <div className="btn-search-wrapper">
        <div className="search">
          <InputField
            inputTitle="Transactions"
            placeholder="Search transactions"
            // onKeyUp={search}
            search={search}
            onChange={search}
          />
        </div>
        <div className="buttons">
          <CustomButton
            shape=""
            width="100%"
            onClick={() => {
              handleDownloadCSV("daily");
            }}
            style={{ padding: "0.9rem", marginTop: "0.85rem" }}
          >
            Export
          </CustomButton>
        </div>
      </div>
      {filtered ? (
        filtered[0] !== "reload" &&
          
          <DisplayWrapper>
          <TransactionList transactions={filtered.length < pagePerCount ? filtered : filtered.slice(pageVisited,pageVisited + pagePerCount)} />
            {filtered.length > pagePerCount ? <Pagination perPageCount={pagePerCount} getPropsOfPage={getPropsOfPage} data={filtered} />:""}
          </DisplayWrapper>
  
      ) : (
        <NoTransaction>
          <p>No transactions today!</p>
        </NoTransaction>
        )}
        </BodyWrapper>
    </Container>
  );
}

export default DailyTransactions;
