import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import InputField from "../../components/Input";
import CustomButton from "../../components/CustomButton";
import { BiArrowBack } from "react-icons/bi";
import {
  ProductsContainer,
  SearchInputWrapper,
  ButtonWrapper,
  ProductsWrapper,
  BodyWrapper,
  SingleProductWrapper,
  AlignButtonCenter,
} from "./Products.styles";
import { BiPlus } from "react-icons/bi";
import Header from "../../components/dashboardHeader";
import { searchArrayOfObject } from "../../utils/helpers";
import { UserContext } from "../../store/userStore/user.context";
import useAxios from "../../components/customHook/useAxios";
import environment from "../../environment";

import defaultImage from "../../assets/default-placeholder-image.png";
import ProductCard from "../../components/ProductCard";
import SingleProductpage from "../../components/SingleProduct";
import { Helmet } from "react-helmet";
import Pagination from "../../components/Pagination";

const ProductPage = () => {
  const user = useContext(UserContext);

  const [getTransaction, { data, error }, isLoading] = useAxios("get");
  const [getData, setData] = useState("data || []");
  const [products, setProducts] = useState(["reload"]);
  const [singleProduct, setSingleProduct] = useState(null);
  const [showCard, setShowCard] = useState(true);

  const pagePerCount = 10;
  const [pageVisited, setPageVisited] = useState(0);
  const search = (event) => {
    setProducts(searchArrayOfObject(event.target.value, data));
  };
  const { push } = useHistory();

  useEffect(() => {
    if (
      user &&
      !isLoading &&
      !Array.isArray(data) &&
      !error &&
      getData &&
      products[0] &&
      products[0] === "reload"
    ) {
      getTransaction("/product/all/" + user.userName);
      setData(null);
    }
  }, [isLoading]);

  if (
    user &&
    !isLoading &&
    data &&
    !error &&
    !getData &&
    products[0] === "reload"
  ) {
    setProducts(data);
    setData(null);
  }

  const { appUrl } = environment;
  const productUrl = user?.userName + "/" + "pay?pid=" + singleProduct?.code;
  const fullUrl =
    appUrl + "/" + user?.userName + "/" + "pay?pid=" + singleProduct?.code;

  const img =
    singleProduct && singleProduct?.image
      ? environment.BACKEND_URL + "/utils/image/" + singleProduct.image
      : defaultImage;

  function createProduct() {
    push({
      pathname: "/dashboard/create-product",
    });
  }
  const getPropsOfPage = (value) => {
    setPageVisited(value);
  };
  return (
    <>
      <Helmet>
        <title>Product Swift pay</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      {user && (
        <ProductsContainer>
          <Header storeName={user?.storeName} />
          <BodyWrapper>
            {showCard ? (
              <div>
                <SearchInputWrapper>
                  <InputField
                    inputTitle="Products"
                    placeholder="Search Products"
                    search={search}
                    onChange={search}
                  />
                </SearchInputWrapper>
                <ButtonWrapper>
                  <CustomButton
                    shape=""
                    width="100%"
                    style={{
                      padding: "1rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={createProduct}
                  >
                    <AlignButtonCenter>
                      <BiPlus />
                      Add Product
                    </AlignButtonCenter>
                  </CustomButton>
                </ButtonWrapper>
              </div>
            ) : (
              ""
            )}

            {showCard ? (
              <>
              <ProductsWrapper>
                {products && products[0] !== "reload" && (
                  <>
                    {products.length < pagePerCount
                      ? products.map((item) => (
                          <ProductCard
                            {...item}
                            onClick={() => {
                              setShowCard(!showCard);
                              setSingleProduct(item);
                              localStorage?.setItem(
                                "product",
                                JSON.stringify(item)
                              );
                            }}
                            key={item.id}
                          />
                        ))
                      : products
                          .slice(pageVisited, pageVisited + pagePerCount)
                          .map((item) => (
                            <ProductCard
                              {...item}
                              onClick={() => {
                                setShowCard(!showCard);
                                setSingleProduct(item);
                                localStorage?.setItem(
                                  "product",
                                  JSON.stringify(item)
                                );
                              }}
                              key={item.id}
                            />
                          ))}
                  </>
                )}
                
                </ProductsWrapper>
                {products.length > pagePerCount ? <Pagination perPageCount={pagePerCount} getPropsOfPage={getPropsOfPage} data={products} />:""}
                </>
            ) : (
              <SingleProductWrapper>
                <div className="back-arrow">
                  <BiArrowBack
                    className="icon"
                    onClick={() => setShowCard(!showCard)}
                  />
                </div>
                {singleProduct && (
                  <SingleProductpage
                    items={singleProduct}
                    img={img}
                    productUrl={productUrl}
                      fullUrl={fullUrl}
                      vendor={user}
                  />
                )}
              </SingleProductWrapper>
            )}
          </BodyWrapper>
        </ProductsContainer>
      )}
    </>
  );
};

export default ProductPage;
