import styled from "styled-components";

export const ChangePasswordContainer = styled.div`
.btn {
    height: 50px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    background: #003883 !important;
    color: #fff;
    :hover {
      background: #01224d !important;
      font-weight: bolder;
    }
    @media screen and (max-width: 780px)
. {
    font-size: 0.8rem;
}
  }
  button {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    width: 100%;
    display: block;
    position: relative;
    left: 0;

  }
  button:disabled {
    background: rgba(1, 13, 27, 0.61) !important;
    border: none !important;
    color: white !important;
  }
  

  .default-align-card {
    padding-left: 40px;
    padding-right: 40px;
    margin: 0 auto;
    width: 100% ;
    max-width:400px; 
    
  }
  }
`;
