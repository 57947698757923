import React, { useState } from "react";
import { ImagePrevContainer } from "./styles";

const ImgPrev = ({
  name,
  imgs,
  imgSrc,
  onChange,
  defaultImage,
  className,
  defaultText,
}) => {
  const [{ alt, src }, setImg] = useState({
    src: imgSrc || defaultImage,
    alt: defaultText || "",
  });

  // console.log({ src });

  const handleImg = (e) => {
    if (e.target.files[0]) {
      setImg({
        src: URL.createObjectURL(e.target.files[0]) || imgSrc,
        alt: e.target.files[0].name,
      });
    }

    imgs(e.target.files[0]);
  };

  return (
    <ImagePrevContainer className={className}>
      <div className="image-container">
        <img src={src} alt={alt} />
      </div>
      <div className="input-container">
        <input
          type="file"
          name={name}
          // defaultValue={URL.createObjectURL(imgSrc[0])}
          onChange={onChange || handleImg}
          accept="image/*"
        />
      </div>
    </ImagePrevContainer>
  );
};

export default ImgPrev;
