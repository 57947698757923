import styled from 'styled-components';

export const Container = styled.div`

`;
export const BodyWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 1rem 1.4rem;
    align-items: center;
    justify-content: center;
    flex-direction:  column;
    margin: 0 auto;
    max-width: 500px;
    .title{
        color: #030F4F;
        p{font-size: 1rem;}
    }
    @media screen and (min-width: 768px) {
        .title{
            p{font-size: 2rem;}
        }
    }
    @media screen and (min-width: 800px) {
        max-width: 75vw;
        margin-left: 25%;
        padding: 0 5rem;
    }

    @media screen and (min-width: 1000px) {
        max-width: 75vw;
        margin-left: 25%;
        padding: 0 7rem;
    }
    @media screen and (min-width: 1200px) {
        max-width: 75vw;
        margin-left: 25%;
        padding: 0 9rem;
    }
    @media screen and (min-width: 1500px) {
        max-width: 75vw;
        margin-left: 25%;
        padding: 0 15rem;
    }
`;
export const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    /* border: 1px solid red; */

    .date-picker{
        display: grid;
        width: 100%;
        /* flex-direction: row; */
        grid-template-columns:22% 78%;
        align-items: center;
        /* border: 1px solid rebeccapurple; */
        h5{
            font-size: 0.8rem;
        }
        div{
        }

    }

    @media screen and (min-width: 850px) {
        flex-direction: row;
        /* border: 1px solid red; */
        column-gap: 0.5rem;
        
        
        .date-picker{
            /* justify-content: center; */
            /* border: 1px solid blue; */
            grid-template-columns:30% 70%;
            h5{
            font-size: 0.8rem;
            }
        }
        
    }
    @media screen and (min-width: 1200px) {
        flex-direction: row;
        /* border: 1px solid red; */
        column-gap: 1rem;
        
        
        .date-picker{
            /* justify-content: center; */
            /* border: 1px solid blue; */
            grid-template-columns:24% 75%;
            h5{
            font-size: 0.9rem;
            }
        }
        
    }
`;

export const ButtonWrapper = styled.div`
    width: 40%;
    margin-bottom: 2rem;
`;



export const HeaderWrapper = styled.div`
margin-bottom: 6rem;
`;
export const NoTransaction = styled.div``;

export const DisplayWrapper = styled.div`
    width: 100%;
    padding: 1rem 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
`;