import { isDate, parse } from "date-fns";
import * as yup from "yup";

function parseDateString(value, originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "yyyy-MM-dd", new Date());
  return parsedDate;
}

export const loginSchema = yup.object().shape({
  userName: yup
    .string()
    .matches(
      /^[a-zA-Z][a-zA-Z0-9]{0,15}$/,
      "Username must be a minimum of 1 and maximum of 15 alphanumeric characters only."
    )
    .min(1, "UserName should be a minimum of a character")
    .max(15, "UserName should have maximum of 15 characters")
    .required(),
  password: yup.string().required(),
  remember: yup.boolean(),
});

const today = new Date();
const account = "Account number should be minimum of 9 characters";
const phoneformat = "Invalid Phone number format";
const format = "Invalid BVN number format";

export const registerSchema = yup.object().shape({
  accountNumber: yup
    .string()
    .required("Please enter your account number")
    .min(10, "Account number must be a minimum of 10 digits"),
  storeName: yup
    .string()
    .min(3, "Storename should be minimum of 3 characters")
    .required(),
  businessType: yup
    .string()
    .min(5, "BusinessType should be minimum of 5 characters")
    .required(),
  userName: yup
    .string()
    .matches(
      /^[a-zA-Z][a-zA-Z0-9]{2,15}$/,
      "Username must be a minimum of 3 and maximum of 15 alphanumeric characters only."
    )
    .min(3, "UserName should be a minimum of 3 characters")
    .max(15, "UserName should have maximum of 15 characters")
    .required(),
  password: yup
    .string()
    .matches(
      /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      "Password must contain at least a special character,a number, an Uppercase and a lowercase character and must be more than 8 characters."
    )
    .min(8)
    .required(),
});

export const createAccountSchema = yup.object().shape({
  firstName: yup.string().required(),
  otherName: yup.string(),
  lastName: yup.string().required(),
  BVN: yup
    .string()
    .min(9)
    .max(11, "BVN cannot be more than 11")
    .matches(/^[0-9]+$/i, format)
    .required(),
  birthDate: yup.date().transform(parseDateString).max(today),
  phoneNumber: yup
    .string()
    .min(10)
    .max(14, "Phone number cannot be more than 14")
    .matches(/^[0-9]+$/i, phoneformat)
    .required(),
  address: yup.string().required(),
  country: yup.string().required(),
  state: yup.string().required(),
  gender: yup.string().min(4).required(),
});

export const updateProfileSchema = yup.object().shape({
  storeName: yup
    .string()
    .min(5, "Store name must be a minimum of 5 characters")
    .required(),
  businessType: yup
    .string()
    .min(3, "Business type must be a minimum of 3 characters")
    .required(),
  userName: yup
    .string()
    .matches(
      /^[a-zA-Z][a-zA-Z0-9]{0,15}$/,
      "Username must be a minimum of 1 and maximum of 15 alphanumeric characters only."
    )
    .min(1, "UserName should be a minimum of a character")
    .max(15, "UserName should have maximum of 15 characters")
    .required(),
  email: yup.string().email("Email must be a valid email format").required(),
});

export const otpSchema = yup.object().shape({
  passCode: yup
    .string()
    .max(6, "Invalid otp")
    .matches(/^[0-9]+$/i, "Invalid otp")
    .required(),
});

export const productSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, 'Product name should be more than 1 character')
    .matches(/^[a-z\d\-_\s]+$/i, "ProductName must contain alphanumeric only")
    .required(),

  description: yup
    .string()
    .min(5, 'Description should be more than 5 characters')
    .max(50, 'Description should not be more than 50 characters')
    .required(),

  price: yup
    .string()
    .min(1, 'Minimum price should be 1.')
    .matches(
      /\d{1,3}(,\d{3})*(\.\d\d)?|\.\d\d/,
      'Price cannot contain invalid characters.'
    )
    .matches(/^\d+(,\d+)*$/, 'Enter a valid Price.')
    .required()
    .required(),

  qty: yup
    .string()
    .min(1, 'Minimum quantity should be 1')
    // .matches(/(?:\/)([^#]+)(?=#|$)/, 'Enter a valid quantity.')
    .matches(
      /\d{1,3}(,\d{3})*(\.\d\d)?|\.\d\d/,
      'Quantity must contain only numbers'
    )
    .matches(/^\d+(,\d+)*$/, 'Enter a valid quantity.')
    .required(),
});

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const requestBusinessCardSchema = yup.object().shape({
  address: yup
    .string()
    .min(5, "Address should be more than 5 characters")
    .required("Please input an address"),
  phoneNumber: yup
    .string()
    .required("Please input your phone number")
    .min(10)
    .max(14, "Phone number cannot be more than 14")
    .nullable()
    .matches(phoneRegExp, "Phone number format is not valid"),
  nameOnCard: yup
    .string()
    .required("Please input name to be displayed on card"),
  branchCode: yup.string().required("Please select a branch"),
  cardType: yup.string().required("Please select a card type"),
  street: yup.string().required("Please input a landmark"),
});

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}