import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem 1.4rem;
  max-width: 50rem;
  margin: auto;
  min-height: 100vh;
`;
export const BodyWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export const BackArrow = styled.div``;

export const ImageWrapper = styled.div`
  width: 10rem;
  height: 10rem;
  margin: auto;
  margin-bottom: 3rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);

  img {
    /* object-fit: contain; */
    width: 100%;
    height: 100%;
  }
`;

export const TitleWrapper = styled.p`
  margin-bottom: 3rem;
  @media screen and (min-width: 768px) {
    font-size: 2rem;
  }
`;

export const LogoWrapper = styled.div`
  width: 12rem;
  height: 12rem;
  margin: auto;
  /* margin-bottom: 3rem; */

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

export const ProductDetailWrapper = styled.div`
  padding: 1rem 1.4rem;
  color: black;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5); */
  font-size: 6rem;
  @media screen and (min-width: 768px) {
  }
`;
export const Underline = styled.div`
  width: 100%;
  border: 1px solid gray;
`;

export const ContentWrapper = styled.p`
  color: ${(props) => props.color};
  margin-bottom: 1rem;
  font-weight: bold;
  @media screen and (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
`;

export const TextWrapper = styled.p`
  margin-bottom: 1rem;
  @media screen and (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const FooterWrapper = styled.div`
  padding: 1rem 2rem;
  margin-top: 2rem;
  display: flex;
  justify-content: center;

  span {
    display: flex;
    align-items: center;
  }
`;
