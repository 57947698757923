import React from "react";
import Select from "react-select";
import { Container } from "./styles";

const CustomSelect = (
  { onChange, value, options, className, label },
  ...rest
) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      boxShadow: "none",
      margin: "1rem 0",
      padding: ".5rem 0",
    }),
  };

  const defaultValue = (options, value) =>
    options ? options.find((option) => option.value === value) : "";
  return (
    <Container className={`${className && className} select-wrapper`}>
      {label && <label htmlFor="select">{label}</label>}
      <Select
        onChange={(value) => onChange(value)}
        options={options}
        value={defaultValue(options, value)}
        styles={customStyles}
        {...rest}
      />
    </Container>
  );
};

export default CustomSelect;
