import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import { Link, useHistory } from "react-router-dom";
import {
  Container,
  BodyWrapper,
  CloseWrapper,
  TextWrapper,
  ButtonWrapper,
  Close,
} from "./DebitCard.styles";
import cardImage from "../../assets/card-buy.svg";
import Button from "../Button";

function DebitCardBlock({ url, onHide }) {
  const [show, setShow] = useState(true);

  const { push } = useHistory();

  const handleClose = () => {
    setShow(false);
    if (onHide) onHide();
    push(url);
  };
  return (
    <>
      {show && (
        <Container>
          <BodyWrapper>
            <CloseWrapper>
              <p className="card-text">
                Do you have an existing Access bank business debit card? If yes
                activate discount using the button below.
              </p>
            </CloseWrapper>

            <TextWrapper>
              <p>
                If no, you can request for a debit card and enjoy discounts on
                your swiftpay transactions.
              </p>
            </TextWrapper>
            <ButtonWrapper>
              <Button text="Activate Discount" className="activate-discount" />
              <Button
                onClick={handleClose}
                className="request-debit-card"
                text="Request Debit Card"
              />
            </ButtonWrapper>
          </BodyWrapper>
          <Close>
            <div className="card-image">
              <img src={cardImage} alt="cardImage" />
            </div>
            <div className="close">
              <GrClose onClick={handleClose} />{" "}
            </div>
          </Close>
        </Container>
      )}
    </>
  );
}

export default DebitCardBlock;
