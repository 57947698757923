import React from "react";
import Helmet from "react-helmet";

const TitleComponent = ({ title }) => {
  var defaultTitle = "Welcome to Access Bank!";
  return (
    <Helmet>
      <title>{title ? title : defaultTitle}</title>
      <link rel="icon" href="/favicon.ico" />
    </Helmet>
  );
};

export default TitleComponent;
