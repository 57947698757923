import styled from "styled-components";
export const Container = styled.div`
  /* display: grid;
    grid-template-columns: 100%; */
    width: 100%;
    box-sizing: border-box;
  @media screen and (min-width: 800px) {
    display: grid;
    grid-template-columns: 30% 70%;
  }
`;

export const HeaderContainer = styled.div`
  background-image: linear-gradient(to right, #003883, #009cb6);
  background-position: top right;
  background-repeat: no-repeat;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  color: white;
  height: 5rem;
  padding: 1rem 2rem;
  box-sizing: border-box;
  position: fixed;
  z-index: 1;
  top: 0;
  /* left: 100; */
  width: 100%;
  @media screen and (min-width: 800px) {
    background-image: none;
    background-color: white;
    opacity: 1;
    height:3.125rem;
    color: black;
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.151);
    z-index: 2;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0.5rem 2rem;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Greetings = styled.h3`
  position: relative;
  margin-left: 2rem;
  font-size: 1.3rem;
  /* line-height: 1rem; */

  &::before {
    content: "👋";
    position: absolute;
    left: -2rem;
    top: 0;
  }
  @media screen and (min-width: 800px) {
    display: none;
  }
`;

export const HamburgerWrapper = styled.span`
  height: min-content;
  cursor: pointer;
  /* @media screen and (min-width: 800px) {
    display: none;
  } */
`;

export const FullScreen = styled.div`
display: none;

@media screen and (min-width: 800px) {
  display: block;
  position: fixed;
width:  25vw;
height: 100vh;
background: white;
z-index: 100;
box-shadow: 5px 10px 16px rgba(0, 0, 0, 0.05);
  }

`;

export const Title = styled.h3`
display: none;
@media screen and (min-width: 800px) {
  display: block;
  text-align: center;
  color: #030f4f ;
  align-self: center;
  width: auto;
  font-size: 1.1rem;
  padding-left: 20%;
}
`;
export const LogOutWrapper = styled.div`
display: none;
@media screen and (min-width: 800px) {
  display: block;
color: #030f4f ;
align-self: flex-end;
.navbar-links{
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.3rem;
}
}
`;

