import React, { useState, useEffect } from "react";
import Header from "../../../components/dashboardHeader";
import DatePicker from "../../../components/DatePicker";
import { toast } from "react-toastify";
import moment from "moment";

import CustomButton from "../../../components/CustomButton";
import TransactionList from "../../../components/TransactionList";

import { sessionManager, encryptAccount, decryptPayload } from "../../../utils/helpers";
import Pagination from "../../../components/Pagination";

import {
  Container,
  BodyWrapper,
  HeaderWrapper,
  InputWrapper,
  ButtonWrapper,
  NoTransaction,
  DisplayWrapper,
} from "./MonthlyTransactions.styles";

import { verifyAuth } from "../../../services/authentication";
import downloadCSV from "../../../utils/downloadCSV";
import http from "../../../services/http";
import { Helmet } from "react-helmet";

async function findMonthlyTransactions(picker) {
  const user = verifyAuth();
  const { minDate, maxDate } = picker;
  let results;

  try {
    const datas = await http.post(
      `transaction/${encryptAccount(user.accountNumber)}/filter`,
      {
        minDate,
        maxDate,
      }
    );
    if (datas?.data?.data) {
      results = decryptPayload(datas.data.data);
    }
  } catch (error) {
    // toast.error('An error occured while spooling your transactions. Please try again..');
  }
  return results;
}

function MonthlyTransactions() {
  const user = verifyAuth();
  const [picker, setPicker] = useState({
    minDate: new Date("01-01-2021"),
    maxDate: new Date(),
  });
  const [results, setResults] = useState([]);
  const [filtered, setFiltered] = useState(["reload"]);
  const pagePerCount = 10;
  const [pageVisited, setPageVisited] = useState(0);
  if (typeof window !== "undefined") {
    sessionManager();
  }

  useEffect(() => {
    if (picker.minDate !== "" && picker.maxDate !== "") {
      (async () => {
        let dd = await findMonthlyTransactions(picker);
        setResults(dd);
        setFiltered(dd);
      })();
    }
  }, [picker]);

  const dataArr = {
    createdAt: "",
    transactionRef: null, 
    currency: "",
    totalAmount: 0,
    paymentType: "",
    status: "",
    productCode: "",
    productName: "",
    quantity: 0,
    accountNumber: "",
    narration: "",
    paymentFrom: "",
    storeName: "",
    channel: "",
  };

 
  const dataArrKeys = Object.keys(dataArr);

  const handleDownloadCSV = (type) => {
    if (results?.length) {
      let filteredTransactions;

      switch (type) {
        case "monthly":
          filteredTransactions = results.filter((transaction) => {
            const createdAt = moment(transaction.createdAt);
            return createdAt.isSame(new Date(), "month");
          });
          break;
        case "daily":
          filteredTransactions = results.filter((transaction) => {
            const createdAt = moment(transaction.createdAt);
            return createdAt.isSame(new Date(), "day");
          });
          break;
        default:
          break;
      }

      if (filteredTransactions.length) {
        setFiltered(filteredTransactions);
        let part = filteredTransactions.slice(
          pageVisited,
          pageVisited + pagePerCount
        );
        let files =
          filteredTransactions.length < pagePerCount
            ? filteredTransactions
            : part;
        const formattedFile = [];
        files.map((file) => {
          let initArray = {

          };
          dataArrKeys.map((curr) => {
            if(curr === "createdAt"){
              initArray['Transaction Date'] = moment(file[curr]).format("YYYY-MM-DD")
            }else if(curr === "accountNumber"){
              initArray[curr[0]?.toUpperCase() + curr?.replace(/([a-z0-9])([A-Z])/g, '$1 $2').substr(1)]=file[curr].toString()
            }
            else{
              initArray[curr[0]?.toUpperCase() + curr?.replace(/([a-z0-9])([A-Z])/g, '$1 $2').substr(1)]=file[curr]
            }
          });
          formattedFile.push(initArray);
          initArray = {};
        });
        downloadCSV({
          fileName: `REPORT ${moment().format("YYYYMMDDhhmmss")}`,
          data: formattedFile,
        });
        toast.success(`${type} report exported`);
        toast.clearWaitingQueue();
      } else {
        toast.error("No transactions for this month!");
        toast.clearWaitingQueue();
      }
    } else {
      toast.error(`No ${type} report`);
      toast.clearWaitingQueue();
    }
  };

  const handleChange = (e, v) => {
    setPicker({
      ...picker,
      [e]: v,
    });
  };

  const getPropsOfPage = (value) => {
    setPageVisited(value);
  };
  return (
    <Container>
      <Helmet>
        <title>Monthly Transactions</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <HeaderWrapper>
        <Header storeName={user?.storeName} />
      </HeaderWrapper>
      <BodyWrapper>
        <div className="title">
          <p> Monthly Transaction Reports </p>
        </div>

        <InputWrapper>
          <div className="date-picker">
            <h5>Start Date: </h5>
            <div>
              <DatePicker
                name="minDate"
                onChange={handleChange}
                placeholder="Start Date"
                date={picker.minDate}
                minDate={new Date("01-01-2021")}
                maxDate={new Date()}
              />
            </div>
          </div>
          <div className="date-picker">
            <h5>End Date: </h5>
            <div>
              <DatePicker
                name="maxDate"
                onChange={handleChange}
                placeholder="End Date"
                date={picker.maxDate}
                minDate={picker.minDate || new Date("06-01-2020")}
                maxDate={new Date()}
              />
            </div>
          </div>
        </InputWrapper>

        <ButtonWrapper>
          <CustomButton
            onClick={() => {
              handleDownloadCSV("monthly");
            }}
            shape=""
            width="100%"
            style={{ padding: "0.5rem", marginTop: "0.85rem" }}
          >
            Export
          </CustomButton>
        </ButtonWrapper>

        {filtered ? (
          filtered[0] !== "reload" && (
            <DisplayWrapper>
              <TransactionList
                transactions={
                  filtered.length < pagePerCount
                    ? filtered
                    : filtered.slice(pageVisited, pageVisited + pagePerCount)
                }
              />
              {filtered.length > pagePerCount ? (
                <Pagination
                  perPageCount={pagePerCount}
                  getPropsOfPage={getPropsOfPage}
                  data={filtered}
                />
              ) : (
                ""
              )}
            </DisplayWrapper>
          )
        ) : (
          <NoTransaction>
            <p>No transactions today!</p>
          </NoTransaction>
        )}
      </BodyWrapper>
    </Container>
  );
}

export default MonthlyTransactions;
