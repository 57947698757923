import styled from "styled-components";

export const InputWrapper = styled.div`
  width: 100%;
  /* padding: 1rem 1.4rem; */
  margin-bottom: 1.5rem;
`;

export const InputTitle = styled.div`
  font-size: 1em;
  width: 100%;
  text-align: left;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: baseline;

  span {
    color: #dc3545;
    margin-left: 0.5rem;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
  background-color: #f1f1f1;
  border: 1px solid #b3b3b3;
  color: #495057;
  background: #f8f8f8;
`;
