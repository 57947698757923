import React from "react";
import Moment from "react-moment";
import { NotificationContainer } from "./styles";

export const TransactionNotification = React.forwardRef(
  (
    {
      children,
      description,
      productName,
      productCode,
      title,
      time,
      price,
      className,
      user,
      reconciliationId,
      narration,
      ...props
    },
    ref
  ) => {
    const type =
      {
        success: "success",
        successful: "success",
        authorized: "success",
        failed: "danger",
        pending: "theme",
      }[props.type] || "theme";
    return (
      <NotificationContainer>
        <div className="header">
          <div className="payment-info">
            <p className={`${type} status`}></p>
            <p>
              <span className="user">{user}</span> paid for{" "}
              <i>
                {productName} ({productCode})
              </i>
            </p>
          </div>
          {price && (
            <p className="price">
              {props.currency || "NGN"} {new Intl.NumberFormat().format(price)}
            </p>
          )}
        </div>
        <div className="details">
          {description && <span>{description}</span>}
          <p className="transaction-id">ID: {reconciliationId}</p>
          <p className="narration">
            <span>{narration}</span>
            {time && (
              <span className="time">
                <Moment fromNow>{time}</Moment>
              </span>
            )}
          </p>
        </div>
      </NotificationContainer>
    );
  }
);
export default TransactionNotification;
