import environment from "../../environment";
import { encryptPayload } from "../../utils/helpers";
import http, { httpv2 } from "../http";

const base = '/pay/token/'

export const generateToken = (request) => {
 return http.post(base + 'generate', {data: encryptPayload(request)});
};

export const verifyToken = (request) => {
  return http.post(base + 'verify', { request });
};
 
export const payment = (url, request) => {
  return http.post('/pay' + url, {data: encryptPayload(request)});
};

export const generateSessionId = (generatedString) => {
  window.addEventListener('load', function() {
    const script = document.createElement('script');
    script.src = `https://h.online-metrix.net/fp/tags.js?org_id=${environment.hMetrix}&session_id=accessbank_socialpay${generatedString}`;
    script.type = 'text/javascript';
    document.body.appendChild(script);
  })
}

export const generateV2Token = (request) => {
  return httpv2.post('payment/generate-jwt', {data: encryptPayload(request)});
}

export const callEnrollmentV2 = (request) => {
  try {
    return httpv2.post('payment/enrollment', {data: encryptPayload(request)});
  } catch (error) {
    if (error.response){
      return error.response;
    }
    throw error
  }
};

export const validateV2 = (request) => {
  return httpv2.post('payment/validate', {data: encryptPayload(request)});
};