import styled from "styled-components";

export const MobileLayoutContainer = styled.div`
  @media screen and (max-width: 600px) and (min-width: 180px) {
    .container {
      // height: 100vh;
      // max-height:100%;
      .p-4 {
        padding: 0;
      }
      .Heading {
        font-size: 1.5rem;
        padding: 0;
        margin: 0;
      }
      h4 {
        font-size: 0.8rem;
      }

      .w-25 {
        a {
          font-size: 0.8rem;
          display: inline-block;
          padding: 4px;
        }
      }
    }
  }

  @media screen and (max-width: 780px) {
    // height: 100vh;
    //   max-height:100%;
    
    .co {
      padding: 4px 0 0 0;
      margin-top: 4px !important;
    }

    .btn {
      font-size: 0.8rem;
    }

    .margin-10 {
      margin-top: 0 !important;
    }
    .margin-6 {
      margin-top: 0 !important;
    }
  }

  .margin-10 {
    margin-top: 100px;
  }
  .margin-6 {
    margin-top: 38px;
  }
`;

export const LogoWrapper = styled.div`
width: 12rem;
height: 12rem;
margin: auto;
// margin-bottom: 1rem;

img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
`;
