import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { LogoHeader } from "../../components/MobileLayout";
import Form from "../../components/Form";
import FormInput from "../../components/FormInput";
import { LoginContainer } from "./Loginstyles";
import useAxios from "../../components/customHook/useAxios";
import AuthService from "../../services/authentication";
import { toastify } from "../../components/Toast";
import TitleComponent from "../../components/TitleComponent";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../schema/auth";

const Login = () => {
  const [loginUser, { data, error }, isLoading] = useAxios("post");
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  async function handleLogin(data) {
    if (!isLoading) {
      loginUser("/auth/vendor/login", data, true, {});
    }
  }

  useEffect(() => {
    if (data && !isLoading && !error) {
      toastify("Login successful!", "success");
      AuthService({ ...data });
    } else if (error) {
      if (error.message === "Bad Request") {
        toastify(
          `${error.error[0].toUpperCase()}${error.error.slice(1)}`,
          "error"
        );
      } else {
        toastify(error.message, "error");
      }
    }
  }, [data, error]);
  return (
    <LoginContainer>
      <TitleComponent title="Welcome back to Swift pay" />
      <br />
      <br />
      <LogoHeader />
      <br />
      <br />
      <Form
        formName="Login"
        onSubmit={handleSubmit(handleLogin)}
        autoComplete="off"
      >
        <FormInput
          labelName="Username"
          name="userName"
          className="bg-gray"
          onChange={(e) => {
            setError("userName", {
              type: "manual",
              message: "Select a userName",
            });
          }}
          {...register("userName")}
        />
        {errors.userName && (
          <p className="text-danger" style={{ fontSize: "0.7rem" }}>
            {errors.userName.message}
          </p>
        )}

        <FormInput
          labelName="Password"
          type="password"
          name="password"
          onChange={(e) => {
            setError("password", {
              type: "manual",
              message: "Select a password",
            });
          }}
          {...register("password")}
        />
        {errors.password && (
          <p className="text-danger" style={{ fontSize: "0.7rem" }}>
            {errors.password.message}
          </p>
        )}
        <div style={{padding:"1rem 0"}}>
        <input
          type="checkbox"
          name="remember"
          onChange={(e) => {
            setError("remember", {
              type: "manual",
              message: "Select remember me",
            });
          }}
          {...register("remember")}
        />
          &nbsp;&nbsp;
        <span style={{fontSize:"15px"}}>Remember Me</span>
        </div>
       
        <button className="btn default mb-5" disabled={isLoading || data}>
          Login
        </button>
      </Form>

      <div style={{ height: "20px" }} />
      <div
        className="text-dark text-center"
        style={{
          fontSize: ".9rem",
          lineHeight: "2rem",
          fontWeight: "bold",
        }}
      >
        <Link to="/forgot-password">
          <span
            className="text-dark text-center pointer"
            style={{
              fontSize: ".8rem",
            }}
          >
            {" "}
            Forgot Password?
          </span>
        </Link>
        <div style={{ height: "20px" }} />
        <div
          className="text-dark text-center"
          style={{
            fontSize: ".8rem",
            lineHeight: "1rem",
            fontWeight: "bold",
          }}
        >
          Don’t have a swiftpay account?
          <Link to="/register">
            <span
              className="text-warning pointer"
              style={{ fontSize: ".8rem" }}
            >
              {" "}
              REGISTER
            </span>
          </Link>
        </div>
        <br />
        <br />
      </div>
    </LoginContainer>
  );
};
export default Login;
