import styled from "styled-components";

export const Container = styled.div`
  /* position:relative; */
  position: relative;
  background-color: #f4fdff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
  display: flex;
  column-gap: 2rem;
  flex: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.4rem;
`;

export const BodyWrapper = styled.div`
  width: 100%;

  border-radius: 0.5rem;
  display: grid;
  grid-template-rows: auto, auto, auto;

  @media screen and (min-width: 850px) {
    max-width: 65%;
  }
`;
export const CloseWrapper = styled.div`
  color: #030f4f;
  padding-bottom: 1rem;
  font-size: 22.5px;
  font-weight: 600;
  width: 95%;
  @media screen and (min-width: 850px) {
    p {
      font-size: 1.2rem;
    }
  }
`;
export const TextWrapper = styled.div`
  width: 95%;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #030f4f;
  font-size: 15px;

  @media screen and (min-width: 800px) {
    p {
      font-size: 1.1rem;
    }
    font-weight: 600;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.4rem;
  column-gap: 1.5rem;

  .activate-discount {
    width: 50%;
    background-color: #003883;
    color: #ffffff;
    font-size: 0.8rem;
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.2rem;
    text-decoration: none;
  }
  .request-debit-card {
    width: 50%;
    background-color: #ffffff;
    color: #030f4f;
    padding: 0.5rem 0;
    display: flex;
    font-size: 0.8rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.2rem;
    text-decoration: none;
  }

  @media screen and (min-width: 800px) {
    font-size: 1.5rem;
    .activate-discount {
      min-width: 12rem; /* The width is 100%, when the viewport is 800px or smaller */
      font-size: 1rem;
    }
    .request-debit-card {
      min-width: 12rem;
      font-size: 1rem;
    }
  }
`;

export const Close = styled.div`
  display: flex;
  column-gap: 0.2rem;
  justify-content: center;
  flex: row;
  max-width: 50%;
  .close {
    position: absolute;
    top: 0;
    right: 0;
    /* align-self: flex-end; */
    padding-top: 1rem;
    padding-right: 1rem;
  }
  .card-image {
    display: none;
    
  }

  @media screen and (min-width: 850px) {
    font-size: 1.2rem;
    .card-image {
      display: block;
      min-width: 90%;
      height: 12rem;
      display: flex;
      justify-content: center;
      align-items: center;

      img{
        width: 100%;
        height: 100%;
      }
    }
  }
`;
