import React, { useState } from "react";
import { Link } from "react-router-dom";
import Heading from "../../components/Utils/Heading";
import Input from "../../components/CustomInput";
import Spinner from "react-bootstrap/Spinner";
import { ForgotPasswordContainer } from "./fogotpasswordstyles";
import useAxios from "../../components/customHook/useAxios";
import { initOtp } from "../../services/authentication";
import TitleComponent from "../../components/TitleComponent";

const ForgotPassword = () => {
  const [resetPassword, { data, error }, isLoading] = useAxios("post");
  const [userName, setUserName] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const submit = () => {
    if (!isLoading) {
      resetPassword("/auth/resetpassword", { userName }, true);
    }
    if (!isLoading && !data?.data && error) {
      setErrorMessage(error?.error);
    }
  };

  if (data?.id && !isLoading && !error) {
    initOtp("id", data.id, "confirm-password-reset");
    return <> preparing to redirect you </>;
  }

  return (
    <ForgotPasswordContainer>
      <TitleComponent title="Reset Password Swift pay" />
      <div className="default-align-card">
        <br /> <br />
        <Heading text="Forgot Password" />
        <div style={{marginTop: "1.25rem"}}>
        <span className="text-danger">{ error?.error }</span>
        <span className="text-success">
          {data?.id ? "An otp has been sent to you" : ""}{" "}
        </span>
        <Input
          data-name="username"
          autoComplete="off"
          label={"Username"}
          placeholder="John Doe_ltd"
          onKeyUp={({ target }) => setUserName(target.value)}
        />
        </div>
        <button className="btn default my-3" onClick={submit}>
          {isLoading ? (
            <Spinner animation="grow" role="status" variant="light" size="sm">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            "Send Reset Code"
          )}{" "}
        </button>
        <div
          className="text-center"
          style={{ color: "#003883", fontSize: ".8rem" }}
        >
          <Link to="/login">Go back to Login</Link>
        </div>
      </div>
    </ForgotPasswordContainer>
  );
};
export default ForgotPassword;
