import React from "react";
import { Link } from "react-router-dom";

export default function Button(props) {
  const clName = props.disabled ? " text-dark " : " ";
  const classAttr = "btn " + clName + " " + props.className;
  const props1 = { ...props };
  delete props1.isLink;
  const button = (
    <button
      disabled={props.disabled}
      {...props1}
      className={classAttr}
      type={props.type || "button"}
    >
      {" "}
      {props.text || "Button"}{" "}
    </button>
  );
  return props.isLink ? <Link to={props.url}>{button}</Link> : button;
}
