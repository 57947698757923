import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  padding: 1rem 1.4rem;
  align-items: center;
  justify-content: center;
`;
export const BodyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin: 1rem auto;
  /* margin-bottom: 1rem; */
  height: fit-content;
  /* height: 100%; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 360px) {
    margin-top: 6rem;
    padding: 0 0.5rem;
  }
`;

export const BackArrow = styled.div`
  cursor: pointer;
  width: 100%;
  padding:0 3.5rem;
  align-self: flex-start;
  margin-top: 2rem;
  .icon{
    color: black;
  }
  .icon:hover{
    fill: #003883;
    
  }
  @media screen and (max-width: 360px) {
    margin-top: 4rem;
    padding: 0 0.5rem;
  }

  @media screen and (max-width: 475px) {
    padding: 0 0.5rem;
  }

`;
export const ProductDetailWrapper = styled.div`
  padding: 1rem;
  color: black;
  font-size: 6rem;
  max-width: 400px;
  margin: 0 auto;
  height: fit-content;
  @media screen and (min-width: 768px) {
  }
`;
export const ProductDetailHeader = styled.div`
  display: grid;
  grid-template-columns: 55% auto;
  grid-gap: 0.5rem;
  justify-content: space-between;
`;

export const Underline = styled.div`
  width: 100%;
  border: 1px solid gray;
  opacity: 0.3;
  margin: 1rem 0;
`;

export const ProductDetailDiv = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;

  @media screen and (max-width: 428px) {
    .available-stock {
      font-size: 1rem;
    }
  }
`;
export const ImageWrapper = styled.div`
  align-self: center;
  width: 8rem;
  height: 8rem;
  margin: auto;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

export const AvailableStockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .available-stock {
    opacity: 0.6;
    font-size: 1rem;
  }
  /* @media screen and (max-width: 380px) {
    .available-stock {
      font-size: 0.3rem;
    }
  } */
  @media screen and (max-width: 428px) {
    .available-stock {
      font-size: 1rem;
    }
  }
`;

export const CheckoutButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
`;

export const ColoredClickWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
`;

export const ColoredClick = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  /* background-color: orange; */
  color: white;
  cursor: pointer;
`;

export const TextTitle = styled.h5`
  font-size: 1.2rem;
`;
export const AmountText = styled.h4`
  font-size: 1.3rem;
  @media screen and (max-width: 428px) {
    font-size: 1rem;
  }
`;

export const BlueShadeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(245, 130, 30, 1);
  padding: 2px 4px;
  border-radius: 3px;
  color: white;
  @media screen and (max-width: 428px) {
    font-size: 0.6rem;
  }
`;





// export const ImageWrapper = styled.div`
//   align-self: center;
//   width: 5rem;
//   height: 3rem;
//   margin: auto;
//   /* border: 1px solid yellow; */
//   /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5); */

//   img {
//     /* object-fit: contain; */
//     width: 100%;
//     height: 100%;
//   }
// `;
export const ImageDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.3rem;
  justify-content: space-between;
  /* align-items: center; */
  /* border: 1px solid red; */
`;

export const TitleWrapper = styled.h3`
  margin-bottom: 2rem;
  text-align: center;
  @media screen and (min-width: 768px) {
    font-size: 2rem;
  }
`;


export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  /* border: 1px solid green; */
`;

export const TextWrapper = styled.span`
  font-size: 1rem;
  opacity: 0.6;
`;
export const ContentWrappers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  .plus-minus {
    align-self: flex-end;
  }
`;

export const FooterWrapper = styled.div`
  margin: 1rem 0;
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  text-align: center;
`;
