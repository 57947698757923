import React from 'react'

export default function Form(props) {
  const {
    children,
    className,
    onSubmit,
    id,
    formName,
    error: errorProps
  } = props;
  return (
    <>
      <h5 className="text-bold text-dark text-bolder" style={{fontSize: "0.9rem"}}>{formName}</h5>
      <form 
        onSubmit={onSubmit}
        className={`form-group ${className}`}
        id={id} >
        <div className="text-danger">{errorProps}</div>
        {children}
      </form>
    </>
  )
}
