
const  ACCOUNT_NUMBER = "accountnumber"
export const LocalSetAccNum=(no)=>{
localStorage.setItem(ACCOUNT_NUMBER, no)
}
export const LocalGetAccNum=()=>{
return localStorage.getItem(ACCOUNT_NUMBER)??null
}

const PAYMENT_ID = "paymentId"
export const LocalSetPaymentId = (id) => {
    localStorage.setItem(PAYMENT_ID, id)
}
export const LocalGetPaymentId =() =>{
    return localStorage.getItem(PAYMENT_ID)??null
}