import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import { BiArrowBack } from "react-icons/bi";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import AccessImage from "../../assets/img/access.png";
import CustomButton from "../../components/CustomButton";
import Input from "../../components/CustomInput";
import InputField from "../../components/Input";
import {
  BodyWrapper,
  Container,
  FooterWrapper,
  FormSelect,
  InputFieldWrapper,
  InputTitleWrapper,
  SelectTextWrapper
} from "../../components/SendMoneyPage/SendMoney.styles";
import TitleComponent from "../../components/TitleComponent";
import { checkDailyTransactionLimit } from "../../services/auth.http";
// import { createPayTransaction } from "../../services/transaction";
import { UserContext } from "../../store/userStore/user.context";
import generate from "../../utils/generaterandom";
import { getServerSidePropsCode, getInitiate } from "../../utils/getServerSideProps";
import { decryptPayload, formatCurrency } from "../../utils/helpers";
import { HeaderWrapper } from "./directdebit.styles";
import { encryptReqBody, decryptResponseBody } from '../../utils/aesWrapper';



function AccountDebit({ ...props }) {
  let { push } = useHistory();
  const { id } = useParams();
  const searchParams = props.location?.search;
  const values = props?.location?.state?.detail;
  const { product, totalAmount, quantity: qty, vendor } = values;

  let amount = product?.amount || totalAmount;

  const [isLoading, setIsLoading] = useState(false);
  const [forms] = useState("NGN");
  const [toggle, setToggle] = useState(false);
  const [getData, setData] = useState();

  const quantity = qty || JSON.parse(window?.localStorage?.getItem("qty"));

  const schema = yup.object().shape({
    amount: yup
      .string()
      .matches(/^[0-9]/, "Enter a valid amount")
      .required("Enter amount")
      .default(amount.toString()),
    paymentFrom: yup
      .string()
      .trim()
      .matches(
        /^[a-zA-Z]/,
        "Please enter your first name or last name only without special characters"
      )
      .required("Please enter your firstname or lastname"),
    currency: yup.string(),
    accountNumber: yup
      .string()
      .required("Please enter your account number")
      .min(10, "Account number must be a minimum of 10 digits"),
    narration: yup.string().required(),
  });


  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const getGenerated = generate(9);
  const handleRegister = async (data) => {
    const payload = {
      accountNumber: data.accountNumber,
      amount: totalAmount || Number(data.amount),
      transactionRef: `SWFTPY${getGenerated}`,
      narration: data.narration,
      signature: data.paymentFrom,
      consent: toggle,
      vendorId: Number(vendor?.id),
      reconciliationId: `GENERATE${getGenerated}`,
      refId: values?.from?.toLowerCase() === 'adhoc' ? values.reference : `SWIFTPAY${getGenerated}`,
      productName: product?.name || "SimplePay",
      productCode: product?.code || "SMO",
      paymentFrom: data?.paymentFrom,
      currency: product?.currency || forms,
      quantity: quantity !== null ? quantity : 0,
      goto: values?.goto || 'acct-debit'
    };

    const encryptedPayload = encryptReqBody(payload)


    
    try {

      setIsLoading(true);
      const checkLimitPayload = {
        vendorId: Number(vendor.id),
        amount: totalAmount || Number(data.amount),
      }


      const checkLimit = await checkDailyTransactionLimit(checkLimitPayload);
      const decryptLimit = decryptPayload(decryptPayload(checkLimit?.data?.data));

      if (checkLimit?.status === 200 && decryptLimit?.exceedLimit === false) {
        const initiate = await getInitiate(
          { data: encryptedPayload },
          "/pay/directdebit/initiate"
        );
        const decrytedRs = decryptResponseBody(initiate?.data)

        if (initiate?.status === 201 && initiate?.data) {
          setData(initiate);
          setTimeout(() => {
            push({
              pathname: `/${id}/direct-debit-otp`,
              state: {
                signature: data.paymentFrom,
                data: decrytedRs,
                narration: payload?.narration,
                values: values,
                vendor,
              },
            });
          }, 1000);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);

        toast.error('Daily limit exceeded..', "error");
      }
    } catch (error) {
      //console.log('error===', decryptPayload(error.response.data.error))
      if (error?.response && error?.response?.status === 400) {
        // toast.error(error?.response?.data?.error?.message, "error");
        let errorObject = error?.response?.data?.error;
        let msg = errorObject;
        if (typeof errorObject === 'object') {
          msg = errorObject?.message;
        }
        toast.error(msg, "error");
      } else {
        toast.error(error?.response?.data?.error?.message);
      }
      setIsLoading(false);
    }
  };

  function backToCheckout() {
    push({
      pathname: `/${vendor?.defaultUrl}/checkout`,
      search: `${searchParams}`,
      state: { detail: values },
    });
  }

  const [accountNo, setAccountNo] = useState("");

  const checkInput = (e) => {
    const { value } = e.currentTarget;
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      if (value.length < 11) {
        setAccountNo(value);
      }
      setError("accountNumber", {
        type: "manual",
        message: "",
      });
    }
  };

  return (
    <Container>
      <TitleComponent title="Pay with Account Debit" />
      <HeaderWrapper>
        <div className="back-arrow" onClick={backToCheckout}>
          <BiArrowBack className="icon" />
        </div>
        <h3>{vendor?.storeName}</h3>
      </HeaderWrapper>
      <BodyWrapper>
        <InputFieldWrapper>
          <form onSubmit={handleSubmit(handleRegister)}>
            <SelectTextWrapper>
              <InputTitleWrapper>
                <span>Currency</span>
              </InputTitleWrapper>
              <FormSelect
                name="currency"
                defaultValue={forms || "currency"}
                onChange={(e) => {
                  setError("currency", {
                    type: "manual",
                    message: "Select a currency",
                  });
                }}
                disabled
                {...register("currency")}
              >
                <option value="NGN">NGN</option>
                <option value="USD">USD</option>
              </FormSelect>
            </SelectTextWrapper>
            <Input
              id="amount"
              name="amount"
              defaultValue={totalAmount || amount}
              onChange={(e) => {
                setError("amount", {
                  type: "manual",
                  message: "Select a currency",
                });
              }}
              disabled
              {...register("amount")}
              label={
                <>
                  Amount <span className="optional">(comma seperated)</span>
                </>
              }
              placeholder="1,000"
              onKeyUp={formatCurrency}
            />
            {errors.amount && (
              <div
                style={{ margin: "-1rem 0 0.625rem 0 ", textAlign: "left" }}
                className="text-danger"
              >
                {errors.amount.message[0].toUpperCase() +
                  errors.amount.message.slice(1)}
              </div>
            )}
            <Input
              id="paymentFrom"
              name="paymentFrom"
              onChange={(e) => {
                setError("paymentFrom", {
                  type: "manual",
                  message: "Dont Forget Your Username Should Be Cool!",
                });
              }}
              {...register("paymentFrom")}
              label={
                <div>
                  <span>Account Signature</span>
                  <br />
                  <span
                    style={{ fontSize: "0.7rem", opacity: "0.9" }}
                    className="text-danger"
                  >
                    *Firstname and Lastname
                  </span>
                </div>
              }
              placeholder="Account name eg. John Doe"
            />
            {errors.paymentFrom && (
              <div
                style={{ margin: "-1rem 0 0.625rem 0 ", textAlign: "left" }}
                className="text-danger"
              >
                {errors.paymentFrom.message[0].toUpperCase() +
                  errors.paymentFrom.message.slice(1)}
              </div>
            )}
            <InputField
              inputTitle="Account Number"
              name="accountNumber"
              value={accountNo}
              {...register("accountNumber")}
              onChange={(e) => {
                checkInput(e);
                setError("accountNumber", {
                  type: "manual",
                  message: "",
                });
              }}
            >
              <small>(10 digits)</small>
            </InputField>
            {errors.accountNumber && (
              <div
                style={{ margin: "-1rem 0 0.625rem 0", textAlign: "left" }}
                className="text-danger"
              >
                {errors.accountNumber.message}
              </div>
            )}
            <Input
              id="narration"
              name="narration"
              onChange={(e) => {
                setError("narration", {
                  type: "manual",
                  message: "Dont Forget Your Narration Should Be Cool!",
                });
              }}
              {...register("narration")}
              label={"Narration"}
              placeholder="Narration"
            />
            {errors.narration && (
              <div
                style={{ margin: "-1rem 0 0.625rem", textAlign: "left" }}
                className="text-danger"
              >
                {errors.narration.message[0].toUpperCase() +
                  errors.narration.message.slice(1)}
              </div>
            )}

            <div style={{ display: "flex" }} disabled={isLoading}>
              <input
                type="checkbox"
                onChange={() => setToggle(!toggle)}
                required={true}
              />
              <p style={{ paddingLeft: "0.625rem", marginTop: "-0.313rem" }}>
                I agree to be debited the above amount
              </p>
            </div>

            <CustomButton
              shape=""
              width="100%"
              style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={handleSubmit(handleRegister)}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  animation="grow"
                  role="status"
                  variant="light"
                  size="sm"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Pay now"
              )}
            </CustomButton>
          </form>
        </InputFieldWrapper>
      </BodyWrapper>
      <FooterWrapper>
        <span>
          <img
            src={AccessImage}
            style={{ width: "2.5rem", height: "2.5rem" }}
            alt="access logo"
          />
          <i>Verified by Access</i>
        </span>
      </FooterWrapper>
    </Container>
  );
}
export default AccountDebit;
