import styled from 'styled-components';

export const DashboardContainer = styled.div`
    max-height: 100%;

   
    
    @media screen and (max-width: 600px) and (min-width: 180px) {
        max-height: auto;
    }
    
`;
export const DashboardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0rem 1rem;
    margin-bottom: 0.5rem;
    height: 100%;
    margin: 0 auto;
    max-width: 500px;
    /* margin-left: 30%; */
    @media screen and (min-width: 800px) {
        max-width: 2500px;
        margin-left: 25%;
        padding: 0 5rem;
    }
`
export const HeaderWrapper = styled.div`
    height: 100%;
    margin-bottom: 7rem;
`;
export const BodyWrapper = styled.div`
    height: 100%;
`;
export const TransactionWrapper = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    /* grid-template-columns: 50% 50%; */
    grid-template-columns: 1fr 1fr;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    border-radius: 0.2rem;
    margin-bottom: 1rem;
    @media screen and (min-width: 850px) {
        width: 40%;
 }
`;

export const PaymentLinkWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100%;

@media screen and (min-width: 850px) {
    /* height: 10rem; */
    flex-direction: row;
    column-gap: 1rem;
 }

`;
export const ReportsWrapper = styled.div`

`;
export const TransactionListWrapper = styled.div`
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    padding: 0.5rem 1.5rem;
    border-radius: 0.2rem;
`;
export const TitleWrapper = styled.div`
display: flex;
flex-direction: row;
    justify-content: space-between;
    .title{
        font-size: 0.7rem;
    }
    .transactions{
        color: orange;
        text-decoration: underline;
    }
    margin-bottom: 1rem;

    @media screen and (min-width: 768px) {
        .title{font-size: 1.2rem;}
    }
`;