import styled from "styled-components";

export const LandingPageContainer = styled.div`
  .bg-image {
    /* Full height */
    height: 100vh;
    width: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .btn {
    height: 50px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  .text-orange {
    color: #fff;
  }
  .text-orange:hover {
    color: orange;
    outline: none;
  }

  .bg-orange {
    background: #ff8200;
  }
`;
