import React, { useState } from "react";
import { SidebarWrapper, SidebarContainer } from "./styles";
import { NavLink } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import { DatabaseIcon, FileBinaryIcon } from "@primer/octicons-react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { AiOutlineLogout } from "react-icons/ai";
import { Logout } from "../../services/authentication";
import {ArrowLeftIcon } from '@primer/octicons-react';

const Sidebar = ({ user }) => {
   let noPopper = null;
  const [display, setDisplay] = useState(false);

  if (window !== undefined) {
    noPopper = localStorage.getItem('popper');
  }

  // const handleDisplay = () => {
  //   setDisplay((prev) => !prev);
  // };

  function toggleDisplay() {
    if (display) {
      setDisplay((prev) => !prev);
    }
    else {
      noPopper = false;
      localStorage.setItem('popper', false);
      setDisplay((prev) => !prev);
    }
  }
  
  return (
    <SidebarContainer>
      <p className="title">SwiftPay</p>
      {/* <div className="menu-link" onClick={handleDisplay}>
        Menu
      </div> */}
      <div className="menu-link" onClick={toggleDisplay}>
        <div> Menu </div> {!noPopper && <div className="color-change-5x" style={{minWidth:"40%",display:'flex', justifyContent:'center', alignItems:'center', columnGap:'0.5rem'}}><span>< ArrowLeftIcon /></span><span>click me</span></div>}
      </div>
      {
        user ? (display && (
        <SidebarWrapper>
          <NavLink
            exact
            to="/dashboard"
            activeClassName="active-navbar-link"
            className="navbar-links"
            style={{ textDecoration: "none" }}
          >
            <AiOutlineHome className="navbar-icons" />{" "}
            <span className="navbar-text">Home</span>
          </NavLink>
          <NavLink
            to="/dashboard/account"
            activeClassName="active-navbar-link"
            className="navbar-links"
            style={{ textDecoration: "none" }}
          >
            <FiUser className="navbar-icons" />{" "}
            <span className="navbar-text">Profile</span>
          </NavLink>
          <NavLink
            to="/dashboard/products"
            activeClassName="active-navbar-link"
            className="navbar-links"
            style={{ textDecoration: "none" }}
          >
            <DatabaseIcon className="navbar-icons" />{" "}
            <span className="navbar-text">Products</span>
          </NavLink>
          <NavLink
            to="/dashboard/transactions"
            activeClassName="active-navbar-link"
            className="navbar-links"
            style={{ textDecoration: "none" }}
          >
            <FileBinaryIcon className="navbar-icons" />{" "}
            <span className="navbar-text">Transactions</span>
          </NavLink>
          <NavLink
            to="/dashboard/notification"
            activeClassName="active-navbar-link"
            className="navbar-links"
            style={{ textDecoration: "none" }}
          >
            <IoMdNotificationsOutline className="navbar-icons" />{" "}
            <span className="navbar-text">Notifications</span>
          </NavLink>
        </SidebarWrapper>
        )) : (display && (
          <SidebarWrapper>
          <NavLink
            to="/login"
            activeClassName="active-navbar-link"
              className="navbar-links"
              style={{textDecoration: 'none' }}
          >
            <AiOutlineLogout className="navbar-icons" />{" "}
            <span className="navbar-text" onClick={Logout}>
              Login
            </span>
          </NavLink>
              </SidebarWrapper>
      ))
      }
      
    </SidebarContainer>
  );
};

export default Sidebar;
