import React, { createContext, useReducer } from "react";
import productsReducer from "./productsReducer";

export const ProductsContext = createContext();
export const DispatchContext = createContext();
const defaultValues = { storeName: "", products: ["reload"] };

export function ProductProvider({ children }) {
  const [state, dispatch] = useReducer(productsReducer, defaultValues);
  return (
    <ProductsContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </ProductsContext.Provider>
  );
}
