import http from '../../services/http';

 export async function getServerSidePropsAccount(payloadData) {
   const { amount, customerName, customerEmail, customerPhone, currency, merchantId, vendorId } = payloadData;
    const payload = 
    {
        customerName: customerName || "Francis Obadiah",
        customerEmail: customerEmail || "francisobed01@gmail.com",
        customerPhone: customerPhone || "09079764140",
        paymentCurrency: currency || "NGN",
        paymentAmount: parseInt(amount),
        merchantId : merchantId || "19",
        vendorId,
        ...payloadData
    }
    
    try {
      const product = await http.post('/pay/vnuban/generate/', payload);  

      if(product.data){
        return product?.data?.data
      }
  
    } catch (e) {
     throw new Error(e)
    }
  }