import styled from "styled-components";

export const UserNotificationWrapper = styled.div`
  padding: 2rem;
  padding-bottom: 0;
  position: relative;

  .header {
    margin-bottom: 1rem;
    font-weight: bold;
  }
  margin: 5rem 0 3rem;

  @media screen and (min-width: 575px) {
    width: 80%;
    margin: auto;
    padding-top: 7rem;

    .header {
      margin-bottom: 2rem;
      font-size: 1.3rem;
    }
  }

  /* PAGINATION STYLING */

  .paginationBttns {
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
    text-decoration: none;
    width: max-content;
    margin: auto;
  }

  .paginationBttns a {
    width: 100%;
    padding: 10px;
    margin: 8px;
    border: 1px solid #003883;
    cursor: pointer;
    color: #003883;
    border-radius: 5px;
    text-decoration: none;
  }

  .paginationBttns a:hover {
    color: #fff;
    background: #009cb6;
  }

  .paginationActive a {
    color: #fff;
    background: #003883;
  }
  /* PAGINATION STYLING */


  @media screen and (min-width: 800px) {
    max-width: 70vw;
    position: absolute;
    right: 0;
    top: 6rem;
    padding: 0 12% 0 8%;
  }
`;

export const UserNotification = styled.div`
  * {
    margin: 0;
  }
  display: flex;
  align-items: baseline;
  padding: 0.8rem;
  border-bottom: 1px solid #afafaf84;
  color: #444444;

  .status {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    margin-right: 0.8rem;
  }
  .successful {
    background-color: #05ca05;
  }

  .details {
    font-weight: bold;
  }

  .time {
    font-size: 0.9rem;
    color: #837f7f;
  }

  @media screen and (min-width: 575px) {
    padding: 2rem;
    .details {
      font-size: 1.1rem;
      margin-bottom: 1rem;
    }
    .time {
      font-size: 1rm;
    }
  }

  @media screen and (min-width: 800px) {

  }
`;
