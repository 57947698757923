import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Open+Sans:wght@400;600;700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,700&display=swap');

@font-face {
  font-family: 'Effra';
  src:  url("assets/fonts/Effra/Effra_Std_Rg") format('truetype');
  /* src:  url("./assets/fonts/Effra/Effra_Std_Rg.otf") format('truetype'); */
}

body{
  box-sizing: border-box;

}

* {
  padding: 0;
  margin: 0;
  /* font-family: 'Nunito Sans', 'Open Sans',  'Roboto', Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
    font-family: "Effra";
  font-size: 16px;
  /* box-sizing: border-box; */
}

a {
  color: inherit;
  text-decoration: none;
}

/* * {
  box-sizing: border-box;
  font-family: 'Effra' !important;
  font-family: 'Nunito Sans', 'Open Sans',  'Roboto', sans-serif;
} */

/* @import "style";
@font-face {
  font-family: 'Effra';
  src:  url("assets/fonts/Effra/Effra_Std_Rg.otf") format('truetype');
} */
// *{
//   font-family: 'Effra' !important;
// }
// html {
//   font-size: 777rem;
// }

.text-bold {
  font-weight: bold !important;
}

.export {
  margin-left: 50px;
  width: 15%;
}

.monthly-transactions {
  list-style: none;
  text-align: center;
}

.small-font {
  font-size: 0.8rem;
}

.vibrate-1 {
	-webkit-animation: vibrate-1 0.3s linear infinite both;
  animation: vibrate-1 0.3s linear infinite both;
border: 1px solid green;
}
@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.color-change-5x {
	-webkit-animation: color-change-5x 800ms cubic-bezier(0.470, 0.000, 0.745, 0.715) infinite alternate both;
  animation: color-change-5x 800ms cubic-bezier(0.470, 0.000, 0.745, 0.715) infinite alternate both;
  font-size: 0.9rem;
  padding: 0 5px
}

 @-webkit-keyframes color-change-5x {
  0% {
    background: #536be5;
  }
  25% {
    background: rgb(76, 116, 236);
  }
  50% {
    background: #602ce4;
  }
  75% {
    background: #6fa4ca;
  }
  100% {
    background: #6fa4ca;
  }
}
@keyframes color-change-5x {
  0% {
    background: #536be5;
  }
  25% {
    background: rgb(76, 116, 236);
  }
  50% {
    background: #602ce4;
  }
  75% {
    background: #6fa4ca;
  }
  100% {
    background: #6fa4ca;
  }
}

.date-picker {
  cursor: pointer;
  max-width: rem(243px);
  width: 50%;
  min-width: 10em;
  label {
    font-style: normal;
    font-weight: 600;
    font-size: rem(13px);
    line-height: rem(19px);
  }
  input {
    width: 250px;
  }
}
`;

export default GlobalStyle;
