import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { HiMenuAlt1 } from "react-icons/hi";
import { GrClose } from "react-icons/gr";
import { AiOutlineHome } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { AiOutlineLogout } from "react-icons/ai";
import { Logout } from "../../services/authentication";
import "./Hamburger.styles.css";
import styled from "styled-components";
import { DatabaseIcon, FileBinaryIcon } from "@primer/octicons-react";
import { RemoveScrollBar } from "react-remove-scroll-bar";

const Hamburger = ({ user }) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <div onClick={() => setShowMenu(!showMenu)}>
        {showMenu ? (
          <GrClose className="toggle-navbar-menu-icon" />
        ) : (
          <HiMenuAlt1 className="toggle-navbar-menu-icon" />
        )}
      </div>
      <NavContainer showMenu={showMenu} className=".hide-scrollbar">
        <div className="title"> SwiftPay </div>
        {user ? (
          <>
            <NavLink
              exact
              to="/dashboard"
              activeClassName="active-navbar-link"
              className="navbar-links"
              style={{textDecoration: 'none' }}
            >
              <AiOutlineHome className="navbar-icons" />{" "}
              <span className="navbar-text">Home</span>
            </NavLink>
            <NavLink
              to="/dashboard/account"
              activeClassName="active-navbar-link"
              className="navbar-links"
              style={{textDecoration: 'none' }}
            >
              <FiUser className="navbar-icons" />{" "}
              <span className="navbar-text">Profile</span>
            </NavLink>
            <NavLink
              to="/dashboard/products"
              activeClassName="active-navbar-link"
              className="navbar-links"
              style={{textDecoration: 'none' }}
            >
              <DatabaseIcon className="navbar-icons" />{" "}
              <span className="navbar-text">Product</span>
            </NavLink>
            <NavLink
              to="/dashboard/transactions"
              activeClassName="active-navbar-link"
              className="navbar-links"
              style={{textDecoration: 'none' }}
            >
              <FileBinaryIcon className="navbar-icons" />{" "}
              <span className="navbar-text">Transactions</span>
            </NavLink>
            <NavLink
              to="/dashboard/notification"
              activeClassName="active-navbar-link"
              className="navbar-links"
              style={{textDecoration: 'none' }}
            >
              <IoMdNotificationsOutline className="navbar-icons" />{" "}
              <span className="navbar-text">Notifications</span>
            </NavLink>
            <NavLink
              to="/login"
              activeClassName="active-navbar-link"
              className="navbar-links"
              style={{textDecoration: 'none' }}
            >
              <AiOutlineLogout className="navbar-icons" />{" "}
              <span className="navbar-text" onClick={Logout}>
                Logout
              </span>
            </NavLink>
          </>
        ) : (
          <NavLink
            to="/login"
            activeClassName="active-navbar-link"
              className="navbar-links"
              style={{textDecoration: 'none' }}
          >
            <AiOutlineLogout className="navbar-icons" />{" "}
            <span className="navbar-text" onClick={Logout}>
              Login
            </span>
          </NavLink>
        )}
      </NavContainer>
      <Overlay showMenu={showMenu}>{showMenu && <RemoveScrollBar />}</Overlay>
    </>
  );
};

export default Hamburger;

const NavContainer = styled.nav`
  display: flex;
  width: 100%;
  height: ${({ showMenu }) => (showMenu ? "100vh" : "0")};
  opacity: ${({ showMenu }) => (showMenu ? "1" : "0")};
  background: #ffffff;
  position: absolute;
  right: 0;
  left: 0;
  top: ${({ showMenu }) => (showMenu ? "5rem" : "4.1rem")};
  flex-flow: column nowrap;
  z-index: 2;
  padding: 2rem 0rem 2rem 0;
  overflow-y: hidden;
  transform: translate(0, 0) !important;
  transition: 600ms 0ms ease-out;
  color: #030f4f;
  text-align: center;
  cursor: pointer;

  .title {
    font-size: 1.5rem;
    font-weight: 400;
    color: #030f4f;
  }

  .navbar-links {
    padding: 0.8rem;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    color: black;
    text-decoration: 'none';

  }
  

  .navbar-text {
    font-size: 1.2rem;
    font-weight: 400;
    color: #030f4f;
  }

  .navbar-icons {
    font-size: 1.5rem;
    font-weight: 400;
    margin-right: 1rem;
    width: 2rem;
    color: #030f4f;
  }

  @media screen and (min-width: 768px) {
    .title {
      font-size: 3rem;
    }
    .navbar-icons {
      font-size: 2rem;
    }
    .navbar-text {
      font-size: 2rem;
    }
  }
`;
const Overlay = styled.div`
  opacity: ${({ showMenu }) => (showMenu ? "0.7" : "0")};
  background-color: #ffffff;
  height: ${({ showMenu }) => (showMenu ? "100vh" : "0")};
  position: absolute;
  width: 100%;
  bottom: 0;
  transition: 200ms 0ms ease-out;
  top: 5rem;
  right: 0;
  z-index: ${({ showMenu }) => (showMenu ? "1" : "0")};
`;
