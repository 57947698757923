import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import defaultPlaceholder from '../../assets/default-placeholder-image.png';
import AccessImage from '../../assets/img/access.png';
import environment from '../../environment';
import { redirect } from '../../utils/helpers';
import {
  BackArrow,
  BodyWrapper,
  ColumnWrapper,
  Container,
  ContentWrapper,
  FooterWrapper,
  ImageWrapper,
  ProductDetailWrapper,
  TextWrapper,
  TitleWrapper,
  Underline,
} from './producttransaction.styles';

function ProductTransactionReceipt({ ...props }) {
  const { product, transactions, qty, orderAmount, pageUrl, paymentFrom } =
    props.location.state.detail;

  function backToProducts() {
    redirect(`${pageUrl}`);
  }

  const image =
    product?.image ||
    product?.image !== null ||
    product?.thumbnail ||
    product?.thumbnail !== null
      ? environment.BACKEND_URL + '/utils/image/' + product?.image
      : defaultPlaceholder;

  return (
    <Container>
      <BackArrow>
        <div className="back-arrow" onClick={backToProducts}>
          <BiArrowBack className="icon" />
        </div>
      </BackArrow>
      <BodyWrapper>
        <TitleWrapper>{transactions[0].storeName}</TitleWrapper>

        <ProductDetailWrapper>
          <ImageWrapper>
            <img src={image} alt="" />
          </ImageWrapper>
          <ColumnWrapper>
            <TextWrapper>Product Name:</TextWrapper>
            <ContentWrapper>
              {product?.name || 'No product name'}
            </ContentWrapper>
          </ColumnWrapper>
          <Underline />
          <ColumnWrapper>
            <TextWrapper>Quantity Purchased: </TextWrapper>
            <ContentWrapper>{qty}</ContentWrapper>
          </ColumnWrapper>
          <Underline />
          <ColumnWrapper>
            <TextWrapper>Description: </TextWrapper>
            <ContentWrapper>{product?.description}</ContentWrapper>
          </ColumnWrapper>
          <Underline />
          <ColumnWrapper>
            <TextWrapper>Order Amount: </TextWrapper>
            <ContentWrapper>
              {product?.currency} {orderAmount}
            </ContentWrapper>
          </ColumnWrapper>
          {/* <ColumnWrapper>
            <TextWrapper>Reference Id: </TextWrapper>
            <ContentWrapper>{transactions[0].refId}</ContentWrapper>
          </ColumnWrapper> */}
          <Underline />
          <ColumnWrapper>
            <TextWrapper>Payment From: </TextWrapper>
            <ContentWrapper>{paymentFrom}</ContentWrapper>
          </ColumnWrapper>
          <Underline />

          <FooterWrapper>
            <span>
              <img
                src={AccessImage}
                style={{ width: '40px', height: '40px' }}
                alt="access logo"
              />
              <i>Verified by Access</i>
            </span>
          </FooterWrapper>
        </ProductDetailWrapper>
      </BodyWrapper>
    </Container>
  );
}

export default ProductTransactionReceipt;
