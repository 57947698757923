import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import Header from "../../components/dashboardHeader/";
import DebitCardBlock from "../../components/DebitCard";
import PaymentLink from "../../components/PaymentLink";
import Reports from "../../components/Reports";
import TransactionsBlock from "../../components/TransactionBlock";
import TransactionsList from "../../components/TransactionList";
import { Helmet } from 'react-helmet';

// import { verifyAuth } from '../../services/authentication';
import { UserContext } from "../../store/userStore/user.context";
import useAxios from "../../components/customHook/useAxios";
// import AuthService from "../../services/authentication";

import getTotalAmount from "../../utils/getTotalAmount";
import { encryptAccount, } from "../../utils/helpers";

import {
  DashboardContainer,
  HeaderWrapper,
  BodyWrapper,
  TransactionWrapper,
  DashboardWrapper,
  PaymentLinkWrapper,
  ReportsWrapper,
  TransactionListWrapper,
  TitleWrapper,
} from "./Dashboard.styles";

function Dashboard( { history }) {
  const user = useContext(UserContext);

  const [getTransaction, { data, error }, isLoading, ] = useAxios("get");
  const [getAllTransations, { data: allData, error: allError }] =
    useAxios("get");
  const [getProductCount] = useAxios("get");
  const [getTransactionInfo] = useAxios("get");

  const [getData, setData] = useState("data || []");
  const [transactions, setTransaction] = useState(["reload"]);
  let firstTime = false;
  if (typeof window !== "undefined") {
    firstTime = localStorage?.getItem("showBusinessCard");
  }
  const [showBusinessCard, ] = useState(firstTime);
  const hideForNow = () => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("showBusinessCard", "true");
    }
  };
  const getTotal = getTotalAmount(allData);

  if (error?.status === 401) {
    history.push('/login')
  }
  useEffect(() => {
    if (
      user &&
      !isLoading &&
      !Array.isArray(data) &&
      !error &&
      getData &&
      transactions[0] &&
      transactions[0] === "reload"
    ) {
      getTransaction(
        "/transaction/recent/" + encryptAccount(user.accountNumber),
      );
      getProductCount("/product/count/" + user.userName);
      getTransactionInfo("/transaction/count/" + user.id);
      getAllTransations(
        "/transaction/all/" + encryptAccount(user.accountNumber)
      );
      setData(null);
    }
  }, [isLoading]);
  if (
    user &&
    !isLoading &&
    data &&
    !error &&
    !getData &&
    transactions[0] === "reload"
  ) {
    setTransaction(data);
    setData(null);
  }
  return (
    <>
      <Helmet>
      <title>Swift Pay Dashboard</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      {user && (
        <DashboardContainer>
          <HeaderWrapper>
            <Header storeName={user?.storeName} />
          </HeaderWrapper>
          <DashboardWrapper>
            {!showBusinessCard ? (
              <BodyWrapper>
                <DebitCardBlock
                  url="/dashboard/account/business-card"
                  onHide={hideForNow}
                />
              </BodyWrapper>
            ) : (
              ""
            )}

            
            <PaymentLinkWrapper>
              <TransactionWrapper>
              <TransactionsBlock
                currency="NGN"
                amount={new Intl.NumberFormat().format(getTotal.NGN)}
              />
              <TransactionsBlock
                currency="USD"
                amount={new Intl.NumberFormat().format(getTotal.USD)}
              />
              
              </TransactionWrapper>
              <PaymentLink user={user.userName} />
              
            </PaymentLinkWrapper>

            
            <ReportsWrapper>
              <Reports />
            </ReportsWrapper>
            <TransactionListWrapper>
              <TitleWrapper>
                <div className="title">Recent Transactions</div>
                <div className="title transactions">
                  {" "}
                  <Link to="/dashboard/transactions">View All</Link>
                </div>
              </TitleWrapper>
              <TransactionsList transactions={transactions} />
            </TransactionListWrapper>
          </DashboardWrapper>
        </DashboardContainer>
      )}
    </>
  );
}

export default withRouter(Dashboard);
