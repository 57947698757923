import environment from '../environment';
import crypto from 'crypto';
import generate from '../utils/generaterandom';
import { createPayTransaction } from '../services/transaction';

const { aesKey, ivKey } = environment.merchantKey;


function sideBar () {
  if (typeof window === 'undefined') {
    return;
  }
}
sideBar();
export class Cypher {
  constructor(aesKey, ivKey) {
    this.aesKey = aesKey;
    this.ivKey = ivKey;
    this.algorithm = 'aes-128-cbc';
  }

  encrypt(dataToBeEncrypted) {
    if (typeof dataToBeEncrypted !== 'string') {
      throw new Error(
        'Cypher.encrypt: argument must be string; objects must must be stringified'
      );
    }
    const cypher = crypto.createCipheriv(
      this.algorithm,
      Buffer.from(this.aesKey),
      this.ivKey
    );
    const encrypted = cypher.update(dataToBeEncrypted);
    const encryptedData = Buffer.concat([encrypted, cypher.final()]);
    return encryptedData.toString('hex');
  }

  decrypt(encryptedData) {
    if (typeof encryptedData !== 'string') {
      throw new Error('Cypher.decrypt error: argument must be string');
    }
    const decipher = crypto.createDecipheriv(
      this.algorithm,
      Buffer.from(this.aesKey),
      this.ivKey
    );
    const encryptedText = Buffer.from(encryptedData, 'hex');
    const decrypted = decipher.update(encryptedText);
    const decryptedData = Buffer.concat([decrypted, decipher.final()]);
    return decryptedData.toString();
  }
}

export const encryptPassCode = (dataToBeEncrypted) => {
  if (typeof dataToBeEncrypted !== 'string') {
    return 'Only Strings are allowed.';
  }
  const { AES_KEY, IV_KEY } = environment;
  const cipher = new Cypher(AES_KEY, IV_KEY);
  const encrypted = cipher.encrypt(dataToBeEncrypted);
  return encrypted;
};

export const generateMerchantCode = () => {
  const merchantCode = generate(15, true);
  return `SWIFTPAY${merchantCode}`;
};

export const encryptAccount = (account) => {
  if (!Number(account)) {
    return 'Only Numbers are allowed.';
  }

  const num = environment.ENCODER.split('');
  const toArray = ('' + account + '').split('');
  return toArray.map((digit) => num[digit]).join('');
};

export const encryptPaymentPayload = (payload) => {
  const cipher = new Cypher(aesKey, ivKey);
  const encrypted = cipher.encrypt(JSON.stringify(payload));
  return encrypted;
}

export const decryptPaymentPayload = (payload) => {
  const cipher = new Cypher(aesKey, ivKey);
  const decrypted = cipher.decrypt(payload);
  return decrypted;
}

export const generatePaymentPayload = async ({
  payload,
  product,
  vendor,
  quantity = 1,
}, cardType) => {


  const [expirationMonth, expirationYear] = payload.expirationDate.split('/');
  const [firstName, lastName] = payload.paymentFrom.split(' ');
  const { cvv, number } = payload;
  const merchantRef = generateMerchantCode(vendor.userName);
  const isVerveCard = cardType === 'VerveCardIcon' ? { pin: payload.pin, phoneNumber: payload.phoneNumber, email: payload.email } : {};

  const unformatAmount =
    product?.price?.replaceAll(',', '') || payload?.amount?.replaceAll(',', '');
  
  const amount = unformatAmount * quantity || unformatAmount;

  // Call Backend to pre-create
  const createTransaction = await createPayTransaction({
    merchantRef, // TODO: remove
    accountNumber: vendor.accountNumber,
    storeName: vendor.storeName,
    amount,
    totalAmount: amount,
    vendorId: Number(vendor.id),
    currency: product?.currency || payload.currency,
    status: 'PENDING',
    reconciliationId: `GENERATE${generate(10)}`,
    refId: merchantRef,
    productName: product.name || 'directproductname',
    productCode: product.code || '1234code',
    narration: payload.narration,
    channel: payload.channel || "CARD",
    paymentFrom: `${lastName} ${firstName}`
  });

  if (createTransaction.status === 200) {
    
  }

  return {
    request: {
        cardDetail: {
        firstName,
        lastName,
        number,
        cvv,
        expirationYear: `20${expirationYear}`,
        expirationMonth,
        ...isVerveCard
      },

      callbackUrl: `${environment.appUrl}/${vendor.userName}/paymentconfirm`,
      currency: product?.currency || payload.currency,
      amount,
      merchantRef,
      descriptor: {
        companyName: vendor.storeName,
        websiteLink: environment.appUrl + '/' + vendor.userName,
        address: vendor.address || 'online portal',
        locality: 'online',
        country: 'nigeria',
        phone: vendor.phoneNumber,
      },
      MDD: [
        {key: '1', value: vendor?.businessType?.toUpperCase()},
        {key: '2', value: vendor?.id.toString()},
        {key: '3', value: vendor.storeName.toUpperCase()},
        {key: '4', value: 'MEDIUM'},
        {key: '5', value: 1000000},
        {key: '6', value: vendor.transactionCurrency.toUpperCase()},
        {key: '7', value: 'WC'},
      ],
    }
  }
};
