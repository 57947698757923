//PRODUCTION
// const BACKEND_URL = 'https://swiftpay.accessbankplc.com:3001';
const BACKEND_URL = 'https://swiftpay-cloud.azurewebsites.net';
const FRONTEND_URL = 'https://swiftpay.accessbankplc.com';

 
//LOCAL
// const BACKEND_URL = 'http://localhost:3001';
// const FRONTEND_URL = 'http://localhost:3000';

//TEST
// let BACKEND_URL = 'https://10.1.7.227:5073';
// const BACKEND_URL = 'http://10.1.7.227:5071';
// const FRONTEND_URL = 'http://10.1.7.227:5070';



const environment = {
  ENCODER: 'zagxdeylqv',
  AES_KEY: 'swiftpay00000000',
  IV_KEY: 'swiftpay12345678',
  BACKEND_URL: BACKEND_URL + '/api/v1',
  BACKEND_URL_V2: BACKEND_URL + '/api/v2',

  BACKEND_SERVER: BACKEND_URL,
  UPLOAD_URL: BACKEND_URL + '/',

  appUrl: FRONTEND_URL,
  storage: {
    names: {
      uniqueVisits: 'dp_uv',
      pageVisits: 'dp_pv',
      userUniqueIdentifier: 'dp_uUI01',
      userUniqueIdentifierIp: 'dp_uUI1',
      userInformationData: 'dp_uiD',
      userInformationKey: 'dp_uiK',
      visitProduct: 'dp_vp',
    },
  },
  ussdConfig: {
    MAXIMUM_USSD_AMOUNT: 20000
  },
  hMetrix: '1snn5n9w', // Test
  // hMetrix: '' //Production,
  merchantKey: {
    aesKey: 'RokiY7Df7iPwmcbg',
    ivKey: 'g0hLoWA7lVqjD2XH'
  },
  // IDLE_TIMEOUT: 60000 * 15,
  AdHocName: "SimplePay",
  AdHocCode: "SMO"
};

export default environment;
