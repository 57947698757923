import http from "../../services/http";

export async function getServerSidePropsCode({id, transactionRef, quantity, vendorId}) {
  const payload = {
    paymentId: id,
    transactionRef,
    quantity,
    vendorId
  };

  try {
    const product = await http.post("/pay/ussd/verify", payload);

    if (product.data) {
      return product?.data?.data;
    }
  } catch (e) {
    throw new Error(e)
  }
}
