import React, { useEffect, useState } from 'react';

let min = 4; let sec = 59; let newId = 0; 
let localStorage = {
  getItem: () => {},
  setItem: () => {},
};
let _vId;

if (typeof window !== 'undefined') {
  localStorage = window.localStorage;
  newId = localStorage.getItem("newId");
  _vId = localStorage.getItem('id');  

  if (!localStorage.getItem("timer")) {
    localStorage.setItem("timer", min + "");
    localStorage.setItem("sec", sec + "");
    localStorage.setItem("newId", newId + "");
  }
  
  if (_vId === newId) {
    min = Number(localStorage.getItem("timer"));
    sec = Number(localStorage.getItem("sec"));
  }
}

const CountDownTwo =() =>{
  const [seconds, setSeconds] = useState(sec);
  const [minutes, setMinutes] = useState(min);
  
  const setLocalStorage = ({min, sec}) => {
    localStorage.setItem("sec", sec + "");
    localStorage.setItem("timer", min + "");
  }


  const resetTimer = () => {
    min=4;
    sec=59;
  };

  const calculateTimeLeft = (setS, setM) => {
    if (min <= 0 && sec <= 0) {
      setLocalStorage({min, sec})
      resetTimer();
      return;
    };
    if (sec <= 0) {
      min -= 1;
      sec = 60;
    }
    sec -= 1;
    setM(min)
    setS(sec);
    setLocalStorage({min, sec});
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      calculateTimeLeft(setSeconds, setMinutes);
    }, 1000);

    return () => clearTimeout(timer);
  });

  return [seconds, minutes, resetTimer];
}
export default CountDownTwo;