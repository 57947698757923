import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Spinner from "./components/Loader/Spinner";
import LandingPage from "./pages/LandingPage";
import Register from "./pages/Register";
import LoginPage from "./pages/LoginPage";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import OtpPage from "./pages/OtpPage";
import NotificationPage from "./pages/NotificationPage";
import Dashboard from "./pages/Dashboard";
import ProductPage from "./pages/Products";
import DailyTransactions from "./pages/TransactionsLogs/Daily";
import MonthlyTransactions from "./pages/TransactionsLogs/Monthly";
import BusinessCardRequest from "./pages/BusinessCardRequestPage";
import Profile from "./pages/Profile";
import Transactions from "./pages/Transactions";
import AddProduct from "./components/CreateProduct";
import EditProductPage from "./pages/EditProduct";
import OrderProductCard from "./components/OrderProduct";
import PaymentPage from "./components/PaymentPage";
import PaymentConfirmPage from "./components/PaymentConfirmPage";
import ReceiptPage from "./pages/ReceiptPage";
import CustomerProductPage from "./pages/CustomerProductPage";
import SendMoneyPage from "./components/SendMoneyPage";
import ProductTransactionReceipt from "./components/ProductTransactionDetail";
import TransferVerification from "./pages/TransferVerification";
import { UserProvider } from "./store/userStore/user.context";
import { ProductProvider } from "./store/productStore/product.context";
import PageNotFound from "./pages/404Page";
import RedirectPage from "./pages/redirectPage";
import ProtectedRoutes from "./pages/ProtectedRoutes";
import PaymentCheckout from "./pages/PaymentCheckout";
import MakePayment from "./pages/MakePayment";
import Page from "./pages/Page";
import SendMoney from "./pages/SendMoney";
import UssdPayment from "./pages/UssdPayment";
import VerifyUssd from "./pages/VerifyUssd"
import Success from "./pages/AccountDebit/DirectDebitSuccess"
import AccountDebit from "./pages/AccountDebit"
import DirectDebitOtpPage from "./pages/AccountDebit/OtpPage";
import AdHoc from "./components/Adhoc";
import AdHocPayment from "./components/AdHocPayment";
import Payment from "./components/Payment";

function Routing() {
  let user =
    JSON.parse(window.localStorage?.getItem("dpUdToken")) ||
    JSON.parse(window.localStorage?.getItem("vendor"));
  let userUrl = user && user.defaultUrl;

  return (
    <>
      <Router>
        <Suspense fallback={Spinner}>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/register" component={Register} />
            <Route path="/login" component={LoginPage} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/change-password" component={ChangePassword} />
            <Route path="/otp/:path" component={OtpPage} />
            <Route path="/progress" component={Page} />
            <Route
              exact
              path="/:id"
              render={({ match }) => {
                if (match.url === "/dashboard") {
                  return (
                    <UserProvider>
                      <ProtectedRoutes
                        exact
                        path="/dashboard"
                        component={Dashboard}
                      />
                    </UserProvider>
                  );
                } else if (match.url.includes("/edit-product")) {
                  return (
                    <UserProvider>
                      <ProtectedRoutes
                        exact
                        path="/edit-product"
                        component={EditProductPage}
                      />
                    </UserProvider>
                  );
                } else {
                  return <Route exact path="/:id" component={RedirectPage} />;
                }
              }}
            />
            <UserProvider>
              <Switch>
                <ProtectedRoutes
                  exact
                  path="/dashboard"
                  component={Dashboard}
                />
                <ProtectedRoutes
                  exact
                  path="/dashboard/notification"
                  component={NotificationPage}
                />
                <ProtectedRoutes
                  exact
                  path="/dashboard/transactions/daily"
                  component={DailyTransactions}
                />
                <ProtectedRoutes
                  path="/dashboard/transactions/monthly"
                  component={MonthlyTransactions}
                />
                <ProtectedRoutes
                  exact
                  path="/dashboard/account"
                  component={Profile}
                />
                <ProtectedRoutes
                  exact
                  path="/dashboard/transactions"
                  component={Transactions}
                />
                <ProtectedRoutes
                  exact
                  path="/dashboard/account/business-card"
                  component={BusinessCardRequest}
                />
                <ProtectedRoutes
                  exact
                  path="/dashboard/products"
                  component={ProductPage}
                />
                <ProtectedRoutes
                  exact
                  path="/dashboard/create-product"
                  component={AddProduct}
                />
                <ProtectedRoutes
                  exact
                  path="/edit-product"
                  component={EditProductPage}
                />

                <ProductProvider>
                  <Route
                    exact
                    path="/:id/store"
                    component={CustomerProductPage}
                  />
                  <Route exact path={`/:id/pay`} component={OrderProductCard} />
                  <Route
                    exact
                    path="/:id/checkout"
                    component={PaymentCheckout}
                  />

                  <Route exact path={`/:id/continue`} component={PaymentPage} />
                  <Route path="/:id/vnuban" component={MakePayment} />
                  <Route path="/:id/ussd-pay" component={UssdPayment} />
                  <Route
                    path="/:id/verify-vnuban"
                    component={TransferVerification}
                  />
                  <Route
                    path="/:id/verify-ussd"
                    component={VerifyUssd}
                  />
                  <Route path="/:id/acct-debit" component={AccountDebit}/>

                  <Route path="/:id/direct-debit-otp" component={DirectDebitOtpPage}/>
                  <Route path="/:id/direct-success" component={Success} />

                  <Route
                    path={`/:id/sendmoney`}
                    exact
                    component={SendMoneyPage}
                  />
                  <Route
                    path={`/:id/adhocpayment`}
                    exact
                    component={AdHocPayment}
                  />
                  <Route path="/:id/send-money" component={SendMoney} />
                  <Route path='/:id/cardpayment' component={Payment} />
                  <Route
                    exact
                    path={`/${userUrl}/paymentconfirm`}
                    component={PaymentConfirmPage}
                  />
                  <Route
                    exact
                    path={`/:id/receipt`}
                    component={ReceiptPage}
                  />
                  <Route
                    exact
                    path={`/:id/adhoc`}
                    component={AdHoc}
                  />
                  <Route
                    exact
                    path={`/:id/product`}
                    component={ProductTransactionReceipt}
                  ></Route>
                   <Route
                    exact
                    path='/payments/pay/check'
                    component={Payment}
                  ></Route>
                </ProductProvider>

                <Route component={PageNotFound} />
              </Switch>
            </UserProvider>
          </Switch>
        </Suspense>
      </Router>
    </>
  );
}
export default Routing;
