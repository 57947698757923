import {
  callEnrollmentV2,
  generateV2Token,
  validateV2,
} from "./paymentservice";
import Cardinal from "./cardinal";

import { decryptPayload, redirect } from "../../utils/helpers";
import { generateV2Payload } from "./generateinlinepayload";

class CardinalUtilsV2 {
  static async initCardinal(
    { currency, amount, source },
    { errorAlert, setIsLoading }
  ) {
    try {
      const generateJWT = await generateV2Token({
        currency,
        amount,
        source,
      });

      if (generateJWT.status === 200) {
        const decrypt = decryptPayload(generateJWT.data.data);
        const { token, ReferenceId, fingerPrint, SessionId } = decrypt.data;
        window?.CardinalUtil?.initFingerPrint(fingerPrint);
        Cardinal.setup("init", {
          jwt: token,
        });
        return { ReferenceId, SessionId };
      }
    } catch (error) {
      errorAlert("Error making payment..");
      setIsLoading(false);
    }
  }

  static async sendToCardinal(request, config) {
    const { spTransactionId } = request;
    const payload = generateV2Payload(request);
    const { setIsLoading, errorAlert, successAlert, channel, back } = config;
    const {
      amount,
      currency,
      ReferenceId,
      number,
      expirationMonth,
      expirationYear,
      cvv,
    } = payload;
    const order = {
      OrderDetails: {
        Amount: amount,
        CurrencyCode: currency || "NGN",
      },
      Consumer: {
        DFReferenceId: ReferenceId,
        Account: {
          AccountNumber: number,
          ExpirationMonth: expirationMonth,
          ExpirationYear: expirationYear,
          CardCode: cvv,
        },
      },
    };
    let enroll = null;

    Cardinal.on("payments.setupComplete", async function () {
      await Cardinal.trigger("bin.process", number);

      try {
        const decrypt = await callEnrollmentV2(payload);

        const enrollment = decryptPayload(decrypt?.data?.data)?.data;
        const consumerAuthentication =
          enrollment.consumerAuthenticationInformation;
        const TransactionId =
          consumerAuthentication?.authenticationTransactionId;
        if (decrypt.status === 200) {
          enroll = {
            TransactionId,
            status: enrollment.status,
          };
          if (enrollment.status === "AUTHENTICATION_SUCCESSFUL") {
            await validateAndAuthorize({ ...payload, enrollment: enroll });
          } else {
            Cardinal.continue(
              "cca",
              {
                ...payload,
                AcsUrl: consumerAuthentication?.acsUrl,
                Payload: consumerAuthentication?.pareq,
              },
              {
                ...order,
                OrderDetails: {
                  ...order.OrderDetails,
                  TransactionId,
                },
              }
            );
          }
        }
      } catch (error) {
        setIsLoading(false);
        if (error?.response) {
          return errorAlert(
            "Payment failed. Please confirm card details or try another card."
          );
        }
        return errorAlert("Payment failed.");
      }
    });

    Cardinal.on("payments.validated", async (responseData, jwt) => {
      if (responseData && typeof responseData === "object") {
        const action = responseData.ActionCode || responseData.ErrorNumber;
        if (action || action === 0) {
          switch (action) {
            case 0:
            case "SUCCESS":
            case "NOACTION":
              await validateAndAuthorize({ ...payload, enrollment: enroll });
              break;
            case "FAILURE":
            case "ERROR":
              setIsLoading(false);
              errorAlert("Payment failed..");
              break;
            default:
              setIsLoading(false);
          }
        }
      }
    });

    async function validateAndAuthorize(payload) {
      try {
        const decrypt = await validateV2({ ...payload, channel });
        if (decrypt.status === 200) {
          if (channel === "adhoc") {
            const alt = decryptPayload(decrypt?.data?.data)?.data;

            const { redirectLink } = alt?.updated?.alternatePayment;
            if (redirectLink) {
              window.location.href = redirectLink;
            }
          } else {
            redirect("receipt?transaction=" + spTransactionId);
          }
          setIsLoading(false);
          successAlert("Payment successful..");
        }
      } catch (error) {
        errorAlert("Could not Authorize payment...");
        setIsLoading(false);
        back();
      }
    }
  }
}

export default CardinalUtilsV2;
