

const productReducer = (state, action) => {
    switch (action.type) {
        case "ADD":
            return  {storeName: action.storeName, products: action.products};
        case "EDITPRODUCT":
            return [ {storeName: action.storeName, products: action.products }];
        case "FETCH":
            return  {storeName: action.storeName, products: action.products}
        default:
            return state;
    }
}

export default productReducer;