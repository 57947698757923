import React, { useState, useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { deformatAmount, formatCurrency } from "../../utils/helpers";
import TitleComponent from "../../components/TitleComponent";
import AccessImage from "../../assets/img/access.png";
import { LogoHeader } from "../../components/MobileLayout";
import Input from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import Spinner from "react-bootstrap/Spinner";
import environment from "../../environment";
import {
  Container,
  BodyWrapper,
  InputFieldWrapper,
  SelectTextWrapper,
  InputTitleWrapper,
  FooterWrapper,
  FormSelect,
} from "../../components/SendMoneyPage/SendMoney.styles";
import * as yup from "yup";
import { useEffect } from "react";
import { getLogo } from "../../services/auth.http";

const schema = yup.object().shape({
  amount: yup
    .string()
    .matches(/^[0-9]/, "Enter a valid amount")
    .required("Enter amount"),
  paymentFrom: yup
    .string()
    .trim()
    .matches(
      /^[a-zA-Z]+ [a-zA-Z]+$/,
      "Enter first name and last name only without special characters"
    )
    .required("Full name is required"),
  currency: yup.string(),
});

function SendMoney({ ...props }) {
  const vendor = props?.location?.state;

  let { id } = useParams();
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [forms] = useState("NGN");

  const [logo, setLogo] = useState(null);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  window.localStorage?.removeItem('qty');

  useEffect(() => {
    const img =  environment.BACKEND_URL + "/utils/image/" + vendor?.vendor?.logo;
    if (!logo) {
      getLogo(img).then((imageData) => {
        if (imageData.status === 200) {
          setLogo(img)
        }
      }).catch(err => {
      });
    }
  }, [logo, vendor]);

  const [message, setMessage] = useState(false)
  const handleRegister = async (data) => {
    const test = { 
      vendor: vendor?.vendor, 
      product: {
        amount: deformatAmount(data.amount),
        currency: data.currency,
        paymentFrom: data.paymentFrom
      }, 
      goto: "sendmoney"
    };

    push({
      pathname: `/${id}/checkout`,
      state: {
        detail: test,
      },
    });
  };

  return (
    <Container>
      <TitleComponent title="Send Money" />
      {
        vendor?.vendor?.logo &&  <LogoHeader url={logo} alt={vendor?.vendor?.storeName}/>
      }
     
      <BodyWrapper>
          <form onSubmit={handleSubmit(handleRegister)}>
            <SelectTextWrapper>
              <InputTitleWrapper>
                <span>Currency</span>
              </InputTitleWrapper>
              <FormSelect
                name="currency"
                defaultValue={forms || "currency"}
                onChange={(e) => {
                  setError("currency", {
                    type: "manual",
                    message: "Select a currency",
                  });
                }}
                {...register("currency")}
              >
                <option value="NGN">NGN</option>
                {
                  vendor?.vendor?.transactionCurrency?.toUpperCase() === 'INTERNATIONAL' && <option value="USD"  >USD</option>
                }
              </FormSelect>
            </SelectTextWrapper>
            <Input
              id="amount"
              name="amount"
              onChange={(e) => {
                setError("amount", {
                  type: "manual",
                  message: "Select a currency",
                });
              }}
              {...register("amount")}
              label={
                <>
                  Amount <span className="optional">(comma seperated)</span>
                </>
              }
              placeholder="1,000"
              onKeyUp={formatCurrency}
            />
            {errors.amount && (
              <div style={{ margin: "-1rem 0 0.625rem 0", textAlign:"left" }} className="text-danger">
                {errors.amount.message[0].toUpperCase() +
                  errors.amount.message.slice(1)}
              </div>
            )}
            <Input
              id="paymentFrom"
              name="paymentFrom"
              onChange={(e) => {
                setError("paymentFrom", {
                  type: "manual",
                  message: "Dont Forget Your Username Should Be Cool!",
                });
              }}
              {...register("paymentFrom")}
              label={"Depositor’s fullname"}
              placeholder="Fullname eg. John Doe"
            />
            {errors.paymentFrom && (
              <div style={{ margin: "-1rem 0 0.625rem 0", textAlign:"left"}} className="text-danger">
                {errors.paymentFrom.message[0].toUpperCase() + errors.paymentFrom.message.slice(1)}
              </div>
            )}

            <div className="text-success d-block m-3" style={{fontSize:"1rem", margin:"2rem 0 1rem"}} >
              {message}
            </div>

            <CustomButton
              shape=""
              width="100%"
              style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => console.log("")}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  animation="grow"
                  role="status"
                  variant="light"
                  size="sm"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Pay now"
              )}
            </CustomButton>
          </form>
      </BodyWrapper>
      <FooterWrapper>
        <span>
          <img
            src={AccessImage}
            style={{ width: "40px", height: "40px" }}
            alt="access logo"
          />
          <i>Verified by Access</i>
        </span>
      </FooterWrapper>
    </Container>
  );
}
export default SendMoney;
