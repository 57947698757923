import qs from "querystring";
import React from "react";
import PropTypes from "prop-types";

import TwitterIcon from "../../assets/twitter.svg";
import FacebookIcon from "../../assets/facebook.svg";
import WhatsAppIcon from "../../assets/whatsapp.svg";
import "./styles.css"

export const FacebookShare = ({ url }) => {
  return (
    <a href={`https://www.facebook.com/${url}`}>
      <img src={FacebookIcon} alt="facebook" />
    </a>
  );
};

export const WhatsAppShare = ({ url }) => {
  return (
    <a href={`https://api.whatsapp.com/send?text=${url}`}>
      <img src={WhatsAppIcon} alt="whatsapp" />
    </a>
  );
};

export const TwitterShare = ({ url, text }) => {
  const payload = qs.encode({
    text,
    url,
  });
  return (
    <a href={`http://twitter.com/share?${payload}`}>
      <img src={TwitterIcon} alt="twitter" />
    </a>
  );
};

const ShareSocialMedia = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <div className="social-icon">
      <FacebookShare url={props.url} />
      <WhatsAppShare url={props.url} />
      <TwitterShare url={props.url} text={props.text} />
    </div>
  );
});

ShareSocialMedia.propTypes = {
  url: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
};
ShareSocialMedia.defaultProps = {
  onSelect: () => {},
  onUpdateQuentity: () => {},
};
export default ShareSocialMedia;
