import React from "react";
import { css } from "@emotion/core";
import BounceLoader from "react-spinners/BounceLoader";
import styled from "styled-components";
// import { FaTag } from "react-icons/fa";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const LoaderContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
`;

const Header = styled.h2`
    color: #003883
`;

export default function Spinner({tag}){
  

  
    return (
      <LoaderContainer className="sweet-loading">
        <Header>{tag}</Header>
    
        <BounceLoader
          css={override}
          size={50}
          color={"#FFD700"}
          loading={true}
        />
        <Header>Loading .....</Header>
      </LoaderContainer>
    );

}