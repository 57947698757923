import axios from "axios";
import { useState, useEffect } from "react";
import { getHttpInstance } from "../../services/http";
import { encryptPayload } from "../../utils/helpers";
import HttpResponse from "./httpAxios";

/**
 * make http request easily
 * usage:
 *  const [payloadToSend, response: {data, error}, isLoading, retry] = useAxios(method: []'post,get,delete, patch')
 * @param method
 * @param headers
 */

const useAxios = (method = "get", headers = {}) => {
  const [isLoading, setIsLoadingState] = useState(false);
  const [successResponse, setSuccessResponse] = useState(null);
  const [errorResponse, setErrorResponse] = useState(null);
  const [getPayload, setRequestPayload] = useState(null);
  const [httpResponseObj, setHttpResponseObj] = useState(null);
  const [isRetry, retryRequest] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

  const $http = getHttpInstance({ method, headers });
  const resetAxios = () => {
    setIsLoadingState(false);
    retryRequest(false);
    setIsAuth(false);
    setSuccessResponse(null);
    setErrorResponse(null);
    setRequestPayload(null);
  };
  useEffect(() => {
    if (!getPayload) return;
    setIsLoadingState(true);
    setSuccessResponse(null);
    setErrorResponse(null);
    (async () => {
      async function makeAuxioCall() {
        try {
          const httpResp = new HttpResponse(
            await $http(getPayload.url, getPayload.data)
          );
          setHttpResponseObj(httpResp);
          let resp = httpResp.decrypt();
          if (isAuth) {
            resp = { encrypted: httpResp.encrypted, data: resp };
          }
          setIsLoadingState((prev) => !prev);

          if (resp.error) {
            return setErrorResponse(resp);
          }

          setErrorResponse(null);
          setSuccessResponse(resp);
          return true;
        } catch (error) {
          const httpResp = new HttpResponse(error.response?.data.message);
          let resp = httpResp.decrypt();
          if (typeof resp === "string") {
            resp = { message: resp };
          }
          setSuccessResponse(null);
          setErrorResponse({
            status: error?.response?.status || 400,
            message: error?.response?.data?.errorMessage || error?.message,
            ...error?.response?.data,
            ...error?.response?.error,
            ...resp,
          });
          setIsLoadingState((prev) => !prev);
        }
      }
      await makeAuxioCall();
    })();
  }, [getPayload]);

  const request = (url, payload = "", encrypt = false, obj, profile ) => {
    if (typeof url !== "string") {
      return console.warn(
        "Error: url must be a string",
        typeof url,
        "provided"
      );
    }
    let data = "";
    if (encrypt) {
      data = encryptPayload(payload);
    } else {
      data = payload;
    }

    if(profile){
      setRequestPayload({ url,data: data  });
    }else{
      setRequestPayload({ url,data:{data}  });
    }

    // setRequestPayload({ url,data:{data}  });
  };
  const makeAuthRequest = (...args) => {
    setIsAuth(true);
    return request(...args);
  };
  const retry = () => retryRequest((lastState) => !lastState);
  return [
    request,
    {
      data: successResponse,
      error: errorResponse,
      reset: resetAxios,
      $http: httpResponseObj,
    },
    isLoading,
    retry,
    makeAuthRequest,
    resetAxios,
  ];
};

export default useAxios;
