import React from 'react';
import styled from "styled-components";

function CustomButton(props) {
    const { width, onClick, shape, background, style, color, disabled } = props;

    let borderRadius = "";

    switch (shape.toLowerCase()) {
        case "oblong":
            borderRadius = "1.9rem";
            break;
        case "sperical":
            borderRadius = "50%";
            break;
        case "block":
            borderRadius = "0";
            break;
        default:
            borderRadius = "0.2rem";
    }

    const Button = styled.button`
        width: ${width};
        background: ${background ? background : "#003883"};
        border-radius: ${borderRadius};
        padding: 0.5rem;
        color: ${color ? color : "#ffffff"};
        outline: none;
        border: none;
        cursor: pointer;
        ${{ ...style }}
        
        :hover {
        background: #01224d}
    `;

    const handleClick = () => {
        onClick();
    }
    return (
        <Button style={{style}} onClick={handleClick} disabled={disabled || ''}>
            {props.children}
        </Button>
    )
}

export default CustomButton;
