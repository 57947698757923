import http from "../services/http";

export async function getServerSidePropsCode(payload, url) {
  try {
    const product = await http.post(url, payload);

    if (product.data) {
      return product?.data?.data;
    }
  } catch (e) {
    throw e;
  }
}

export async function getInitiate(payload, url) {
  try {
    const data = await http.post(url, payload);

    if (data.data) {
      return data?.data;
    }
  } catch (e) {
    throw e;
  }
}

