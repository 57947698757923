import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Heading, { GoBackHeading } from "../../components/Utils/Heading";
import countDown from "../../components/CountDown";
import { OtpPageContainer } from "../OtpPage/otpstyles";
import Spinner from "react-bootstrap/Spinner";
import { getServerSidePropsCode, getInitiate } from "../../utils/getServerSideProps";
import TitleComponent from "../../components/TitleComponent";
import OtpInput from "react-otp-input";
import { updateProductQuantity } from "../../services/auth.http";
import {encryptReqBody, decryptResponseBody} from '../../utils/aesWrapper';
import CountDownTwo from "../../components/CountDownAccountDebit";

const DirectDebitOtpPage = ({ ...props }) => {
  const { signature, data, narration, values, vendor } = props?.location?.state;
  const [seconds, minutes, resetTime] = CountDownTwo();
  const [isLoading, setIsLoading] = useState(false);
  const [passCode, setPassCode] = useState("");
  const { id } = useParams();
  const { push } = useHistory();

  const { product, quantity, goto } = values;
  const handleChange = (passCode) => setPassCode(passCode);

  async function handleRegister() {
    let otp = passCode;
    const payload = {
      accountNumber: data.customerAccountNumber,
      amount: Number.parseInt(data.amount),
      transactionRef: data.transactionReference,
      narration: narration,
      signature: signature || data?.customerName,
      consent: true,
      OTP: otp,
      vendorId: vendor.id,
      quantity: quantity || 0,
      goto,
    };

    let encryptedPayload =  encryptReqBody(payload)


    try {
      if (otp.length === 6) {
        setIsLoading(true);
        const initiate = await getInitiate(
          {data: encryptedPayload},
          "/pay/directdebit/debit"
        );

      const decrytedRs = decryptResponseBody(initiate?.data)

        if (initiate?.status === 200) {
          // if (quantity > 0) {
          //   await updateProductQuantity([product.code, quantity])
          // }
          toast.success("Transaction Successful", "success");
          setIsLoading(false);
          if (initiate?.alternatePayment?.goto === "adhocpayment") {
            window.location.href = initiate?.alternatePayment?.redirectLink;
          } else {
            setTimeout(() => {
              push({
                pathname: `/${vendor?.defaultUrl}/receipt`,
                search: `?transaction=${decrytedRs?.transactionReference}`,
              });
            }, 1000);
          }
        }
      }
    } catch (error) {
      if (error?.response && error?.response?.status === 400) {
        if (error?.alternatePayment?.goto === "adhocpayment") {
          window.location.href = error?.alternatePayment?.redirectLink;
        }
        toast.error(error?.response?.data?.error?.message, "error");
      } else {
        toast.error(error?.response?.data?.error?.message);
      }
      setIsLoading(false);
    }
  }

  function resendOtp() {
    let otp = passCode;
    const payload = {
      accountNumber: data.customerAccountNumber,
      amount: Number.parseInt(data.amount),
      transactionRef: data.transactionReference,
      narration: narration,
      signature: data?.customerName,
      consent: true,
      OTP: otp,
    };

    let encryptedPayload =  encryptReqBody(payload)

    resetTime();
    toast.success("OTP token has been resent.");
    getServerSidePropsCode( {data: encryptedPayload}, "/pay/directdebit/resendotp");
  }
  const handleGoBack = () => {
    push({
      pathname: `/${id}/acct-debit`,
      state: { detail: values },
    });
  };
  return (
    <OtpPageContainer>
      <TitleComponent title="Pay with Account Debit OTP" />

      <div className="row bg-white">
        <div className="col-12 col-sm text-dark p-4">
          <div className="default-align-card">
            <GoBackHeading
              text=""
              className="pb-4"
              style={{ cursor: "pointer" }}
              onGoBack={handleGoBack}
            />
            <Heading text="Enter OTP" />

            <div className="my-4 py-2" />
            <p>
              A one time verification pin has been sent to your registered phone
              number.
            </p>
            <span className="text-danger d-block text-center"></span>
            <div className="userInput">
              <OtpInput
                value={passCode}
                onChange={handleChange}
                numInputs={6}
                isInputNum={true}
              />
            </div>
            <button
              onClick={handleRegister}
              className="btn default my-4"
              disabled={passCode.length < 6 || isLoading}
            >
              {isLoading ? (
                <Spinner
                  animation="grow"
                  role="status"
                  variant="light"
                  size="sm"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Verify"
              )}
            </button>
            <span className="w-25 mx-auto d-block text-center text-dark border p-2">
              {`${minutes} : ${seconds < 10 ? "0" + seconds : seconds} `}
            </span>
            <button
              className="btn text-theme border my-3 mx-auto d-block"
              style={{
                maxWidth: "9.375rem",
                backgroundColor: "#fff",
                color: "#be5f05",
              }}
              onClick={resendOtp}
            >
              {" "}
              Resend OTP{" "}
            </button>
          </div>
        </div>
      </div>
    </OtpPageContainer>
  );
};
export default DirectDebitOtpPage;
