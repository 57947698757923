import React from "react";
import { Link } from "react-router-dom";
import image from "../../assets/PageNotFound.gif";
import TitleComponent from "../../components/TitleComponent";
import "./styles.css";


const PageNotFound = () => {
  return (
    <div style={{ position: "relative" }}>
      <TitleComponent title="404!!!" />
      <div className="page_404">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="col-sm-10 col-sm-offset-1  text-center">
                <div
                  className="four_zero_four_bg"
                  style={{ backgroundImage: image }}
                >
                  <h1 className="text-center ">404</h1>
                </div>

                <div className="contant_box_404">
                  <h3 className="h2">Looks like you're lost</h3>

                  <p>The page you are looking for is not avaible!</p>
                  <div className="link_404">
                    <Link to="/dashboard">Go to Home</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
