import styled from "styled-components";

export const FormInputContainer = styled.div`
  .form-input input.form-control,
  .form-input input,
  .form-input select {
    position: absolute;
    top: 0;
    height: 45px;
    background: rgba(33, 47, 61, 0.21);
    color: #000;
    font-size: 1rem;
  }
  .form-input {
    position: relative;
    height: 40px;
    width: 100%;
    margin: 10px auto;
    margin-bottom: 20px;
  }

  .form-input input.form-control:focus,
  .form-input input:focus,
  .form-input select:focus {
    background: rgba(196, 196, 196, 0.44);
    border: 0.5px;
    box-shadow: none;
    font-size: 0.78rem;
  }
  .form-input input.form-control:invalid,
  .form-input input:invalid,
  .form-input select:invalid {
    border: none;
    background: rgba(246, 32, 32, 0.21);
  }
  .form-input input.form-control.checkbox,
  .form-input input.form-control[type="checkbox"],
  .form-input input.checkbox,
  .form-input input[type="checkbox"],
  .form-input select.checkbox,
  .form-input select[type="checkbox"] {
    width: 20px !important;
    margin-top: -15px;
  }
  .form-input label {
    position: absolute;
    top: 13px;
    left: 13.3px;
    font-size: 0.8rem;
    line-height: 19px;
    color: rgba(141, 110, 110, 0.45);
  }

  .form-input label.focus {
    top: 3px;
    font-size: 0.8rem;
  }
  .form-input label.checkboxLabel {
    margin-left: 20px;
    margin-top: -13px;
    color: #000;
  }
  input {
    height: 50px !important;
    padding-left: 10px !important;
    border: none;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    outline: none;
  }
`;
