import http from '../../services/http';

 export async function getServerSidePropsAccount({
   accountNumber,
   transactionRef,
   vendorId,
   quantity
 }) {
    const payload = {
        accountNumber,
        transactionRef,
        vendorId,
        quantity
    }
    
    try {
      const product = await http.post('/pay/vnuban/verifyPayment/', payload);  

      if(product?.data){
        return product.data?.data
      }
  
    } catch (e) {
     throw new Error(e)
    }
  }