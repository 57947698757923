import styled from "styled-components";

export const SidebarContainer = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 30vw;
  box-shadow: 10px 0 10px -7px #b1b1b1;
  .title {
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    padding-top: 1rem;
    margin-bottom: 5rem;
  }

  .menu-link {
    color: #030f4f;
    font-weight: 600;
    border-bottom: 1px solid #d8d8d8;
    padding: 0 2rem;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    /* cursor: pointer; */

    &:hover {
      color: #009cb6;
    }
  }
`;

export const SidebarWrapper = styled.div`
  /* color: #009cb6; */
  color: #00026d;
  width: 100%;
  max-width: 30vw;

  .navbar-links {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
    padding: 1rem 0 1rem 1.5rem;
    cursor: pointer;
    font-weight: 600;
   
  }

  /* .navbar-links span{
    font-size: 1.6rem;
  } */

  .navbar-icons {
    margin-right: 0.5rem;
  }

  .active-navbar-link {
    color: #009cb6;
  }
`;
