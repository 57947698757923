import React from "react";
import PropTypes from "prop-types";

import TwitterIcon from "../assets/twitter.svg";

import ValidOtherCard from "../assets/card/credit-card.svg";
import DefaultCard from "../assets/card/card.svg";
import MasterCard from "../assets/card/mastercard.svg";
import VisaCard from "../assets/card/visa.svg";
import DiscoverCard from "../assets/card/discover.svg";
import AmericanExpressCard from "../assets/card/american-express.svg";
import InvalidCard from "../assets/card/invalid-card.svg";
import VerveCard from "../assets/card/verve2.svg";

export const IconsValue = {
  OtherIcon: ValidOtherCard,
  DefaultIcon: DefaultCard,
  MasterCardIcon: MasterCard,
  VisaCardIcon: VisaCard,
  VerveCardIcon: VerveCard,
  DiscoverCardIcon: DiscoverCard,
  AmericanExpressCardIcon: AmericanExpressCard,
  InvalidCardIcon: InvalidCard,
};
const Icons = React.forwardRef(({ icon, className, ...props }, ref) => {
  return (
      <>
    <img
      src={IconsValue[icon] || IconsValue.InvalidCardIcon}
      className="social-icon"
      style={{
        maxWidth: "50px",
        marginRight: "11px !important",
      }}
      alt= ""
    />
    </>
  );
});

Icons.propTypes = {
  url: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
};
Icons.defaultProps = {
  onSelect: () => {},
  onUpdateQuentity: () => {},
};
export default Icons;
