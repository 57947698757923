import React, { useState, useEffect, useContext } from "react";
import {  useHistory } from "react-router-dom";
import { PaymentContainer } from "./styles";
import { RiFileCopyLine } from "react-icons/ri";
import { getServerSidePropsCode } from "../../utils/getServerSideProps";
import { UserContext } from "../../store/userStore/user.context";
import Spinner from "react-bootstrap/Spinner";
import { LocalSetPaymentId } from "../../utils/localStorage";
import TitleComponent from "../../components/TitleComponent";
import generate from '../../utils/generaterandom';

const UssdPayment = ({ ...props }) => {
  let { push } = useHistory();
  const searchParams = props.location?.search;
  const user = useContext(UserContext);
  const values = props?.location?.state?.detail;
  const { vendor, product, goto, quantity: qty, totalAmount} = values;
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [transactionReference, setTransactionReference] = useState(null);
  let amount = goto === 'sendmoney' ? product?.amount : totalAmount;
  const quantity = qty || JSON.parse(window?.localStorage?.getItem("qty"));

  useEffect(() => {
    const getGenerated = generate(10);
    if (!transactionReference) {
      setTransactionReference(`SWIFTPAY${getGenerated}`)
    }

  const payload = {
    amount: totalAmount || Number(amount),
    customerId: "swiftpay",
    customerName: "Access SwiftPay",
    email: "swiftpay@accessbankplc.com",
    phoneNumber: "08133366776",
    currencyCode: "NGN",
    productCode:product?.code || 'N/A',
    billerReference: transactionReference,
    vendorId: vendor?.id,
    productName: product?.name || 'N/A',
    quantity: quantity !== null ? quantity : 0,
  }
    if (
      transactionReference
    ) {
      setIsLoading(true);
      getServerSidePropsCode(payload, '/pay/ussd/initiate')
        .then((res) => {
          setData(res);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [transactionReference]);

  const handleCancelPayment = () => {
    LocalSetPaymentId(null);
    if (user) {
      push("/dashboard/products");
    } else {
    push(`/${vendor?.defaultUrl || user?.defaultUrl}`)
    }
  };
  
  const test = {vendor: vendor, product: product}
  const handleChangePayment = () => {
    LocalSetPaymentId(null);
    if(user) {
      push({
        pathname: `/${user?.defaultUrl || vendor?.defaultUrl}/pay`,
        search: `${searchParams}`,
        state: { amount: amount, ussd: data, vendor: values?.vendor },
      });
    } else{
      push({
        pathname: `/${user?.defaultUrl || vendor?.defaultUrl}/checkout`,
        search: `${searchParams}`,
        state: { detail: test},
      });
    }
    
  };
  const handleCopy = (link) => {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = link;
    dummy.select();
    dummy.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");
    document.body.removeChild(dummy);

    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 3000);
  };
  const redirectToPayment = () => {
    // LocalSetPaymentId(null)
    push({
      pathname: `/${
        user?.defaultUrl || values?.vendor?.defaultUrl
      }/verify-ussd`,
      search: `${searchParams}`,
      state: { amount: amount, ussd: data, vendor: values?.vendor, product:product, transactionRef: transactionReference, quantity },
    });
  };

  return (
    <PaymentContainer>
      <TitleComponent title="Make Payment With Transfer" />
      <div className="heading">
        <span>Pay with USSD Code</span>
        <div
          to="/dashboard/products"
          onClick={handleChangePayment}
          className="link"
        >
          Change
        </div>
      </div>
      <div className="container">
        <p className="pay">
          <span className="pay1">Pay</span>{" "}
          <span className="pay2">
            {values?.product?.currency} {amount}
          </span>
        </p>
        <div className="sub-heading">
          Pay {values?.product?.currency} {amount} with the USSD Code below.
        </div>

        {isLoading ? (
          <div className="account-details">
            <Spinner animation="border" role="status" variant="secondary">
              <span className="sr-only">Loading...</span>
            </Spinner>
            <p className="text">USSD Code Loading...</p>
          </div>
        ) : (
          <div className="account-details">
            <div className="acct">
              {" "}
              {data?.data?.ussd || ""}
              {isCopy ? (
                <span style={{ paddingLeft: "10px" }}>copied!!!</span>
              ) : (
                <RiFileCopyLine onClick={() => handleCopy(data?.data?.ussd)} />
              )}
            </div>
            <p className="text">
              This USSD Code expires in 30 minutes and can only be used for this
              transaction
            </p>
          </div>
        )}

        <button
          className="checkout-btn"
          disabled={isLoading}
          onClick={redirectToPayment}
        >
          I've sent the money
        </button>

        <div className="btn">
          <span className="btn1" onClick={handleChangePayment}>
            {" "}
            Change Payment Method
          </span>
          <span className="btn2" onClick={handleCancelPayment}>
            {" "}
            Cancel Payment
          </span>
        </div>
      </div>

      <div className="footer">
        <span>
          <img
            src="/favicon.ico"
            style={{ width: "40px", height: "40px" }}
            alt="access logo"
          />
          <i>Verified by Access</i>
        </span>
      </div>
    </PaymentContainer>
  );
};

export default UssdPayment;
