import styled from "styled-components";

export const UpdateProfileContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 2rem 0 4rem;
  margin-bottom: 2.5rem;
  padding: 2rem;
  max-width: 40rem;

  .update-profile-header {
    font-weight: 500;
    font-size: 1.75em;
    text-align: left;
  }

  .image-wrapper {
    text-align: left;

    img {
      border-radius: 100%;
      height: 10rem;
      width: 10rem;
      object-fit: cover;
    }
  }

  .img-error {
    text-align: left;
    color: red;
    margin-top: 0.5rem;
  }

  .password-link {
    text-decoration: underline;
    color: orange;
    font-size: 0.9em;
    text-align: right;
    margin-top: 4rem;
  }

  .profile-update-button {
    padding: 0.8rem 1.5rem;
    font-size: 1em;
    color: #fff;
    background-color: #003883;
    border: none;
    margin-top: 2rem;
    border-radius: 5px;
    width: 100%;

    button {
      padding: 0 0.5rem;
    }
  }

  .select-wrapper {
    position: relative;

    .select-dropdown {
      position: absolute;
      top: 100%;
      margin-top: 0.5rem;
      left: 0;
      z-index: 2;
      border: 1px solid rgba(0, 0, 0, 0.04);
      box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
      overflow-y: scroll;
      height: 20rem;
      background: white;
      opacity: 1;
      width: 100%;

      .option {
        margin: 0;
        border-bottom: 1px solid #b3b3b3;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
        text-align: left;
        padding: 0.5rem;
        color: #495057;
        cursor: pointer;
      }
    }
  }

  @media screen and (min-width: 575px) {
    width: 70%;
    margin: auto;
    padding: 5rem 0;

    .profile-update-button {
      width: 100%;
    }
  }

  /* @media screen and (min-width: 800px) {
    margin-right: calc(100% - 12rem);
  } */
`;

export const InputContainer = styled.div`
  padding: 2rem 0;
  height: 100%;

  .colored-span {
    color: #4bc5fd;
    font-size: 0.8em;
    text-align: left;
    margin-left: 0.3rem;
  }

  .select {
    input {
      outline: none;
    }
  }
`;

export const Header = styled.h1`
  color: #18184d;
  font-size: 1.5em;
  margin-bottom: 2rem;
`;
