import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/svg/logo.svg";
import { MobileLayoutContainer,  LogoWrapper } from "./styles";

const MobileLayout = ({ children, loggedIn, className }) => {
  return (
    <MobileLayoutContainer>
      <div
        className={`container flex-column d-flex justify-content-center ${className} shadow`}
        style={{ padding: "1px", maxWidth: "400px", maxHeight:"100%" }}
      >
        {!loggedIn && <div className="p-2"> &nbsp; </div>}
        <div className="col-12"> {children}</div>
      </div>
    </MobileLayoutContainer>
  );
};

export function LogoHeader({ url, alt }) {
  return (
    <div className="d-flex justify-content-center pt-4">
      {
        url ? < LogoWrapper><img src={url} alt={alt || "Access Bank Logo"} /> </ LogoWrapper>:
        <Link to={"/"}>
        <img src={url || Logo} alt={alt || "Access Bank Logo"} />
      </Link>
      }
     
    </div>
  );
}

export default MobileLayout;
