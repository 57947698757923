/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { Helmet } from "react-helmet";
import { getAdHocInfo, getTransactionReceipt } from "../../services/auth.http";
import { redirect } from "../../utils/helpers";

function AdHocPage({ ...props }) {
  const { push } = useHistory();
  const [invalidPage, setInvalidPage] = useState(false);
  const [loading, setLoading] = useState(false);

  const [info, setInfo] = useState(null);
  const { id } = props.match.params;
  const search = props.location.search;
  const [, queryId] = search.split("=");

  useEffect(() => {
    if (invalidPage) {
      redirect('/404');
    }
    if (!id || !queryId) {
      setInvalidPage(true);
    }
  }, [invalidPage]);
  const redirectToCheckout = (data) => {
    const test = {
      vendor: data?.vendor,
      product: {
        amount: data.amount,
        currency: data.currency,
        paymentFrom: data.full_name || "",
      },
      goto: "adhocpayment",
      from: 'adhoc',
      reference: data.reference
    };
    push({
      pathname: `/${id}/checkout`,
      state: {
        detail: test,
      },
    });
  };
  useEffect(async () => {
    try {
      if (!info) {
        setLoading(true);
        const getDetails = await getAdHocInfo({
          link: queryId,
          vendorUserName: id,
        });
        if (getDetails.status === 200) {
          setInfo(getDetails?.data?.data);
        }
        setLoading(false);
      } else {
        redirectToCheckout(info);
      }
    } catch (error) {
      setInvalidPage(true);
      setLoading(false);
    }
  }, [info]);

  return loading ? (
    <Spinner animation="border" />
  ) : (
    <Helmet>
      <title>Pay </title>
      <link rel="icon" href="/favicon.ico" />
    </Helmet>
  );
}

export default AdHocPage;
