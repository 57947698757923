import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import useAxios from "../../components/customHook/useAxios";
import Form from "../../components/Form";
import FormInput from "../../components/FormInput";
import InputField from "../../components/Input";
import { LogoHeader } from "../../components/MobileLayout";
import TitleComponent from "../../components/TitleComponent";
import { toastify } from "../../components/Toast";
import { registerSchema } from "../../schema/auth";
import { initOtp } from "../../services/authentication";
import { RegisterContainer } from "./registerstyles";
const Register = () => {
  const [registerUser, { data, error }, isLoading] =
    useAxios("post");
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registerSchema),
  });

  async function handleRegister(data) {
    if (!isLoading) {
      registerUser("/auth/vendor/signup", data, true, {});
    }
  }

  useEffect(() => {
    if (data && !isLoading && !error) {
      toastify("Registration successful!", "success");
      initOtp("id", data.id, "complete-registration");
      return <> preparing to redirect you </>;
    } else if (error) {
      toastify(
        `${error.error[0].toUpperCase()}${error.error.slice(1)}`,
        "error"
      );
    }
  }, [data, error]);

  const [accountNo, setAccountNo] = useState("");

  const checkInput = (e) => {
    const { value } = e.currentTarget;
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      if (value.length < 11) {
        setAccountNo(value);
        setError("accountNumber", {
          type: "manual",
          message: "",
        });
      }
    }
  };

  return (
    <RegisterContainer>
      <TitleComponent title="Register an Account || Access Bank" />
      <br />
      <br />
      <LogoHeader />
      <br />
      <br />
      <Form
        formName="Register"
        onSubmit={handleSubmit(handleRegister)}
        autoComplete="off"
      >
        <InputField
          placeholder="Account Number"
          name="accountNumber"
          value={accountNo}
          className="account__number"
          {...register("accountNumber")}
          onChange={(e) => {
            checkInput(e);
            setError("accountNumber", {
              type: "manual",
              message: "",
            });
          }}
        />
        {errors.accountNumber && (
          <p className="text-danger" style={{ fontSize: "0.7rem" }}>
            {errors.accountNumber.message}
          </p>
        )}
        <FormInput
          labelName="Store or business name"
          name="storeName"
          id="storeName"
          type="text"
          onChange={(e) => {
            setError("storeName", {
              type: "manual",
              message: "Select a storeName",
            });
          }}
          {...register("storeName")}
        />
        {errors.storeName && (
          <p className="text-danger" style={{ fontSize: "0.7rem" }}>
            {errors.storeName.message}
          </p>
        )}

        <FormInput
          labelName="Business type/sector: e.g. Gift store"
          name="businessType"
          id="businessType"
          type="text"
          onChange={(e) => {
            setError("businessType", {
              type: "manual",
              message: "Select a businessType",
            });
          }}
          {...register("businessType")}
        />
        {errors.businessType && (
          <p className="text-danger" style={{ fontSize: "0.7rem" }}>
            {errors.businessType.message}
          </p>
        )}

        <FormInput
          labelName="Username"
          name="userName"
          id="userName"
          type="text"
          onChange={(e) => {
            setError("userName", {
              type: "manual",
              message: "Select a userName",
            });
          }}
          {...register("userName")}
        />
        {errors.userName && (
          <p className="text-danger" style={{ fontSize: "0.7rem" }}>
            {errors.userName.message}
          </p>
        )}

        <FormInput
          labelName="Password"
          type="password"
          name="password"
          onChange={(e) => {
            setError("password", {
              type: "manual",
              message: "Select a password",
            });
          }}
          {...register("password")}
        />
        {errors.password && (
          <p className="text-danger" style={{ fontSize: "0.7rem" }}>
            {errors.password.message}
          </p>
        )}
        <button
          className="btn default mb-4"
          type="submit"
          disabled={isLoading || data}
        >
          {isLoading || data ? (
            <Spinner animation="grow" role="status" variant="light" size="sm">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            "Register my store"
          )}
        </button>
      </Form>
      <div style={{ height: "20px" }} />
      <div
        className="text-dark text-center"
        style={{
          fontSize: ".8rem",
          lineHeight: "1rem",
          fontWeight: "bold",
        }}
      >
        Already have an account?
        <Link to="/login">
          <span className="text-warning pointer" style={{ fontSize: ".8rem" }}>
            {" "}
            LOGIN
          </span>
        </Link>
        <br />
        <br />
        <br />
      </div>
    </RegisterContainer>
  );
};
export default Register;
