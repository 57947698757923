import crypto from "crypto";
import environment from '../environment'

require("dotenv").config();
export const encryptReqBody = (payload) => {

  const dataToBeEncrypted = JSON.stringify(payload);
 
  if (typeof dataToBeEncrypted !== "string") {
    throw new Error(
      "the encryption function takes only strings as arguments; objects must be stringified before passage"
    );
  }
  const cypher = crypto.createCipheriv(
    "aes-128-cbc",
    Buffer.from(environment?.AES_KEY),
    environment?.IV_KEY
  );
  const encrypted = cypher.update(dataToBeEncrypted);
  const encryptedData = Buffer.concat([encrypted, cypher.final()]);
  return encryptedData.toString("hex");
};

export const decryptResponseBody = (encryptedData) => {
  if (typeof encryptedData !== "string") {
    throw new Error("Cypher.decrypt error: argument must be string");
  }
  const decipher = crypto.createDecipheriv(
    "aes-128-cbc",
    Buffer.from(environment?.AES_KEY),
    environment?.IV_KEY
  );
  const encryptedText = Buffer.from(encryptedData, "hex");
  const decrypted = decipher.update(encryptedText);
  const decryptedData = Buffer.concat([decrypted, decipher.final()]);
  return JSON.parse(decryptedData.toString());
};
