import styled from "styled-components";

export const QRWrapper = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .url {
    font-size: 1em;
    font-weight: bold;
    color: #ff8f4e;
    cursor: pointer;
    word-wrap: break-word;
    text-align: justify;
  }
`;

export const QRText = styled.p`
  font-size: 0.9em;
  margin-bottom: 1rem;
  color: #030f4f;
  text-align: left;
  @media screen and (min-width: 575px) {
    height: 4rem;
  }
`;

export const QRImage = styled.img`
  cursor: pointer;
  width: 80%;
  max-width: 200px;
  margin: 0 auto;
`;

export const ShareDetailsWrapper = styled.div`
  display: grid;
  place-content: center;
  .action {
    color: #ff8f4e;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;

    p {
      margin: 0;
      text-decoration: underline;
    }

    & > img {
      margin-right: 1rem;
    }
  }
`;
