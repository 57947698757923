import styled from "styled-components";

export const NotificationContainer = styled.div`
  * {
    margin: 0;
  }
  width: 100%;
  border-bottom: 1px solid rgba(173, 173, 173, 0.527);
  font-weight: 500;
  padding: 0 0.3rem;
  padding: 0.5rem 0;
  /* margin-bottom: 0.5rem; */

  .success {
    background-color: #28a745;
  }

  .theme {
    background: #7c6d6d;
  }

  .danger {
    background: #ff3737;
  }

  .header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .payment-info {
      display: flex;
      align-items: baseline;
      padding-right: 0.7rem;

      .status {
        width: 0.75rem;
        min-width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        margin-right: 0.5rem;
      }
    }

    .price {
      white-space: nowrap;
    }

    .user {
      font-weight: bold;
    }
  }

  .details {
    margin-left: 1.2rem;
    color: #999696;
    .transaction-id {
      font-size: 0.8rem;
      font-weight: bold;
      padding-right: 0.7rem;
    }
    .narration {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;
