import styled from "styled-components";

export const RegisterContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  padding-left: 20px;
  padding-right: 20px;
  .account__number {
    height: 50px;
    input {
      color: black;
      background: rgba(33, 47, 61, 0.21);
      ::placeholder {
        font-size: 0.8rem;
      }
    }
  }
  .btn {
    height: 50px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    background: #003883 !important;
    color: #fff;
    :hover {
      background: #01224d !important;
      font-weight: bolder;
    }
  }
  button {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    width: 100%;
    display: block;
    position: relative;
    left: 0;
  }
  button:disabled {
    background: rgba(1, 13, 27, 0.61) !important;
    border: none !important;
    color: white !important;
  }
`;
