import creditCardType from "credit-card-type";

export function cardValidator(cardNo) {
  let value = cardNo + "";
  // accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(value)) return false;

  // The Luhn Algorithm. It's so pretty.
  let nCheck = 0,
    bEven = false;
  value = value?.replace(/\D/g, "");
  for (let n = value.length - 1; n >= 0; n--) {
    let cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10);
    if (bEven) {
      if ((nDigit *= 2) > 9) nDigit -= 9;
    }
    nCheck += nDigit;
    bEven = !bEven;
  }
  return nCheck % 10 === 0;
}
const CreditCard = (value) => {
  const cards = {
    mastercard: 'MasterCard',
    maestro: 'MasterCard',
    visa: 'VisaCard',
    discover: 'DiscoverCard',
    verve: 'VerveCard',
    "american-express": 'AmericanExpressCard',
    OTHERS: 'ValidOtherCard',
    INVALID: 'InvalidCard',
    DEFAULT: 'DefaultCard',
  };
  const cardDigit = (value || "0000").toString().slice(0, 4);
  value = (value || "").split(" ").join("");
  if (isNaN(Number(value)) || value.length > 19) {
    return cards["INVALID"];
  }
  
  // TODO: Validate for Verve Card
  creditCardType.addCard({
    niceType: 'Interswitch Verve Card',
    type: 'verve',
    patterns: [[506099, 506198], [507865, 507964],[650002, 650007]],
    lengths: [16, 19],
    code: {
      name: 'CVV',
      size: 3
    }
  });
  creditCardType.changeOrder('verve', 0);
  const card = creditCardType(cardDigit);
  let cardName = card.length
    ? card[0].type
    : (value || "").length <= 4
    ? "DEFAULT"
    : "INVALID";
  return cards[cardName] || cards['INVALID'];
};

export default CreditCard;
