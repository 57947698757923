import React, { useState, useEffect, useContext } from "react";
import {  useHistory } from "react-router-dom";
import { Container } from "./styles";
import { getServerSidePropsCode } from "./verifyussd";
import CountDown from "./timer";
import { UserContext } from "../../store/userStore/user.context";
import TitleComponent from "../../components/TitleComponent";
import { LocalSetAccNum, LocalSetPaymentId } from "../../utils/localStorage";

const VerifyUssd = ({ ...props }) => {
  const [seconds, minutes, resetTimer] = CountDown();

  const [data, setData] = useState();
  const [, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  let { push } = useHistory();
  const searchParams = props.location?.search;
  const user = useContext(UserContext);
  let values = props?.location?.state;

  const { quantity, vendor } = values;

  useEffect(() => {
    setIsLoading(true);
    const interval = setInterval(() => {
      getServerSidePropsCode({
        id: values?.ussd?.data?.paymentId,
        transactionRef: values?.transactionRef,
        quantity: quantity !== null ? quantity : 0,
        vendorId: vendor?.id
      })
        .then((res) => {
          setData(res);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 30000);
    setTimeout(() => {
      clearInterval(interval);
      setError(!error);
    }, 1800000);

    return () => {
      clearInterval(interval)
    }
  }, []);

  useEffect(() => {
    let timeout = null;
    if (
      // data?.data?.amount === values?.amount &&
      data?.data?.paymentStatus === "SUCCESS"
    ) {
      LocalSetAccNum(null);
      resetTimer();
      timeout = setTimeout(() => {
        push({
          pathname: `/${user?.defaultUrl || values?.vendor?.defaultUrl}/receipt`,
          search: `?transaction=${values?.transactionRef}`,
        });
      }, 3000);
    }
    return () => {
      if (timeout) {
        clearInterval(timeout);
      }
    }
  }, [data]);
  const test = { vendor: values?.vendor, product: values?.product };
  const handleChangePayment = () => {
    resetTimer()
    LocalSetPaymentId(null);
    if (user) {
      push({
        pathname: `/${user?.defaultUrl || values?.vendor?.defaultUrl}/pay`,
        search: `${searchParams}`,
        state: { amount: values?.amount, ussd: data, vendor: values?.vendor },
      });
    } else {
      push({
        pathname: `/${user?.defaultUrl || values?.vendor?.defaultUrl}/checkout`,
        search: `${searchParams}`,
        state: { detail: test },
      });
    }
  };
  const handleCancelPayment = () => {
    resetTimer()
    LocalSetPaymentId(null);
    if (user) {
      push("/dashboard/products");
    } else {
      push(`/${values?.vendor?.defaultUrl || user?.defaultUrl}`);
    }
  };

  return (
    <Container>
      <TitleComponent title="Verify Payment" />
      <div className="heading">
        <span>Pay with USSD Code</span>
        <div onClick={handleChangePayment} className="link">
          Change
        </div>
      </div>
      <div className="container">
        <p className="pay">
          <span className="pay1">Pay</span>{" "}
          <span className="pay2">NGN {values?.amount}</span>
        </p>

        {
          // data?.data?.amount === values?.amount &&
          data?.data?.paymentStatus === "SUCCESS" ? (
            <div
              style={{
                color: "green",
                textAlign: "center",
                padding: "70px 0px",
                fontSize: "25px",
              }}
            >
              {" "}
              PAYMENT RECEIVED
            </div>
          ) : (
            <>
              <div className="sub-heading">
                We're waiting to receive your transfer.
                <p>This can take up to a minute or more...</p>
              </div>
              {/* {!error && (
                <div
                  style={{
                    color: "#f44336",
                    textAlign: "center",
                    padding: "70px 0px",
                    fontSize: "25px",
                  }}
                >
                  Transaction Failed
                </div>
              )} */}
              <div className="timer">
                <span>
                  {`${minutes} : ${seconds < 10 ? "0" + seconds : seconds} `}
                </span>
              </div>
            </>
          )
        }

        <div className="btn">
          <span className="btn1" onClick={handleChangePayment}>
            {" "}
            Change Payment Method
          </span>
          <span className="btn2" onClick={handleCancelPayment}>
            {" "}
            Cancel Payment
          </span>
        </div>
      </div>

      <div className="footer">
        <span>
          <img
            src="/favicon.ico"
            style={{ width: "40px", height: "40px" }}
            alt="access logo"
          />
          <i>Verified by Access</i>
        </span>
      </div>
    </Container>
  );
};

export default VerifyUssd;
