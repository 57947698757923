import React, { useContext, useState } from "react";
import { Redirect, Route } from "react-router";
import environment from "../environment";
import { Logout } from "../services/authentication";
import { UserContext } from "../store/userStore/user.context";
import { sessionManager } from "../utils/helpers";
import IdleTimer from 'react-idle-timer'

let idleTimer = null;

const ProtectedRoutes = ({ component: Component, ...rest }) => {
  const user = useContext(UserContext);
  const [isTimedOut, setTimedOut] = useState(true);
  sessionManager();

  const onAction = (e) => {
    setTimedOut(false);
  };

  const onActive = (e) => {
    setTimedOut(false);
  };

  const onIdle = (e) => {
    if (isTimedOut) {
      Logout();

      // navigate("/");
    } else {
      idleTimer.reset();

      setTimedOut(true);
    }
  };
  return (
    <Route
      {...rest}
      render={(props) => {
        return user && user !== null ? (
          <>
            {/* <IdleTimer
              key="idleTimer"
              startOnMount={true}
              ref={(ref) => {
                idleTimer = ref;
              }}
              element={document}
              onActive={onActive}
              onIdle={onIdle}
              onAction={onAction}
              timeout={30000}
            /> */}

            <Component {...props} />
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoutes;
