import React, { useState, useEffect, useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { BiArrowBack } from "react-icons/bi";

// import { encryptPaymentPayload, generateMerchantCode, generatePaymentPayload } from "../../utils";
// import {  gateWaySchema } from "../../schema/payment";
import {
  formatCardNumber,
  formatExpiration,
  formatCurrency,
  decryptPayload,
  redirect
} from "../../utils/helpers";
import validCardType from "../../utils/card";
import { createTransaction } from "../../services/auth.http";

import AccessImage from "../../assets/img/access.png";
import { UserContext } from "../../store/userStore/user.context";
import Input from "../CustomInput";
import PasswordInput from "../CustomInput/PasswordInput";
import CustomButton from "../CustomButton";
import Spinner from "react-bootstrap/Spinner";

import {
  Container,
  HeaderWrapper,
  BodyWrapper,
  InputFieldWrapper,
  SelectTextWrapper,
  InputTitleWrapper,
  FooterWrapper,
  TextArea,
  CvvExpDateWrapper,
  FormSelect,
} from "./SendMoney.styles";
import { useHistory } from "react-router";
import generate from "../../utils/generaterandom";
// import CardinalUtils from "../../services/payment/cardinalutil";
// import { generateSessionId } from "../../services/payment/paymentservice";
import CardinalUtilsV2 from "../../services/payment/cardinalutilv2";
import { toast } from "react-toastify";
import environment from "../../environment";

function SendMoneyPage({ ...props }) {
  const values = props.location?.state?.detail;
  const state = props.location?.state;
  const { push } = useHistory();
  const { product } = values;
  const vendor =
    useContext(UserContext) ||
    JSON.parse(window.localStorage?.getItem("vendor")) ||
    values?.vendor;

  const [errorMessage, setErrorMessage] = useState("");
  const [cardType, setCardType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [forms] = useState(product?.currency);
  const cardSchema = yup.object().shape({
    expirationDate: yup
      .string()
      .matches(
        /^(0[1-9]|1[0-2])\/?(([0-9]{4}|[0-9]{2})$)/g,
        "invalid card expiration date"
      )
      .required("Expiry Date is required."),
    number: yup
      .string()
      .min(16, "Invalid card number")
      .max(25, "Invalid card number")
      .required("Credit Card number is required."),
    cvv: yup.string().min(3).max(8).required("CVV is required."),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(cardSchema),
  });

  const handleCardType = ({ target }) => {
    const getCard = validCardType(target.value);
    if (getCard) {
      setCardType(getCard + "Icon");
    }
  };

  const makePayment = async (payload) => {
    setIsLoading(true);
    const successAlert = toast.success;
    const errorAlert = toast.error;
    try {
      const amount = product?.amount;

      const currency = product?.currency;
      const reconciliationId = `SWIFTCRD${generate(10)}`;

      const { ReferenceId, SessionId } = await CardinalUtilsV2.initCardinal(
        { currency, amount: amount.toString(), source: environment.AdHocName },
        {
          setIsLoading,
          errorAlert,
        }
      );

      const addTrans = await createTransaction({
        merchantRef: reconciliationId, // TODO: remove
        accountNumber: vendor.accountNumber,
        storeName: vendor.storeName,
        amount,
        totalAmount: amount,
        vendorId: Number(vendor.id),
        currency,
        reconciliationId,
        refId: values.reference,
        productName: environment.AdHocName,
        productCode: environment.AdHocCode,
        narration: "N/A",
        channel: payload?.channel || "CARD",
        paymentFrom: product?.paymentFrom,
        transactionCurrencyType:
          currency.toLowerCase() === "usd" ? "INTERNATIONAL" : "LOCAL",
        transactionRef: reconciliationId,
      });

      const decrypt = decryptPayload(addTrans?.data?.data);
      if ([200, 201].includes(addTrans.status)) {
        // Start Cardinal stuffs...
        await CardinalUtilsV2.sendToCardinal(
          {
            card: payload?.number,
            cvv: payload?.cvv,
            expireDate: payload?.expirationDate,
            accountNumber: vendor?.accountNumber,
            amount: amount.toString(),
            vendorId: Number(vendor?.id),
            currency: product?.currency || payload?.currency,
            narration: payload?.narration || "N/A",
            channel: product?.channel || "CARD",
            paymentFrom: product?.paymentFrom,
            payerName: product?.paymentFrom,
            merchantRef: reconciliationId,
            ReferenceId,
            spTransactionId: decrypt.transactionRef,
            productName:
              product?.name || values?.from?.toUpperCase() || "SEND-MONEY",
            cardType,
            vendor,
            SessionId,
            source: environment.AdHocName,
          },
          {
            successAlert,
            errorAlert,
            setIsLoading,
            channel: "adhoc",
            back: backToSendMoney,
          }
        );
      } else {
        errorAlert("Server Error Occured.. ");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        errorAlert(error.response.data.error);
      } else {
        errorAlert("Network error occured ");
      }
      setIsLoading(false);
    }
  };

  function backToSendMoney() {
    if (values?.from === "adhoc") {
      setTimeout(() => {
        redirect(`${vendor?.secondaryUrl}`);
      }, 1500);
    } else {
      push({
        pathname: `/${vendor?.defaultUrl}/send-money`,
        state: { vendor: vendor },
      });
    }
  }

  return (
    <Container>
      <HeaderWrapper>
        <div className="back-arrow" onClick={backToSendMoney}>
          <BiArrowBack className="icon" />
        </div>
        <h3>{vendor.storeName}</h3>
      </HeaderWrapper>
      <BodyWrapper>
        <InputFieldWrapper>
          {/* <form id="sandbox-form">
            <input name="data" type="hidden" value="" id="data" />
          </form> */}
          <form onSubmit={handleSubmit(makePayment)}>
            <SelectTextWrapper>
              <InputTitleWrapper>
                <span>Currency</span>
              </InputTitleWrapper>
              <FormSelect defaultValue={forms || "currency"} disabled>
                <option value={product?.currency}>{product?.currency}</option>
              </FormSelect>
            </SelectTextWrapper>
            <Input
              defaultValue={product?.amount}
              label={
                <>
                  Amount <span className="optional">(comma seperated)</span>
                </>
              }
              placeholder="1,000"
              onKeyUp={formatCurrency}
              disabled
            />
            <Input
              defaultValue={product?.paymentFrom}
              disabled
              label={"Depositor’s fullname"}
              placeholder="Fullname eg. John Doe"
            />
            {/* <Input
              id="narration"
              name="narration"
              onChange={(e) => {
                setError("narration", {
                  type: "manual",
                  message: "Narration field cannot be empty!",
                });
              }}
              {...register("narration")}
              label={"Narration"}
              placeholder="Comment to vendor"
            />
            {errors.narration && (
              <div className="text-danger" style={{textAlign:'left', marginTop:"-1rem", marginBottom:'0.7rem'}}>{errors.narration.message}</div>
            )} */}
            {/* <label htmlFor="deliveryAddress">
              <span>Delivery address </span>{" "}
              <span className="optional"> (Optional)</span>
            </label>
            <TextArea
              id="deliveryAddress"
              name="delivery"
              {...register("delivery")}
              onChange={(e) => {
                setError("delivery", {
                  type: "manual",
                  message: "Enter an Address!",
                });
              }}
              size="4"
              placeholder="Delivery Address"
              className="mb-4"
            /> */}
            {/* {errors.delivery && <div className="text-danger">{errors.delivery.message}</div>} */}
            <Input
              name="number"
              onChange={(e) => {
                setError("number", {
                  type: "manual",
                  // message: "Enter a valid card number!"
                });
              }}
              {...register("number")}
              label={"Credit card number"}
              icon={"CreditCardIcon"}
              iconType={cardType}
              placeholder="0000 0000 0000 0000"
              onKeyUp={formatCardNumber}
              onBlur={handleCardType}
              style={{ fontSize: "1.05rem", color: "#000000" }}
            />
            {errors.number && (
              <div
                className="text-danger"
                style={{
                  textAlign: "left",
                  marginTop: "-1rem",
                  marginBottom: "0.7rem",
                }}
              >
                {errors.number.message}
              </div>
            )}
            <CvvExpDateWrapper>
              <div>
                <Input
                  id="expirationDate"
                  name="expirationDate"
                  label={"Expiry date"}
                  placeholder="MM/YY"
                  onKeyUp={formatExpiration}
                  onChange={(e) => {
                    setError("expirationDate", {
                      type: "manual",
                      message: "The expiring date is invalid!",
                    });
                  }}
                  {...register("expirationDate")}
                  size="5"
                  style={{ fontSize: "1.05rem", color: "#000000" }}
                />
                {errors.expirationDate && (
                  <div
                    className="text-danger"
                    style={{
                      textAlign: "left",
                      marginTop: "-1rem",
                      marginBottom: "0.7rem",
                    }}
                  >
                    {errors.expirationDate.message}
                  </div>
                )}
              </div>
              <div>
                <Input
                  id="cvv"
                  name="cvv"
                  size="4"
                  label={"CVV/CVN"}
                  onChange={(e) => {
                    setError("cvv", {
                      type: "manual",
                      message: "The Cvv is invalid!",
                    });
                  }}
                  {...register("cvv")}
                  placeholder="****"
                  type="password"
                  style={{ fontSize: "1.05rem", color: "#000000" }}
                />
                {errors.cvv && (
                  <div
                    className="text-danger"
                    style={{
                      textAlign: "left",
                      marginTop: "-1rem",
                      marginBottom: "0.7rem",
                    }}
                  >
                    {errors.cvv.message}
                  </div>
                )}
              </div>
            </CvvExpDateWrapper>

            {cardType === "VerveCardIcon" && (
              <PasswordInput
                name="pin"
                label={
                  <>
                    PIN{" "}
                    <small className="text-danger">(verve cards only)</small>
                  </>
                }
                placeholder="****"
                {...register("pin")}
                size="4"
                isRequired={false}
              />
            )}

            {cardType === "VerveCardIcon" && (
              <Input
                id="email"
                name="email"
                {...register("email")}
                label={"Email"}
                placeholder="Email"
              />
            )}
            {errors.email && (
              <div
                className="text-danger"
                style={{
                  textAlign: "left",
                  marginTop: "-1rem",
                  marginBottom: "0.7rem",
                }}
              >
                {errors.email.message[0].toUpperCase() +
                  errors.email.message.slice(1)}
              </div>
            )}

            {cardType === "VerveCardIcon" && (
              <Input
                id="phoneNumber"
                name="phoneNumber"
                {...register("phoneNumber")}
                label={"Phone Number"}
                placeholder="Phone Number"
              />
            )}
            {errors.phoneNumber && (
              <div
                className="text-danger"
                style={{
                  textAlign: "left",
                  marginTop: "-1rem",
                  marginBottom: "0.7rem",
                }}
              >
                {errors.phoneNumber.message}
              </div>
            )}
            <br />

            <div className="text-danger">{errorMessage}</div>

            <CustomButton
              shape=""
              width="100%"
              style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => console.log()}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  animation="grow"
                  role="status"
                  variant="light"
                  size="sm"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Pay now"
              )}
            </CustomButton>
          </form>
        </InputFieldWrapper>
      </BodyWrapper>
      <FooterWrapper>
        <span>
          <img
            src={AccessImage}
            style={{ width: "40px", height: "40px" }}
            alt="access logo"
          />
          <i>Verified by Access</i>
        </span>
      </FooterWrapper>
    </Container>
  );
}

export default SendMoneyPage;
