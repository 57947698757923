import { redirect } from '../utils/helpers';
import { createTransaction, updateTransaction } from './auth.http';
// import http from './http';

export const storeTransactionInformation = async (
  { payload, vendor, product, quantity },
  callback = () => {}
) => {
  try {
    const { amountDetails } = payload.orderInformation;
    const {
      paymentFrom,
      // transactionId,
      status,
      // refId,
      id,
      reconciliationId,
      narration,
      merchantRef
    } = payload;
    // const { id: vendorId } = vendor;

    // const rPayload = {
    //   paymentFrom,
    //   vendorId,
    //   channel: 'CARD',
    //   ...amountDetails,
    //   refId: merchantRef,
    //   status,
    //   productCode: product.code || vendor.storeName,
    //   productName: product.name || vendor.storeName,
    //   reconciliationId: reconciliationId || id,
    //   narration,
    //   quantity,
    // };

    const {success} = await updatePayTransaction({
      paymentFrom,
      channel: 'CARD',
      merchantRef,
      status,
      productCode: product.code || vendor.storeName,
      productName: product.name || vendor.storeName,
      reconciliationId: reconciliationId || id,
      narration,
      quantity,
      ...amountDetails
    });

    if (success) {
      redirect(
        'receipt?transaction=' + reconciliationId
      );
    }

    // const request = await http.post('transaction/' + vendorId, rPayload);

    // if (request?.status === 201 && request?.data?.data) {
    //   redirect(
    //     'receipt?transaction=' + request?.data?.data?.reconciliationId ||
    //       request?.data?.data?.id
    //   );
    //   callback(null, request?.data?.data);
    // } else {
    //   callback(request?.data?.data, null);
    // }
  } catch (e) {
    setTimeout(() => callback(e?.response?.data || e, null), 2000);
  }
  callback({ id: 'yes we did' });
};

export const createPayTransaction = async (payload) => {
  try {
    await createTransaction(payload);
    return {
      success: true,
    }
  } catch (error) {
    return {
      success: false
    }
  }
}

export const updatePayTransaction = async (payload) => {
  try {
    const transaction = await updateTransaction(payload);
    if (!transaction?.data){
      return {
        success: false,
      }
    }
    return {
      success: true,
      transaction: transaction.data,
    }
  } catch (error) {
    return {
      success: false,
    }
  }
}
