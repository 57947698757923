import React, { useContext, useEffect, useState } from "react";
import useAxios from "../../components/customHook/useAxios";
import Moment from "react-moment";
import { UserContext } from "../../store/userStore/user.context";
import { sessionManager } from "../../utils/helpers";
import Navbar from "../../components/dashboardHeader";
import { UserNotification, UserNotificationWrapper } from "./styles";
import TitleComponent from "../../components/TitleComponent";
import ReactPaginate from "react-paginate";
import Pagination from "../../components/Pagination";

const NotificationPage = () => {
  const vendor = useContext(UserContext);

  const [getTransaction, { data, error }, isLoading, retry] = useAxios("get");
  const [getData, setData] = useState("data || []");
  const [transactions, setTransaction] = useState(["reload"]);
  if (typeof window !== "undefined") {
    sessionManager();
  }

  
  const transactionsPerPage = 5;
  const [pageVisited, setPageVisited] = useState(0);

  

  useEffect(() => {
    if (
      vendor &&
      !isLoading &&
      !Array.isArray(data) &&
      !error &&
      getData &&
      transactions[0] &&
      transactions[0] === "reload"
    ) {
      getTransaction("/notification/" + vendor.id);
      setData(null);
    }
  }, [data, error, getData, getTransaction, isLoading, transactions, vendor]);

  if (
    vendor &&
    !isLoading &&
    data &&
    !error &&
    !getData &&
    transactions[0] === "reload"
  ) {
    setTransaction(data);
    setData(null);
  }
  const getPropsOfPage = (value) => {
    setPageVisited(value);
  };

  return (
    <>
      <TitleComponent title="Notifications | Swift Pay" />
      <Navbar storeName={vendor?.storeName} />
      <UserNotificationWrapper>
        <h5 className="header"> Notifications</h5>

        <div className="">
          {transactions &&
            transactions
              .slice(pageVisited, pageVisited + transactionsPerPage)
              .map(({ type, details, createdAt, id }, index) => (
                <UserNotification key={index}>
                  <p className={`${type && type.toLowerCase()} status`}></p>
                  <div>
                    <p className="details">{details}</p>
                    <Moment className="time" fromNow>
                      {createdAt}
                    </Moment>
                  </div>
                </UserNotification>
              ))}
        </div>
        <br />
        {transactions.length > transactionsPerPage ? <Pagination perPageCount={transactionsPerPage} getPropsOfPage={getPropsOfPage} data={transactions} />:""}
      </UserNotificationWrapper>
    </>
  );
};
export default NotificationPage;
