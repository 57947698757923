import styled from 'styled-components';

export const Container = styled.div`
`;


export const TransactionListWrapper = styled.div`
    display: grid;
    grid-template-columns: 3% 60% 30% 5%;
    column-gap: 0.5rem;
    justify-content: space-between;

    .flex-end{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    p{
        font-size:0.8rem;
    }
    h6{
        color: rgba(0,0,0,0.4);
        font-size:0.8rem;
    }
    .amount{
        padding-top:1.2rem;
    }

    @media screen and (min-width: 768px) {
        p{
            font-size: 1rem;
        }
        h6{
            font-size: 1rem;
        }
    }
`;

export const ColorGenerator = styled.div`
    background-color: ${(props) => props.color};
    width:0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-top: 0.5rem;

    @media screen and (min-width: 768px) {
        width:1rem;
        height: 1rem;
        margin-top: 0.8rem;
    }
`;
export const NoTransaction = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

    .img-2{
        height:3rem ;
    }
    p{
        font-size:0.8rem;
        color: rgba(0,0,0,0.5);
        text-align:center;
    }
    @media screen and (min-width: 768px) {
        p{
            font-size: 1.2rem;
        }
    }
`;