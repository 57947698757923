import React, { useState, useRef } from "react";
import { EyeIcon, EyeClosedIcon } from "@primer/octicons-react";
import {CustomInputContainer} from '../inputstyle';

const PasswordInput = React.forwardRef((props, ref) => {
  const [defaultType, setDefaultType] = useState("password");
  const {
    label,
    id,
    error: propsError,
    style,
    onChange,
    defaultValue,
    isRequired,
    placeholder,
    "data-name": dataName,
    name,
    onKeyUp,
  } = props;

  const togglePassword = () => {
    if (defaultType.toLowerCase() === "password") {
      setDefaultType("text");
    } else {
      setDefaultType("password");
    }
  };

  // Toggle the password visibility
  const typeIcon = (
    <div className="password-icon" onClick={togglePassword}>
      {defaultType.toLowerCase() === "password" ? (
        <EyeClosedIcon className="small-icon .mr-2, .p-1, .m-2" size="medium" />
      ) : (
        <EyeIcon className="small-icon .mr-2, .p-1, .m-2" size="medium" />
      )}
    </div>
  );

  return (
    <CustomInputContainer>
      <label htmlFor={id}> {label} </label>
      <div className={"input-group-password"}>
        <input
          ref={ref}
          id={id}
          className={"password " + (propsError ? "error" : "")}
          style={style}
          onChange={onChange}
          onKeyUp={onKeyUp}
          type={defaultType}
          defaultValue={defaultValue}
          required={isRequired}
          placeholder={placeholder || label}
          name={dataName || name}
          maxLength={props.size || 80}
        />
        {typeIcon}
      </div>
      {propsError && (
        <>
          <span className="text-danger">{propsError.message}</span> <br />
        </>
      )}
      <br />
    </CustomInputContainer>
  );
});

PasswordInput.defaultProps = {
  htmlFor: "app-password",
  id: "app-password",
  isRequired: true,
  defaultValue: "",
  style: {},
  error: "",
  onChange: () => {},
};

export default PasswordInput;
