// import queryString from 'querystring';
// import {toast} from 'react-toastify';
import environment from '../environment'

import {
  clearAllSessionExcept,
  // hasExpired,
  redirectAuth,
} from '../utils/helpers';
import { Cypher } from '../utils/index'
import { logout } from './auth.http';

let localStorage = {
  getItem: () => {},
  setItem: () => {},
};

if (typeof window !== 'undefined') {
  localStorage = window.localStorage;
}
const storage = {
  userDetails: 'dpUdToken',
  userSession: 'dpAuth',
};
export const getToken = () => {
  let token =
    localStorage.getItem(storage.userSession) ||
    sessionStorage.getItem(storage.userSession);
  return token;
};
export const setStorage = ({ signature, session }) => {
  try {
    localStorage.setItem(storage.userDetails, signature);
    localStorage.setItem(storage.userSession, session);
  } catch (e) {
  }
};
export const setSStorage = ({ signature, session }) => {
  sessionStorage?.setItem(storage.userDetails, signature);
  sessionStorage?.setItem(storage.userSession, session);
};
export default function AuthService(
  successObject = null,
  redirect = true,
  seconds = 1000
) {
  try {
    if (!successObject) return;
    const { token: session, ...data } = successObject;
    const signature = JSON.stringify(data);
    if (successObject.remember) {
      setStorage({ ...data, session, signature });
      setSStorage({ ...data, session, signature });
    } else {
      setStorage({ ...data, session, signature });
      setSStorage({ ...data, session, signature });
    }

    if (redirect) {
      const redirectUrl =
        typeof redirect === 'string' ? redirect : '/dashboard';
      if (seconds) {
        return setTimeout(() => {
          redirectAuth(redirectUrl);
        }, seconds);
      } else {
        redirectAuth(redirectUrl);
      }
    }
  } catch (e) {
  }
}






export const verifyAuth = (getUser = null) => {
  try {
    const user =
      localStorage.getItem(storage.userDetails) ||
      sessionStorage?.getItem(storage.userDetails);
    const userSession =
      localStorage.getItem(storage.userSession) ||
      sessionStorage?.getItem(storage.userSession);
    if (!user || !userSession) {
      window?.location?.replace('/login');
      throw Error('Not logged in');
    }
    const resp = JSON.parse(user);
    if (!resp.id) {
      throw Error('Data not encrypted');
    }
    return { session: userSession, signature: user, ...resp };
  } catch (e) {
    if (getUser && typeof getUser === 'string') {
      //
    }
    return false;
  }
};
const { aesKey, ivKey } = environment.merchantKey;
const testCypher = new Cypher(aesKey, ivKey);
export const authState = ({ session, signature }) => {
  try {
    const resp = JSON.parse(testCypher.decrypt(session, signature)); //aes
    return resp;
  } catch (e) {
    verifyAuth();
    return false;
  }
};

export const Logout = async () => {
  await logout();
  clearAllSessionExcept('localStorage');
  return setTimeout(() => {
    redirectAuth('/login');
  }, 500);
};

export const getUserInfo = (userId = null) => {
  if (!userId) return;
};

export const initOtp = (field, value, type, redirect = true) => {
  let typeEndPoint = '/auth/verify/otp';
  if (type === 'NEW_ACCOUNT') {
    typeEndPoint = '/account/phone-bvn/verify';
  } else if (['FORGOT-PASSWORD', 'confirm-password-reset'].includes(type)) {
    typeEndPoint += '/pass';
  }
  localStorage.setItem('typeEndPoint', typeEndPoint);
  localStorage.setItem(field, value);
  localStorage.setItem('field', field);
  if (redirect) {
    redirectAuth('/otp/' + type.toLowerCase(), value);
  }
};
