import "./App.css";
import GlobalStyles from "./GlobalStyles";
import Route from "./Route";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
    <div className="App">
      <GlobalStyles />
     
      <Route/>
      
    </div>
    <ToastContainer 
      hideProgressBar
      limit={1}
    />
    </>
  );
}

export default App;
