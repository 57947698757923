import styled from 'styled-components';

export const Container = styled.div`
    background-color: white;
    width: 100%;
    padding: 0.5rem 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 8rem;
    row-gap: 1rem;
    
    font-family: "Effra" !important;

    .total-transaction{
        font-size: 0.8rem;
        color: #030f4f;

        @media screen and (min-width: 850px) {
        font-size: 0.6rem;
        }
        @media screen and (min-width: 1420px) {
        font-size: 1rem;
        }
    }
    .details-currency{
        font-size: 1rem;
        font-weight: bold;
        color: #030f4f;
        /* @media screen and (min-width: 768px) {
        font-size: 1rem;
        } */
    }
    .details-amount{
        font-size: 1rem;
        font-weight: bold;
        /* @media screen and (min-width: 768px) {
        font-size: 1rem;
        } */
    }
`;