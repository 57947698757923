import React from "react";

const style = {
  color: "red",
  // padding: "1rem 0",
  // paddingTop: "1rem",
  marginTop: "-1rem",
  marginBottom: "1rem",
};

const Error = ({ children }) => {
  return <div style={style}>{children}</div>;
};

export default Error;
