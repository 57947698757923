import {
  callEnrollmentV2,
  generateV2Token,
  validateV2,
} from "./paymentservice";
import Cardinal from "./cardinal";
import { decryptPayload, redirect } from "../../utils/helpers";
import { generateV2Payload } from "./generateinlinepayload";

export const initCardinal = async ({ currency, amount }) => {
  try {
    const generateJWT = await generateV2Token({
      currency,
      amount,
    });
    if (generateJWT.status === 200) {
      const decrypt = decryptPayload(generateJWT.data.data);
      const { token, ReferenceId, fingerPrint, SessionId } = decrypt.data;
      window?.CardinalUtil?.initFingerPrint(fingerPrint);
      Cardinal.setup("init", {
        jwt: token,
      });
      return { ReferenceId, SessionId };
    }
  } catch (error) {
    return {
      error,
    };
  }
};

export const sendToCardinal = async (request, config) => {
  const { spTransactionId, vendor } = request;
  const payload = generateV2Payload(request);
  const { setIsLoading, errorAlert, successAlert, back, channel } = config;
  const {
    amount,
    currency,
    ReferenceId,
    number,
    expirationMonth,
    expirationYear,
    cvv,
  } = payload;

  const order = {
    OrderDetails: {
      Amount: amount,
      CurrencyCode: currency || "NGN",
    },
    Consumer: {
      DFReferenceId: ReferenceId,
      Account: {
        AccountNumber: number,
        ExpirationMonth: expirationMonth,
        ExpirationYear: expirationYear,
        CardCode: cvv,
      },
    },
  };
  let enroll = null;
  const redirectPath = `/${vendor?.defaultUrl}/receipt?transaction=${spTransactionId}`;

  Cardinal.on("payments.setupComplete", async () => {
    try {
      await Cardinal.trigger("bin.process", number);
      const decrypt = await callEnrollmentV2(payload);

      const enrollment = decryptPayload(decrypt?.data?.data)?.data;

      const consumerAuthentication =
        enrollment.consumerAuthenticationInformation;
      const TransactionId = consumerAuthentication?.authenticationTransactionId;
      if (decrypt.status === 200) {
        enroll = {
          TransactionId,
          status: enrollment.status,
        };
        if (enrollment.status === "AUTHENTICATION_SUCCESSFUL") {
          await validateAndAuthorize({ ...payload, enrollment: enroll });
        } else if (
          enrollment?.errorInformation?.reason !==
          "CONSUMER_AUTHENTICATION_REQUIRED"
        ) {
          errorAlert(enrollment?.errorInformation.reason);
          setIsLoading(false);
          back();
        } else {
          Cardinal.continue(
            "cca",
            {
              ...payload,
              AcsUrl: consumerAuthentication?.acsUrl,
              Payload: consumerAuthentication?.pareq,
            },
            {
              ...order,
              OrderDetails: {
                ...order.OrderDetails,
                TransactionId,
              },
            }
          );
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response) {
        errorAlert(
          "Payment failed. Please confirm card details or try another card."
        );
      } else {
        errorAlert("Payment failed.");
      }
      return back();
    }
  });

  Cardinal.on("payments.validated", async (responseData, jwt) => {
    if (responseData && typeof responseData === "object") {
      const action = responseData.ActionCode || responseData.ErrorNumber;
      if (action || action === 0) {
        switch (action) {
          case 0:
          case "SUCCESS":
          case "NOACTION":
            await validateAndAuthorize({ ...payload, enrollment: enroll });
            break;
          case "FAILURE":
          case "ERROR":
            setIsLoading(false);
            errorAlert("Payment failed..");
            back();
            break;
          default:
            setIsLoading(false);
        }
      }
    }
  });

  async function validateAndAuthorize(payload) {
    try {
      const decrypt = await validateV2({ ...payload, channel });
      if (decrypt.status === 200) {
        if (channel === "adhoc") {
          const alt = decryptPayload(decrypt?.data?.data)?.data;

          const { redirectLink } = alt?.updated?.alternatePayment;
          if (redirectLink) {
            window.location.href = redirectLink;
          }
        } else {
          redirect(redirectPath);
        }
        setIsLoading(false);
        successAlert("Payment successful..");
      }
    } catch (error) {
      errorAlert("Could not Authorize payment...");
      setIsLoading(false);
      back();
    }
  }
};
