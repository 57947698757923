import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  IsShowingWrapper,
  ImagePreviewWrapper,
  TitleWrapper,
  TextWrapper
} from "./ProductCard.styles";
import defaultImage from "../../assets/default-placeholder-image.png";
import environment from "../../environment";

function ProductCard({
  image,
  thumbnail,
  id,
  name,
  description,
  code,
  price,
  currency,
  ...props
}) {
    
    
  
  const img =
    image || thumbnail
      ? environment.BACKEND_URL + "/utils/image/" + image
      : defaultImage;
  return (
    <>
        <Container onClick={props.onClick}>
          <IsShowingWrapper>
            <ImagePreviewWrapper>
              <img src={img} alt="ProductImage" />
            </ImagePreviewWrapper>
            <TitleWrapper>
            <TextWrapper ><h5> Name: </h5><h5>{name}</h5></TextWrapper>
              
            <TextWrapper> <h5>Price: </h5> <h5> {currency} {price}</h5></TextWrapper>
            <TextWrapper>Code: {code}</TextWrapper>
            </TitleWrapper>
          </IsShowingWrapper>
        </Container>
    </>
  );
}

export default ProductCard;
