import styled from 'styled-components';

export const Container = styled.div`
    margin: 0 auto;
    max-width: 600px;
label .optional{
    /* border: 2px solid red; */
    padding-left: 0.2rem;
    font-size: 1rem;
    opacity: 0.8;
    
}
`;
export const HeaderWrapper = styled.div`
    width: 100%;
    padding: 2rem;
    h3{
        text-align: center;
    }
    .back-arrow{
        cursor: pointer;
    }
    @media screen and (min-width: 768px) {
        font-size: 2rem;
    }
    
`;
export const Textwrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 0;
    @media screen and (min-width: 768px) {
        font-size: 2rem;
    }
`;
export const BodyWrapper = styled.div`
    width: 100%;
    text-align: center;
    padding: 1rem 2rem;

    label{
        text-align: left;
        margin-bottom: .5rem;
  display: flex;
  align-items: baseline;
    }
`;
export const InputFieldWrapper = styled.div`
    padding-top: 2rem;


`;
export const FooterWrapper = styled.div`
    padding:1rem 2rem;
    display: flex;
    justify-content: center;
    text-align: center;
`;
export const TextArea = styled.textarea`
width: 100%;
  padding: 0.8rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
  background-color: #f1f1f1;
  border: 1px solid #b3b3b3;
  color: #495057;
  text-align: left;
 
`;
export const SelectTextWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;
export const InputTitleWrapper = styled.div`
font-size: 1em;
  width: 100%;
  text-align: left;
  margin-bottom: .5rem;
  display: flex;
  align-items: baseline;
`;
export const FormSelect = styled.select`
    width: 100%;
  padding: 0.8rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
  background-color: #f1f1f1;
  border: 1px solid #b3b3b3;
  color: #495057;

  
  /* margin:1.5rem 0 ; */
`;

export const CvvExpDateWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 1rem;
`;
