import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import CustomButton from "../CustomButton";
import {
  Container,
  BodyWrapper,
  ImageWrapper,
  DescriptionWrapper,
  ButtonWrapper,
} from "./SingleProduct.styles";

import { UserContext } from "../../store/userStore/user.context";
import { ProductsContext } from "../../store/productStore/product.context";
function SingleProductpage({ items, img, productUrl, fullUrl, vendor }) {
  const user = useContext(UserContext);
  const state = useContext(ProductsContext);
  const { push } = useHistory();

  function editProduct() {
    push({
      pathname: "/edit-product",
      search: `?pid=${items.code}`,
      state: { detail: { product: items, img: img } },
    });
  }

  function checkOutProduct() {
    push({
      pathname: `/${
        state?.storeName || user?.defaultUrl || vendor?.defaultUrl
      }/pay`,
      search: `?pid=${items.code}`,
      state: {
        detail: { product: items, img: vendor?.logo || img, vendor: user || vendor },
      },
    });
  }

  return (
    <Container>
      <BodyWrapper>
        <ImageWrapper>
          <img src={img} alt="ProductImage" />
        </ImageWrapper>
        <DescriptionWrapper>
          <p>
            <span className="title">Name: </span>
            <span className="title-content">{items.name}</span>
          </p>
          <p>
            <span className="title">Price: </span>
            <span className="title-content">
              {items.currency} {items.price}
            </span>{" "}
          </p>
          <p>
            <span className="title">Quantity: </span>{" "}
            <span className="title-content">{items.qty}</span>
          </p>
          <p>
            <span className="title">Code: </span>
            <span className="title-content">{items.code}</span>
          </p>
          
        </DescriptionWrapper>
      </BodyWrapper>
      <DescriptionWrapper>
      <p>
            <span className="title">Description: </span>
            <span className="title-contents">{items.description}</span>
          </p>
      </DescriptionWrapper>
      {user?.defaultUrl ? (
        <>
          <DescriptionWrapper>
            <p>
              <span className="title">Link: </span>{" "}
              {productUrl ? (
                <span className="style-link" onClick={checkOutProduct}>
                  {fullUrl}
                </span>
              ) : (
                ""
              )}
            </p>
          </DescriptionWrapper>
          <ButtonWrapper>
            <CustomButton
              shape=""
              width="100%"
              onClick={editProduct}
              style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Edit Product
            </CustomButton>
          </ButtonWrapper>
        </>
      ) : (
        <ButtonWrapper>
          {productUrl ? (
            <CustomButton
              shape=""
              width="100%"
              onClick={checkOutProduct}
              style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Buy Product
            </CustomButton>
          ) : (
            ""
          )}
        </ButtonWrapper>
      )}
    </Container>
  );
}

export default SingleProductpage;
