import React from "react";
import { Container } from "./Report.styles";
import { useHistory } from "react-router-dom";
import CustomButton from "../CustomButton";

function Reports() {
  let history = useHistory();
  return (
    <Container>
      <p>Reports</p>
      <div className="buttons">
        
        <CustomButton
                shape=""
                width="100%"
                onClick={()=> history.push("/dashboard/transactions/daily")}
                style={{
                  padding: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span>Daily</span>
        </CustomButton>
        
        <CustomButton
                shape=""
                width="100%"
                onClick={() => history.push("/dashboard/transactions/monthly/")}
                style={{
                  padding: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span>Monthly</span>
        </CustomButton>
      </div>
    </Container>
  );
}

export default Reports;
