import React, { useState, useContext, useEffect } from "react";

import { BiArrowBack } from "react-icons/bi";
import { useParams, useLocation, useHistory } from "react-router-dom";

import { redirect } from "../../utils/helpers";
import environment from "../../environment";

import defaultPlaceholder from "../../assets/default-placeholder-image.png";
import { UserContext } from "../../store/userStore/user.context";
import CustomButton from "../CustomButton";
import AccessImage from "../../assets/img/access.png";
import {
  Container,
  BackArrow,
  TitleWrapper,
  ProductDetailWrapper,
  ImageWrapper,
  BodyWrapper,
  ContentWrapper,
  TextWrapper,
  FooterWrapper,
  ProductDetailHeader,
  ImageDetailWrapper,
  AvailableStockWrapper,
  BlueShadeWrapper,
  CheckoutButtons,
  ProductDetailDiv,
  TextTitle,
  AmountText,
  Underline,
  ColoredClick,
  ColoredClickWrapper,
} from "./OrderProduct";

import { getServerSidePropsProduct } from "./GetStoreDetail";
import PageNotFound from "../../pages/404Page";

function OrderProductCard({ ...props }) {
  const [productDetail, setProductdetail] = useState({
    product: "",
    vendor: "",
    img: "",
  });
  const [error, setError] = useState(false);

  const location = useLocation();
  const { id } = useParams();
  const query = {
    pid: location.search.slice(5),
    store: id,
  };

  useEffect(() => {
    if (query.pid !== undefined || query.pid !== null) {
      getServerSidePropsProduct(query)
        .then((res) =>
          setProductdetail({
            product: res.product,
            vendor: res.vendor,
            img: res.vendor.logo,
          })
        )
        .catch((e) => setError(!error, e.redirect));
    }
  }, [query.pid, query.store]);



  const test = props.location?.state?.detail || productDetail;
  const searchParams = props.location?.search;

  // let match = useRouteMatch()

  const user = useContext(UserContext);
  const { product, img, vendor } = test;
  
  const [quantity, setQuantity] = useState(1);

  function backToProducts() {
    if (user) {
      redirect("/dashboard/products");
    } else {
      redirect(`/${test?.vendor?.defaultUrl || user?.defaultUrl}`)
    }
  }
 
  const image =
    img || product.thumbnail
      ? environment.BACKEND_URL + "/utils/image/" + product.image
      : defaultPlaceholder;

  const initialQuantity = quantity;
  const updateQuantity = (add = true) => {
    const upDatedQuantity =
      add && product.qty > initialQuantity
        ? initialQuantity + 1
        : add === false && quantity > 1
        ? initialQuantity - 1
        : quantity;
    setQuantity(upDatedQuantity);
    return upDatedQuantity;
  };

  let localStorage = {
    getItem: () => {},
    setItem: () => {},
  };

  let totalPrice = quantity * Number(product.price || 0);

  if (typeof window !== "undefined") {
    localStorage = window.localStorage;
    localStorage.setItem("qty", quantity);
    // localStorage.setItem("orderAmount", totalPrice);
  }

  const { push } = useHistory();
  const redirectToPayment = () => {
    push({
      pathname: `/${user?.defaultUrl || productDetail?.vendor?.defaultUrl}/checkout`,
      search: `${searchParams}`,
      state: { detail: { product: {...product, amount: totalPrice}, vendor: productDetail?.vendor, img, totalAmount: totalPrice, quantity, goto: 'product' } },
    });
  };
  return (
    <>
      {error ? (
        <PageNotFound />
      ) : (
        <Container>
          <BodyWrapper>
              <BackArrow onClick={backToProducts}>
                <BiArrowBack className="icon" />
              </BackArrow>
            <TitleWrapper>{user?.storeName || vendor?.storeName}</TitleWrapper>

            <ProductDetailWrapper>
              <ProductDetailHeader>
                <TextTitle>{product.name || "No product name"}</TextTitle>
                <AvailableStockWrapper>
                  <div className="available-stock">Available Stock</div>
                  <BlueShadeWrapper>{(product.qty) - quantity}</BlueShadeWrapper>{" "}
                </AvailableStockWrapper>{" "}
              </ProductDetailHeader>
              <Underline />
              <ProductDetailDiv>
                <ImageDetailWrapper>
                  <ImageWrapper>
                    <img src={image} alt="" />
                  </ImageWrapper>
                </ImageDetailWrapper>
                <ContentWrapper>
                  <AmountText>
                    {product.currency}{" "}
                    {Number(product.price || 0).toLocaleString()}
                  </AmountText>
                  <TextWrapper>{product.description}</TextWrapper>{" "}
                  <TextWrapper>{product.code || "No product name"}</TextWrapper>
                </ContentWrapper>
              </ProductDetailDiv>
              <Underline />
              <CheckoutButtons>
                <AmountText style={{ fontSize: "1.2rem" }}>Quantity</AmountText>{" "}
                <ColoredClickWrapper>
                  <ColoredClick
                    onClick={() => updateQuantity(false)}
                    style={{
                      backgroundColor:
                        quantity === 1
                          ? "rgba(245, 130, 30, 0.5)"
                          : "rgba(245, 130, 30, 1)",
                    }}
                  >
                    {" "}
                    -{" "}
                  </ColoredClick>{" "}
                  {quantity}
                  <ColoredClick
                    onClick={() => updateQuantity(true)}
                    style={{
                      backgroundColor:
                        quantity === product.qty
                          ? "rgba(245, 130, 30, 0.5)"
                          : "rgba(245, 130, 30, 1)",
                    }}
                  >
                    +
                  </ColoredClick>
                </ColoredClickWrapper>
              </CheckoutButtons>
              <CheckoutButtons>
                <AmountText style={{ fontSize: "1.2rem" }}>
                  Total To Pay
                </AmountText>{" "}
                <AmountText style={{ fontSize: "1.2rem" }}>
                  {product.currency} {totalPrice}
                </AmountText>
              </CheckoutButtons>
              <Underline />

              <CustomButton
                shape=""
                width="100%"
                onClick={redirectToPayment}
                style={{
                  padding: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  marginTop:"2rem"
                }}
                disabled={quantity < 1}
              >
                <span>Proceed to Payment</span>
              </CustomButton>
              <FooterWrapper>
                <span>
                  <img
                    src={AccessImage}
                    style={{ width: "40px", height: "40px" }}
                    alt="access logo"
                  />
                  <i>Verified by Access</i>
                </span>
              </FooterWrapper>
            </ProductDetailWrapper>
          </BodyWrapper>
        </Container>
      )}
    </>
  );
}

export default OrderProductCard;
