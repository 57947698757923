import React, { useEffect, useState, useContext, memo } from "react";
import {  useParams,useRouteMatch, useHistory} from "react-router-dom";
import InputField from "../../components/Input";
import CustomButton from "../../components/CustomButton";
import { BiArrowBack } from "react-icons/bi";
import {
  ProductsContainer,
  SearchInputWrapper,
  ButtonWrapper,
  ProductsWrapper,
  BodyWrapper,
  SingleProductWrapper,
  AlignButtonCenter
} from "./Product.styles";
import { BiPlus } from "react-icons/bi";
import Header from "../../components/dashboardHeader";
import { searchArrayOfObject } from "../../utils/helpers";
import { UserContext } from "../../store/userStore/user.context";
import { ProductsContext, DispatchContext } from "../../store/productStore/product.context";
import useAxios from "../../components/customHook/useAxios";
import environment from "../../environment";

import defaultImage from "../../assets/default-placeholder-image.png";
import ProductCard from "../../components/ProductCard";
import SingleProductpage from "../../components/SingleProduct";
import { Helmet } from 'react-helmet';
import Pagination from "../../components/Pagination";

const CustomerProductPage = ({ ...props }) => {
  const vendor = props.location?.state?.vendor
  const user = useContext(UserContext)
  const state = useContext(ProductsContext);
  const dispatch = useContext(DispatchContext)
  let { id } = useParams();
  let match = useRouteMatch()
  const { push } = useHistory();

  


  const [getTransaction, { data, error }, isLoading] = useAxios("get");
  const [getData, setData] = useState("data || []");
  const [products, setProducts] = useState(["reload"]);
  const [singleProduct, setSingleProduct] = useState(null);
  const [showCard, setShowCard] = useState(true);

  const pagePerCount = 10;
  const [pageVisited, setPageVisited] = useState(0);

  const search = (event) => {
    dispatch({type: "FETCH",storeName:id, products:searchArrayOfObject(event.target.value, data)})
  };

  useEffect(() => {
    if (
      (id || user?.defaultUrl || vendor?.defaultUrl) &&
      !isLoading &&
      !Array.isArray(data) &&
      !error &&
      getData &&
      products[0] &&
      products[0] === "reload"
    ) {
      getTransaction("/product/all/" + (id || user?.defaultUrl || vendor?.defaultUrl));
      setData(null);
    }
  }, [data, error, getData, getTransaction, id, isLoading, products, user?.defaultUrl, vendor?.defaultUrl]);



  if (
    (id || user?.defaultUrl || vendor?.defaultUrl) &&
    !isLoading &&
    data &&
    !error &&
    !getData &&
    products[0] === "reload"
  ) {
    setProducts(data);
    dispatch({type: "FETCH",storeName:id, products:data})
    setData(null);
  }



  const productUrl =
    id + "/"+
    "pay?pid=" +
    singleProduct?.code;
  
    const fullUrl =
    environment.BACKEND_URL +
    ((user?.userName) || match.url || vendor?.userName) +
    "/" +
    "pay?pid=" +
    singleProduct?.code;
    
  const img =
    singleProduct && singleProduct.image
      ? environment.BACKEND_URL + "/utils/image/" + (singleProduct.image || vendor?.logo)
      : defaultImage;
  
      function createProduct() {
        push({
          pathname: "/dashboard/create-product",
        });
      }
      const getPropsOfPage = (value) => {
        setPageVisited(value);
      };
  return (
    <>
      <Helmet>
      <title>Customer Product</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      { (id || user || vendor) && (
        <ProductsContainer>
          <Header storeName={user?.storeName || vendor?.storeName}/>
          <BodyWrapper>
          {
              showCard ? (
                <div>
            <SearchInputWrapper>
              <InputField
                inputTitle="Products"
                placeholder={user? "Search Products": "Filter Products"}
                search={search}
                onChange={search}
              />
                  </SearchInputWrapper>
                  {
                    user && <ButtonWrapper>
              <CustomButton
                shape=""
                width="100%"
                style={{
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={createProduct}
                      ><AlignButtonCenter>
                           <BiPlus />
                  Add Product
              </AlignButtonCenter>
                 
              </CustomButton>
            </ButtonWrapper>
                  }
            
            </div>
              ):("")
            }
            {showCard ? (
              <>
              <ProductsWrapper>
                {state?.products &&
                  state?.products[0] !== "reload" &&(
                  <>
                    {state?.products.length < pagePerCount
                      ? state?.products.map((item) => (
                          <ProductCard
                            {...item}
                            onClick={() => {
                              setShowCard(!showCard);
                              setSingleProduct(item);
                              localStorage?.setItem(
                                "product",
                                JSON.stringify(item)
                              );
                            }}
                            key={item.id}
                          />
                        ))
                      : state?.products
                          .slice(pageVisited, pageVisited + pagePerCount)
                          .map((item) => (
                            <ProductCard
                              {...item}
                              onClick={() => {
                                setShowCard(!showCard);
                                setSingleProduct(item);
                                localStorage?.setItem(
                                  "product",
                                  JSON.stringify(item)
                                );
                              }}
                              key={item.id}
                            />
                          ))}
                  </>)}
                </ProductsWrapper>
                {products.length > pagePerCount ? <Pagination perPageCount={pagePerCount} getPropsOfPage={getPropsOfPage} data={products} />:""}
                </>
            ) : (
              <SingleProductWrapper>
                <div
                  className="back-arrow"
                >
                  <BiArrowBack className="icon" onClick={() => setShowCard(!showCard)}/>
                </div>
                {singleProduct && (
                  <SingleProductpage
                    items={singleProduct}
                    img={img}
                    productUrl={productUrl}
                      fullUrl={fullUrl}
                      vendor={vendor}
                  />
                )}
              </SingleProductWrapper>
            )}
          </BodyWrapper>
        </ProductsContainer>
      )}
    </>
  );
};

export default memo(CustomerProductPage);
