import React, { useState } from "react";
import ReactPaginate from "react-paginate";


import {Container} from './pagination.style'


const Pagination = ({ perPageCount, data, getPropsOfPage }) => {
  const [pageNumber, setPageNumber] = useState(0);
   const pagesVisted = pageNumber * perPageCount;
    const pageCount = Math.ceil(data?.length / perPageCount);
    
    

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };
    
  getPropsOfPage(pagesVisted)
    
    return (<Container>
      <ReactPaginate
          previousLabel="Prev"
          nextLabel="Next"
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          marginPagesDisplayed={0}
          pageRangeDisplayed={1}
        />
  </Container>);
};

export default Pagination;
