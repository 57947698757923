// react libraries
import React from 'react';

// third party libraries
import ReactDatePicker from 'react-datepicker';



// styles
import 'react-datepicker/dist/react-datepicker.css';
import './Datepicker.styles.css';

const DatePicker = ({
  name, onChange, date, minDate, placeholder, maxDate
}) => {
  const handleChange = dateInfo => {
    onChange(name, dateInfo);
  };

  return (
      <ReactDatePicker
        dateFormat='d MMMM yyyy'
        selected={date ? new Date(date) : ''}
        onChange={handleChange}
        minDate={new Date(minDate) || new Date()}
        maxDate={new Date(maxDate) || new Date()}
        placeholderText={placeholder}
        popperPlacement='top-end'
        wrapperClassName='datePicker'
      
        popperModifiers={{
          offset: {
            enabled: true,
            offset: '5px, 10px',
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: 'viewport',
          },
        }}
      />
  );
};

export default DatePicker;
