/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet';
import { GrClose } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import defaultPlaceholder from '../../assets/default-placeholder-image.png';
import CustomButton from '../../components/CustomButton';
import useAxios from '../../components/customHook/useAxios';
import InputField from '../../components/Input';
import ShareSocialMedia from '../../components/Share';
import environment from '../../environment';
import { getTransactionReceipt } from '../../services/auth.http';
// import { encryptAccount, searchArrayOfObject } from "../../utils/helpers";
import { redirect } from '../../utils/helpers';
import {
  BodyWrapper,
  ButtonWrapper,
  ColumnWrapper,
  Container,
  ContentWrapper,
  EmailShareWrapper,
  ImageWrapper,
  ShareDetailWrapper,
  SocialMediaShareWrapper,
  StoreName,
  TextWrapper,
  TitleWrapper,
  Underline
} from './ReceiptPage.styles';

function ReceiptPage({ ...props }) {
  let PaymentDetail = props?.location?.state?.detail;

  const [sharePage, showSharePage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [email, setEmail] = useState(null);
  const [invalidPage, setInvalidPage] = useState(false);
  const [loginUser, isLoadings] = useAxios('post');
  const [getTransaction, { data, error }, isLoading] = useAxios('get');
  const [transactions, setTransaction] = useState(['reload']);
  const [loading, setLoading] = useState(false);

  function handleLogin() {
    if (!isLoadings) {
      showSharePage(false);
      setSuccessMessage(`Receipt sent to this email ${email}`);

      loginUser('/utils/send/email', {
        email,
        transactionId: PaymentDetail.product.transactionId,
      });
    }
  }

  const [receipt, setReceipt] = useState({});
  const { id } = props.match.params;
  const search = props.location.search;
  const [, transactionId] = search.split('=');

  useEffect(() => {
    if (invalidPage) {
      redirect('/404');
    }
    if (!id || !transactionId) {
      setInvalidPage(true);
    }
  }, [invalidPage]);

  useEffect(async () => {
    if (!Object.entries(receipt).length && !loading) {
      setLoading(true);
      try {
        const rec = await getTransactionReceipt({
          transactionId,
          vendorUserName: id,
        });
        if (rec.status === 200) {
          setReceipt(rec?.data?.data);
        }
        setLoading(false);
      } catch (error) {
        setInvalidPage(true);
      }
    }
  }, [receipt, loading]);

  let localStorage = {
    getItem: () => {},
    setItem: () => {},
  };
  if (typeof window !== 'undefined') {
    localStorage = window.localStorage;
  }

  const quantity = JSON.parse(localStorage?.getItem('qty'));
  // const totalAmount = JSON.parse(localStorage?.getItem("orderAmount"));
  const productDetail = JSON.parse(localStorage?.getItem('product'));

  const type =
    {
      success: 'SUCCESSFUL',
      successful: 'SUCCESSFUL',
      authorized: 'SUCCESSFUL',
      failed: 'danger',
      pending: 'theme',
    }[receipt?.status?.toLowerCase()] || 'theme';

  

  const pageUrl =
    environment.appUrl +
    '/' +
    receipt?.userName +
    '/receipt?transaction=' +
    receipt?.transactionRef;

  const redirectToProducts = () => {
    redirect(`/${receipt?.userName}/store`);
  };

  const logo = receipt?.logo
    ? environment.BACKEND_URL + '/utils/image/' + receipt.logo
    : defaultPlaceholder;

  let searchParams = `?pid=${receipt?.productCode}`;


  return loading ? (
    <Spinner animation="border" />
  ) : (
    <Container>
      <Helmet>
        <title>Receipt Swift pay</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <StoreName>
        <TitleWrapper>Transaction receipt</TitleWrapper>
        <ImageWrapper>
          <img src={logo} alt="vendorImage" />
        </ImageWrapper>
        <TitleWrapper>{receipt?.storeName}</TitleWrapper>
      </StoreName>
      <BodyWrapper>
        <ColumnWrapper>
          <TextWrapper>Transaction Status</TextWrapper>
          <ContentWrapper color={type === 'SUCCESSFUL' ? 'green' : 'red'}>
            {receipt?.status}
          </ContentWrapper>
        </ColumnWrapper>
        <Underline />
        <ColumnWrapper>
          <TextWrapper>Date</TextWrapper>
          <ContentWrapper>
            {new Date(receipt?.createdAt).toLocaleString('en-GB')}
          </ContentWrapper>
        </ColumnWrapper>
        <Underline />
        <ColumnWrapper>
          <TextWrapper>Total Amount</TextWrapper>
          <ContentWrapper>
            {receipt?.currency} {receipt?.totalAmount}
          </ContentWrapper>
        </ColumnWrapper>
        <Underline />
        <ColumnWrapper>
          <TextWrapper>Payment Type:</TextWrapper>
          <ContentWrapper>{receipt?.paymentType}</ContentWrapper>
        </ColumnWrapper>
        <Underline />

        <ColumnWrapper>
          <TextWrapper>Transaction Reference</TextWrapper>
          <ContentWrapper>{receipt?.transactionRef}</ContentWrapper>
        </ColumnWrapper>
        <Underline />

        <ColumnWrapper>
          <TextWrapper>Payment made by:</TextWrapper>
          <ContentWrapper>{receipt?.paymentFrom}</ContentWrapper>
        </ColumnWrapper>
        <Underline />

        <ColumnWrapper>
          <TextWrapper>Narration</TextWrapper>
          <ContentWrapper>{receipt?.narration}</ContentWrapper>
        </ColumnWrapper>
        <Underline />

        {receipt?.quantity ? (
          <>
            <ColumnWrapper>
              <TextWrapper>Product Name</TextWrapper>
              <ContentWrapper>{receipt?.productName}</ContentWrapper>
            </ColumnWrapper>
            <Underline />

            <ColumnWrapper>
              <TextWrapper>Product Code</TextWrapper>
              <ContentWrapper>{receipt?.productCode}</ContentWrapper>
            </ColumnWrapper>
            <Underline />

            <ColumnWrapper>
              <TextWrapper>Quantity</TextWrapper>
              <ContentWrapper>{receipt?.quantity}</ContentWrapper>
            </ColumnWrapper>
            <Underline />
          </>
        ) : (
          <>
            <ColumnWrapper>
              <TextWrapper>Transaction Type</TextWrapper>
              <ContentWrapper>{'Send-Money'}</ContentWrapper>
            </ColumnWrapper>
            <Underline />
          </>
        )}

        <div className="w-25 p-2 mx-4 text-success">{successMessage}</div>
        {sharePage ? (
          <ShareDetailWrapper>
            <EmailShareWrapper>
              <InputField
                name="email"
                inputTitle={'Email Address to send to'}
                placeholder="eg. John Doe.inc@gmail.com"
                onKeyUp={({ target }) => setEmail(target.value)}
                onChange={({ target }) => setEmail(target.value)}
              />

              <CustomButton
                onClick={handleLogin}
                shape=""
                width="100%"
                background="green"
                disabled={isLoading}
                style={{ padding: '1rem', marginTop: '0.85rem' }}
              >
                {'Send Receipt'}
              </CustomButton>
            </EmailShareWrapper>
            <SocialMediaShareWrapper>
              <div
                className="back-arrow"
                onClick={() => showSharePage(!sharePage)}
              >
                <GrClose />{' '}
              </div>
              <ShareSocialMedia
                url={pageUrl}
                text={`Hello ${receipt?.userName}. I just paid ${receipt?.currency}{" "}
            ${receipt?.totalAmount} for `}
              />
            </SocialMediaShareWrapper>
          </ShareDetailWrapper>
        ) : (
          <CustomButton
            onClick={() => showSharePage(!sharePage)}
            shape=""
            width="100%"
            background="green"
            style={{ padding: '1rem', marginTop: '0.85rem' }}
          >
            Share Receipt
          </CustomButton>
        )}
        <ButtonWrapper>
          {receipt?.quantity ? (
            <CustomButton
              onClick={() => {}}
              shape=""
              width="100%"
              disabled={isLoading}
              style={{ padding: '1rem', marginTop: '0.85rem' }}
            >
              <Link
                to={{
                  pathname: `/${receipt?.userName}/product`,
                  search: `${searchParams}`,
                  state: {
                    detail: {
                      product: productDetail,
                      transactions: transactions,
                      qty: quantity,
                      orderAmount: receipt?.totalAmount,
                      pageUrl: pageUrl,
                      paymentFrom: receipt?.paymentFrom,
                    },
                  },
                }}
                style={{
                  width: '100%',
                  height: '100%',
                  textDecoration: 'none',
                  color: 'white',
                }}
              >
                View Product (1)
              </Link>
            </CustomButton>
          ) : (
            <CustomButton
              onClick={() => {}}
              shape=""
              width="100%"
              disabled={isLoading}
              style={{ padding: '1rem', marginTop: '0.85rem' }}
            >
              <Link
                to={{
                  pathname: `/@${receipt?.userName}`,
                }}
              >
                Send Money
              </Link>
            </CustomButton>
          )}
          <CustomButton
            onClick={redirectToProducts}
            shape=""
            width="100%"
            disabled={isLoading}
            style={{ padding: '1rem', marginTop: '0.85rem' }}
          >
            Go to Store
          </CustomButton>
        </ButtonWrapper>
      </BodyWrapper>
    </Container>
  );
}

export default ReceiptPage;
