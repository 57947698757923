import React from "react";


const Page = () => {
  
  return (
    <div style={{fontSize: "30px", textAlign:"center"}}>
        Payment Method in Progress. 
    </div>
  );
};
export default Page;
