import React from "react";
import TitleComponent from "../../components/TitleComponent";
import AccessImage from "../../assets/img/access.png";
import { LogoHeader } from "../../components/MobileLayout";
import {
  Container,
  BodyWrapper,
  FooterWrapper
} from "../../components/SendMoneyPage/SendMoney.styles";

function Success() {
  return (
    <Container>
      <TitleComponent title="Pay with Account Debit Success" />
      <br />
      <LogoHeader />
      <BodyWrapper>
        <div>Pay with Direct Debit Success message</div>
        <div
          style={{
            textAlign: "center",
            color: "green",
            padding: "1.875rem",
            fontSize: "1.875rem",
          }}
        >
          Transaction Successful!!!
        </div>
      </BodyWrapper>
      <FooterWrapper>
        <span style={{ marginTop: "1.875rem" }}>
          <img
            src={AccessImage}
            style={{ width: "2.5rem", height: "2.5rem" }}
            alt="access logo"
          />
          <i>Verified by Access</i>
        </span>
      </FooterWrapper>
    </Container>
  );
}
export default Success;
