import styled from "styled-components";

export const PaymentContainer = styled.div`
  width: 100%;
  max-width: 60rem;
  margin: auto;

  @media screen and (min-width: 575px) {
    display: flex;
    flex-direction: row;
    padding: 3rem;
    & > * {
      max-width: 50%;
      padding: 1.5rem;
    }
  }
`;
