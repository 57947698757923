import React from 'react'
import { Container } from './Transaction.styles';

function TransactionsBlock({currency, amount}) {
    return (
        <Container>
            <div className="total-transaction">Total Transaction</div>
            <div className="details-currency">{currency}</div>
            <div className="details-amount">{amount}</div>
        </Container>
    )
}

export default TransactionsBlock;
