/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import {  useHistory } from "react-router-dom";
import { PaymentContainer } from "./styles";
import { RiFileCopyLine } from "react-icons/ri";
import { getServerSidePropsAccount } from "./vnuban";
import { UserContext } from "../../store/userStore/user.context";
import Spinner from "react-bootstrap/Spinner";
import { LocalGetAccNum, LocalSetAccNum } from "../../utils/localStorage";
import TitleComponent from "../../components/TitleComponent";

const MakePayment = ({ ...props }) => {
  let { push } = useHistory();
  const searchParams = props.location?.search;
  const user = useContext(UserContext);
  const values = props?.location?.state?.detail;
  const { vendor, product, goto, totalAmount, quantity: qty } = values;

  let amount = goto === 'sendmoney' ? product?.amount : totalAmount;
  const quantity = qty || JSON.parse(window?.localStorage?.getItem("qty"));

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  useEffect(() => {
    let checkLocalAcc = LocalGetAccNum();
    if (
      checkLocalAcc == null ||
      checkLocalAcc === "undefined" ||
      checkLocalAcc === "null"
    ) {
      setIsLoading(true);
      getServerSidePropsAccount({
        amount,
        accountNumber: vendor?.accountNumber,
        storeName: vendor?.storeName,
        totalAmount: amount,
        vendorId: vendor?.id,
        productName: product?.name || 'N/A',
        productCode: product?.code || 'N/A',
        narration: 'accountdebit payment',
        quantity: quantity !== null ? quantity : 0,
      })
        .then((res) => {
          setData(res);
          LocalSetAccNum(JSON.stringify(res));
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } else {
      setData(JSON.parse(checkLocalAcc));
    }
  }, [amount]);

  const handleCancelPayment = () => {
    LocalSetAccNum(null);
    if (user) {
     push("/dashboard/products");
    } else {
     push(`/${vendor?.defaultUrl || user?.defaultUrl}`)
    }
  };
  const test = {vendor: vendor, product: product}
  const handleChangePayment = () => {
    LocalSetAccNum(null);
    if(user) {
      push({
        pathname: `/${user?.defaultUrl || vendor?.defaultUrl}/pay`,
        search: `${searchParams}`,
        state: { amount: amount, accountNumber: data?.accountNumber },
      });
    } else{
      push({
        pathname: `/${user?.defaultUrl || vendor?.defaultUrl}/checkout`,
        search: `${searchParams}`,
        state: { detail: test},
      });
    }
    
  };
  const handleCopy = (link) => {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = link;
    dummy.select();
    dummy.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");
    document.body.removeChild(dummy);

    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 3000);
  };
  const redirectToPayment = () => {
    push({
      pathname: `/${
        user?.defaultUrl || values?.vendor?.defaultUrl
      }/verify-vnuban`,
      search: `${searchParams}`,
      state: { amount: amount, accountNumber: data?.accountNumber , vendor:vendor, product: product, vnubanData: data, quantity },
    });
  };

  return (
    <PaymentContainer>
      <TitleComponent title="Make Payment With Transfer" />
      <div className="heading">
        <span>Pay with Transfer</span>
        <div
          onClick={handleChangePayment}
          className="link"
        >
          Change
        </div>
      </div>
      <div className="container">
        <p className="pay">
          <span className="pay1">Pay</span>{" "}
          <span className="pay2">
            {values?.product?.currency} {amount}
          </span>
        </p>
        <div className="sub-heading">
          Transfer {values?.product?.currency} {amount} to
        </div>

        {isLoading ? (
          <div className="account-details">
            <Spinner animation="border" role="status" variant="secondary">
              <span className="sr-only">Loading...</span>
            </Spinner>
            <p className="text">Account number loading...</p>
          </div>
        ) : (
          <div className="account-details">
            <div className="bank-name"> {data?.accountName}</div>
            <div className="acct">
              {" "}
              {data?.accountNumber || ""}
              {isCopy ? (
                <span style={{ paddingLeft: "10px" }}>copied!!!</span>
              ) : (
                <RiFileCopyLine
                  onClick={() => handleCopy(data?.accountNumber)}
                />
              )}
            </div>
            <p className="text">
              This account number expires in 30 minutes and can only be used for
              this transaction
            </p>
          </div>
        )}

        <button
          className="checkout-btn"
          disabled={isLoading}
          onClick={redirectToPayment}
        >
          I've sent the money
        </button>

        <div className="btn">
          <span className="btn1" onClick={handleChangePayment}>
            {" "}
            Change Payment Method
          </span>
          <span className="btn2" onClick={handleCancelPayment}>
            {" "}
            Cancel Payment
          </span>
        </div>
      </div>

      <div className="footer">
        <span>
          <img
            src="/favicon.ico"
            style={{ width: "40px", height: "40px" }}
            alt="access logo"
          />
          <i>Verified by Access</i>
        </span>
      </div>
    </PaymentContainer>
  );
};

export default MakePayment;
