import * as queryString from 'query-string';
import { Cypher } from '.';
import environment from '../environment';
import { Logout } from '../services/authentication';
const abbrev = 'KMB'; // could be an array of strings: [' m', ' Mo', ' Md']

const cypher = new Cypher(environment.AES_KEY, environment.IV_KEY);

export function roundNumberWithPrecision(number, precision) {
  precision = Math.pow(10, precision);
  return Math.round(number * precision) / precision;
}

export function userFriendVoteCount(number) {
  let base = Math.floor(Math.log(Math.abs(number)) / Math.log(1000));
  const suffix = abbrev[Math.min(2, base - 1)];
  base = abbrev.indexOf(suffix) + 1;
  return suffix
    ? Math.floor(roundNumberWithPrecision(number / Math.pow(1000, base), 2)) +
        suffix
    : number;
}

export const hasExpired = (timeStamp) => new Date(timeStamp) <= new Date();

var windowObjectReference = null;

export function openRequestedSinglePopup(url, name) {
  windowObjectReference = url;
  window.location.href = windowObjectReference;
  return windowObjectReference;
}

export const openWindow = (type = null, url = null) => {
  if (type) {
    return openRequestedSinglePopup(
      environment.BACKEND_URL + '/auth/social/' + type,
      'SwiftPay Authentication'
    );
  }
  window?.open(url, '_blank');
};

export function getRandom(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export const randomColorName = () => {
  const colors = [
    {
      bg: 'bg-secondary',
      text: 'text-white',
    },
    {
      bg: 'bg-info',
      text: 'text-dark',
    },

    {
      bg: 'bg-success',
      text: 'text-dark',
    },

    {
      bg: 'bg-danger',
      text: 'text-white',
    },
  ];
  const color = colors[getRandom(colors.length)];
  return ` ${color.bg} ${color.text} `;
};

export const redirectAfterAuth = (path = null) => {
  const { redirect } = queryString.parse(window.location.search);
  sessionStorage.setItem('redirectAuth', redirect || path || '/dashboard');
};
export const redirectAuth = (path = null, newId = null) => {
  // set new localstorage field
  if (newId) {
    localStorage.setItem('newId', newId);
  }
  path = sessionStorage?.getItem('redirectAuth') || path;
  window?.location?.replace(path);
  return path;
};

export const clearAllSessionExcept = (name) => {
  const savedKeys = ['redirectAuth'];
  Object.keys(sessionStorage || {}).forEach((key) => {
    if (key !== name && !savedKeys.includes(key)) {
      sessionStorage?.removeItem(key);
    }
  });
  if (name === 'localStorage') {
    Object.keys(localStorage || {}).forEach((key) => {
      if (key !== name && !savedKeys.includes(key)) {
        localStorage?.removeItem(key);
      }
    });
  }
};
export const clearRedirectAuthSession = () => {
  sessionStorage?.removeItem('redirectAuth');
  sessionStorage?.removeItem('redirectError');
};

const IDLE_TIMEOUT = 4 * 60; //15 minutes
let _idleSecondsTimer = null;
let _idleSecondsCounter = 0;
export const sessionManager = () => {
  document.onmousemove = function () {
    _idleSecondsCounter = 0;
  };

  document.onclick = function () {
    _idleSecondsCounter = 0;
  };

  document.onkeypress = function () {
    _idleSecondsCounter = 0;
  };

  if (!_idleSecondsTimer) {
    _idleSecondsTimer = window.setInterval(CheckIdleTime, 1000);
  }
  function CheckIdleTime() {
    _idleSecondsCounter++;
    if (_idleSecondsCounter >= IDLE_TIMEOUT) {
      window.clearInterval(_idleSecondsTimer);
      Logout();
    }
  }
};

export const autoExpand = function (field, minHeight = '50px') {
  // Reset field height
  field.style.height = minHeight;
  field.style.height = field.scrollHeight + 'px';
};

export const changeAddressBarUrl = (title, url) => {
  localStorage?.setItem('currentUrl', window?.location?.href);
  window?.history?.replaceState({}, title, url);
};
export const getCurrentUrl = () => localStorage?.getItem('currentUrl') || '';
export const redirect = (path) => {
  window.location.href = path;
};

export function stickyScroll(elem, self = window) {
  if (self.pageYOffset > elem.offsetTop + 101) {
    elem.classList.add('sticky');
  } else {
    elem.classList.remove('sticky');
  }
}

export const searchArrayOfObject = (key, arrayObject) => {
  try {
    return arrayObject.filter((items) => {
      const flatObject = JSON.stringify(items).toLowerCase();
      return flatObject.includes(key.toLowerCase());
    });
  } catch (e) {
    return [];
  }
};

export const formatCurrency = (event) => {
  const value = event.target.value.replace(/[^0-9]/gi, '');
  event.target.value = new Intl.NumberFormat().format(value);
};

export const formatSerial = (num, spacer = ' ', len = 4) => {
  const str = num.replace(/\s+/g, '');
  let newStr = '';

  for (let i = 1; i < str.length + 1; i++) {
    newStr += str[i - 1];
    if (i % len === 0) {
      newStr = i !== str.length ? `${newStr}${spacer}` : newStr;
    }
  }
  return newStr;
};

export const formatCardNumber = (event) => {
  const value = event.target.value.replace(/[^0-9]/gi, '');
  event.target.value = formatSerial(value);
};
export const formatName = (event) => {
  const value = event.target.value.replace('E', '');
  event.target.value = formatSerial(value);
};

export const formatExpiration = (event) => {
  const value = event.target.value.replace(/[^0-9]/gi, '');
  event.target.value = formatSerial(value, '/', 2);
};

const num = ['z', 'a', 'g', 'x', 'd', 'e', 'y', 'l', 'q', 'v'];
export const encryptAccount = (account) => {
  if (!Number(account)) {
    return 'Only Numbers are allowed.';
  }

  const toArray = ('' + account + '').split('');
  return toArray.map((digit) => num[digit]).join('');
};

export const decryptAccount = (account) => {
  if (typeof account !== 'string') {
    return 'Only strings are allowed.';
  }

  const toArray = account.split('');
  return toArray.map((chars) => num.indexOf(chars)).join('');
};

export const deformatAmount = (amount) => {
  const delim = amount.replaceAll(',', '');
  return Number(delim);
};

export const encryptPayload = (payload) => {
  return cypher.encrypt(JSON.stringify(payload));
};

export const decryptPayload = (encryptedPayload) => {
  return JSON.parse(cypher.decrypt(encryptedPayload));
};
