import React, { useState, useEffect, useContext } from "react";
import {  useHistory } from "react-router-dom";
import { TransferContainer } from "./styles";
import { getServerSidePropsAccount } from "./VerifyPayment";
import { UserContext } from "../../store/userStore/user.context";
import { LocalSetAccNum } from "../../utils/localStorage";
import TitleComponent from "../../components/TitleComponent";
import CountDown from "../VerifyUssd/timer";

const TransferVerification = ({ ...props }) => {
  const [seconds, minutes, resetTimer] = CountDown();
  const [data, setData] = useState();
  const [error, setError] = useState(false);
  const [, setIsLoading] = useState(false);

  let { push } = useHistory();
  const searchParams = props.location?.search;
  const user = useContext(UserContext);
  let values = props?.location?.state;
  
  useEffect(() => {
    let interval, timeout = null;
    setIsLoading(true);
    interval = setInterval(() => {
      getServerSidePropsAccount({
        accountNumber: values?.accountNumber,
        transactionRef: values?.vnubanData?.transactionReference,
        quantity: values?.quantity || 0,
        vendorId: values?.vendor?.id
      })
        .then((res) => {
          setData(res);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }, 30000);
    timeout = setTimeout(() => {
      clearInterval(interval);
      setError(!error);
    }, 1800000);
    return () => {
      if (interval || timeout)
      clearInterval(interval);
      clearTimeout(timeout)
    }
  }, [values?.accountNumber]);

  useEffect(() => {
    let interval = null;
    if (data?.availableBalance === values?.amount) {
      LocalSetAccNum(null);
      resetTimer()
      interval = setTimeout(() => {
        push({
          pathname: `/${user?.defaultUrl || values?.vendor?.defaultUrl}/receipt`,
          search: `?transaction=${values?.vnubanData?.transactionReference}`,
        });
      }, 3000);
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [data]);

  const test = {vendor: values?.vendor, product: values?.product}
  const handleChangePayment = () => {
    LocalSetAccNum(null);
    resetTimer();
    if(user) {
      push({
        pathname: `/${user?.defaultUrl || values?.vendor?.defaultUrl}/pay`,
        search: `${searchParams}`,
        state: { amount: values?.amount, accountNumber: data?.accountNumber },
      });
    } else{
      push({
        pathname: `/${user?.defaultUrl || values?.vendor?.defaultUrl}/checkout`,
        search: `${searchParams}`,
        state: { detail: test},
      });
    }
  };
  const handleCancelPayment = () => {
    LocalSetAccNum(null);
    resetTimer();
    if (user) {
      push("/dashboard/products");
     } else {
      push(`/${values?.vendor?.defaultUrl || user?.defaultUrl}`)
     }
  };

  return (
    <TransferContainer>
      <TitleComponent title="Verify Payment" />
      <div className="heading">
        <span>Pay with Transfer</span>
        <div
          onClick={handleChangePayment}
          className="link"
        >
          Change
        </div>
      </div>
      <div className="container">
        <p className="pay">
          <span className="pay1">Pay</span>{" "}
          <span className="pay2">NGN {values?.amount}</span>
        </p>

        {data?.availableBalance === values?.amount ? (
          <div
            style={{
              color: "green",
              textAlign: "center",
              padding: "70px 0px",
              fontSize: "25px",
            }}
          >
            {" "}
            PAYMENT RECEIVED!!!
          </div>
        ) : (
          <>
            <div className="sub-heading">
              We're waiting to receive your transfer.
              <p>This can take up to a minute or more...</p>
            </div>
            {/* {!error && (
                <div
                  style={{
                    color: "#f44336",
                    textAlign: "center",
                    padding: "70px 0px",
                    fontSize: "25px",
                  }}
                >
                  Transaction Failed
                </div>
              )} */}

            <div className="timer">
              <span>
                {`${minutes} : ${seconds < 10 ? "0" + seconds : seconds} `}
              </span>
            </div>
          </>
        )}

        <div className="btn">
          <span className="btn1" onClick={handleChangePayment}>
            {" "}
            Change Payment Method
          </span>
          <span className="btn2" onClick={handleCancelPayment}>
            {" "}
            Cancel Payment
          </span>
        </div>
      </div>

      <div className="footer">
        <span>
          <img
            src="/favicon.ico"
            style={{ width: "40px", height: "40px" }}
            alt="access logo"
          />
          <i>Verified by Access</i>
        </span>
      </div>
    </TransferContainer>
  );
};

export default TransferVerification;
