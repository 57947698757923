import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  width: 100%;
  /* height: 100%; */
  align-items: center;
  background-color: #003883;
  color: white;
  border-radius: 0.5rem;
  box-sizing: border-box;
  padding: 0.2rem 1.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);

  .link-image {
    /* font-size: 1rem; */
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (min-width: 850px) {
    grid-template-columns: 15% 82%;
    column-gap: 0.5rem;
    padding: 0.8rem 0.9rem;
    width: 60%;

    .link-image {
      /* border: 1px solid black; */
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  @media screen and (min-width: 1440px) {
    padding: 0.5rem 1rem;

    .link-image {
      /* border: 1px solid black; */
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media screen and (min-width: 1450px) {
    padding: 1.3rem 1rem;

    .link-image {
      /* border: 1px solid black; */
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .my-3 {
    width: 100%;
    display: flex;
    justify-content: center;

    /* align-items: center; */
    flex-direction: column;
    padding-right: 0.5rem;
    /* border: 1px solid red; */
    span {
      font-size: 1rem;
      word-wrap: break-word;
      text-align: justify;
    }
    .link u {
      font-size: 0.85rem;
      word-wrap: break-word;
      text-align: justify;
    }
    /* @media screen and (min-width: 566px) {
        span {
        font-size: 1.5rem;
      }
      .link u{
        font-size: 1.4rem;
    }
    } */
    @media screen and (min-width: 850px) {
      span {
        font-size: 0.8rem;
      }
      .link u {
        font-size: 0.8rem;
        font-weight: bold;
      }
    }
    @media screen and (min-width: 950px) {
      span {
        font-size: 0.87rem;
      }
      .link u {
        font-size: 0.87rem;
        font-weight: bold;
      }
    }
    @media screen and (min-width: 1300px) {
      span {
        font-size: 1rem;
      }
      .link u {
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }
`;
