import styled from "styled-components";

export const PaymentContainer = styled.div`
  max-width: 25rem;
  height: 100vh;
  margin: auto;
  padding-top: 3rem;

  @media screen and (min-width: 768px) {
    padding-top: 7rem;
  }

  .container {
    padding: 1.25rem 1.25rem 0rem;
    border: 1px solid #dcdcdc;
    border-radius: 0.625rem;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
  .heading {
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 1.25rem 0rem;
    font-size: 1.25rem;

    .link {
      color: #ffa500;
      cursor: pointer;
    }
    span {
      //   opacity: 0.6;
      color: #003883;
    }
  }
  .email-id {
    display: flex;
    justify-content: flex-end;
    opacity: 0.6;
  }

  .pay {
    display: flex;
    justify-content: flex-end;
    border: 1px solid #dcdcdc;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 0rem 0rem 1.25rem;

    .pay1 {
      opacity: 0.6;
      padding-right: 0.313rem;
    }
    .pay2 {
      color: #ffa500;
      font-weight: bold;
    }
  }
  .sub-heading {
    text-align: center;
    color: #003883;
    padding: 0.625rem;
  }

  .account-details {
    text-align: center;
    padding: 1.25rem;
    background-color: #f8f8ff;
    margin-bottom: 0.875rem;
    border-radius: 0.313rem;

    .bank-name {
      color: #003883;
      font-size: 1.25rem;
      padding-bottom: 0.313rem;
    }
    .acct {
      color: #003883;
      font-weight: bold;
      font-size: 1.563rem;
      padding-bottom: 0.5rem;
    }
    .text {
      opacity: 0.6;
      font-size: 0.75rem;
    }
  }
  .checkout-btn {
    border: 1px solid #dcdcdc;
    border-radius: 0.313rem;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    margin-bottom: 1.25rem;
    width: 100%;
    color: #fff;
    background: #003883 !important;
  }
  .checkout-btn:disabled {
    cursor: not-allowed;
    background-color: #fefefe;
  }
  .checkout-btn:hover {
    color: #fff;
    background: #01224d !important;
    font-weight: bolder;
  }
  .btn {
    display: flex;
    justify-content: center;
  }
  .btn1 {
    border: 1px solid #dcdcdc;
    border-radius: 0.313rem;
    margin-right: 0.313rem;
    padding: 0.5rem 0.25rem;
    font-size: 0.938rem;
    background-color: #f8f8ff;

    @media screen and (max-width: 320px) {
      font-size: 0.625rem;
    }
  }
  .btn2 {
    border: 1px solid #dcdcdc;
    border-radius: 0.313rem;
    padding: 0.5rem 0.25rem;
    font-size: 0.938rem;
    background-color: #f8f8ff;
    color: #f44336;
    @media screen and (max-width: 320px) {
      font-size: 0.625rem;
    }
  }

  .footer {
    margin: 5rem 0;
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;
