import styled from "styled-components";


export const OtpPageContainer = styled.div`
.btn {
    height: 50px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    background: #003883;
    color: #fff;
    :hover {
      background: #01224d;
      font-weight: bolder;
      cursor: pointer;
    }
    @media screen and (max-width: 780px)
 {
    font-size: 0.8rem;
}
  }
  button {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    width: 100%;
    display: block;
    position: relative;
    left: 0;

  }
  button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background: rgba(1, 13, 27, 0.61)
  color: #666666;
  cursor: not-allowed! important;

}

  

  .default-align-card {
    padding-left: .1rem;
    padding-right: .1rem;
    margin: 0 auto;
    width: 100% ;
    max-width:400px;
    
  }
  
  .border-bottom {
    width: 100%; 
    display: flex;
    // text-align: centre;

  }

  .userInput{
    display: flex;
    justify-content: center;
    max-width:400px;
  }

  input{
    margin:15px;
    height:35px;
    width:50%;
    border:1px solid black;
    text-align: center;
    font-size: 30px;
    background:#fff;
    border-top: hidden;
    border-right: hidden;
    border-left: hidden;
    outline: none;
  
  }
  input:focus {outline:none!important;


`;

