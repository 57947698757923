import styled from "styled-components";

export const Container = styled.div`
  margin-top: 5rem;
  @media screen and (min-width: 800px) {
    margin: 0;
  }
`;

export const BodyWrapper = styled.div`
  width: 100%;
  /* text-align: center; */
  padding: 2rem;
  margin: 0 auto;
  max-width: 450px;

  .update-profile-header {
    font-weight: 500;
    font-size: 1.75rem;
  }

  @media screen and (min-width: 800px) {
    margin: 0;
    max-width: 70vw;
    margin-left: 25vw;
    padding: 6% 10% 6% 13%;
    padding-top: 7rem;
  }
`;

export const TextWrapper = styled.h1`
  color: #18184d;
  font-size: 1.5em;
  margin-bottom: 2rem;
`;

export const ImageWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const FormWrapper = styled.form``;
export const FormSelect = styled.select`
  width: 100%;
  padding: 0.8rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
  background-color: #f1f1f1;
  border: 1px solid #b3b3b3;
  color: #495057;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 0.8rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
  background-color: #f1f1f1;
  border: 1px solid #b3b3b3;
  color: #495057;
`;

export const SelectTextWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
`;
export const InputTitleWrapper = styled.div`
  font-size: 1em;
  width: 100%;
  text-align: left;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: baseline;
`;
