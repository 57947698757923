import React, { useState } from "react";
import { Container } from "./Payment.styles";
import TabShareIcon from "../../assets/tap-share.svg";
import { Link } from 'react-router-dom'
import { redirect } from "../../utils/helpers";
import  enviroment  from "../../environment";


function PaymentLink({ user }) {
  const [showCopyIcon, setShowCopyIcon] = useState(true);
  const { appUrl } = enviroment;
  
  let timeout = null;
  const userUrl = `${appUrl}/${user}`;

  
  function backToProducts() {
    redirect(`/${user}`);
  }
    const handleCopyToClipboard = (text) => async () => {
        try {
    
          if (navigator?.clipboard) {
            await navigator.clipboard.writeText(text);
          } else {
            const tempInput = document.createElement("input");
            tempInput.style = "opacity: 0;";
            tempInput.value = text;
      
            document.body.appendChild(tempInput);
            tempInput.select();
      
            document.execCommand("copy");
            document.body.removeChild(tempInput);
          }
          setShowCopyIcon(false);
          // alert(`${userUrl} url copied`);
            if (!timeout) {
              timeout = setTimeout(() => {
                setShowCopyIcon(true)
              }, 3000)
            }
        } catch {
          setShowCopyIcon(false);
        }
      };
    
  return (
    <Container onClick={handleCopyToClipboard(userUrl)}>
      <div className="link-image">
        <img src={TabShareIcon} alt="tapImage" />
      </div>
      <div>
        {!showCopyIcon ? (
          <div className="my-3">
            <span>Copied to clipboard..</span>
            <span>
              <Link to={userUrl}>
                <u>{userUrl}</u>
              </Link>
            </span>
          </div>
        ) : (
          <div className="my-3">
            <span>
              Tap the icon to share store link and start receiving payments.
            </span>
            <div
              className="link"
              onClick={() => {
                setTimeout(() => {
                  backToProducts();
                }, 2000);
              }}
            >
              <u>{userUrl}</u>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}

export default PaymentLink;
