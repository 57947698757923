import React, { useContext, useState } from "react";
import Navbar from "../../components/dashboardHeader";
import { UserContext } from "../../store/userStore/user.context";
import Payment from "../Payment";
import Api from "../Api";
import UpdateProfile from "../UpdateProfile";
import {
  ProfileHeaderWrapper,
  ProfileHeader,
  ProfileContainer,
  ProfileWrapper,
} from "./styles";
import TitleComponent from "../../components/TitleComponent";

const Profile = () => {
  const user = useContext(UserContext);
  const [activeClass, setActiveClass] = useState("btn1");

  const handleActive = (val) => {
    setActiveClass(val);
  };

  return (
    <>
      <TitleComponent title="Account" />
      <Navbar storeName={user?.storeName} />
      <ProfileContainer>
        <ProfileHeaderWrapper>
          <ProfileHeader
            onClick={() => handleActive("btn1")}
            className={`${activeClass === "btn1" && "active"}`}
          >
            Profile
          </ProfileHeader>
          <ProfileHeader
            onClick={() => handleActive("btn2")}
            className={`${activeClass === "btn2" && "active"}`}
          >
            Payment
          </ProfileHeader>
          <ProfileHeader
            onClick={() => handleActive("btn3")}
            className={`${activeClass === "btn3" && "active"}`}
          >
            API
          </ProfileHeader>
        </ProfileHeaderWrapper>
        <ProfileWrapper>
          {activeClass === "btn1" ? <UpdateProfile /> : activeClass === "btn2" ? <Payment /> : <Api />}
        </ProfileWrapper>
      </ProfileContainer>
    </>
  );
};

export default Profile;
