import styled from "styled-components";

export const BusinessCardRequestContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 1rem;
  padding-top: 1rem;
  font-size: 0.9em;
  color: #030f4f;
  margin-top: 7rem;
  margin-bottom: 5rem;
  text-align: left;

  h1 {
    font-weight: 500;
    font-size: 1.5em;
  }

  input {
    outline: none;
  }

  .password-link {
    text-decoration: underline;
    color: orange;
    font-size: 0.9em;
  }

  .profile-update-button {
    padding: 0.8rem 1.5rem;
    font-size: 1em;
    color: #fff;
    background: #003883;
    border: none;
    border-radius: 5px;
    width: 100%;
    margin-top: 3rem;
  }

  @media screen and (min-width: 575px) {
    width: 70%;
    margin: 7rem auto 5rem;
  }

  @media screen and (min-width: 800px) {
    max-width: 70vw;
    margin-left: 25vw;
    padding: 2% 10% 4% 13%;
  }
`;

export const InputConatiner = styled.div`
  /* padding: 2rem 0; */
  padding-bottom: 1rem;

  .colored-span {
    color: #dc3545;
    font-size: 0.8em;
    text-align: left;
    margin-bottom: 0.5rem;
    margin-left: 0.3rem;
  }
`;

export const Header = styled.h1`
  color: #18184d;
  font-size: 1.5em;
  margin-bottom: 2rem;
`;
