import styled from "styled-components";

export const ProductsContainer = styled.div`
  margin-top: 5rem;
  @media screen and (min-width: 800px) {
    margin-top: 0rem;
  }
`;

export const ProductsWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-column-gap: 0.5rem;
  margin: 2rem 0rem;

  @media screen and (min-width: 800px) {
    grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
    margin: 2rem 0rem;
  }
  @media screen and (min-width: 543px) {
    grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  }
`;

export const SearchInputWrapper = styled.div``;

export const ButtonWrapper = styled.div`
  width: 100%;
  @media screen and (min-width: 800px) {
    margin-bottom: 6rem;
  }
`;

export const ProductCard = styled.div``;
export const BodyWrapper = styled.div`
  padding: 1rem 1.4rem;
  margin-top: 5rem;
  max-width: 600px;
  margin: 0 auto;

  @media screen and (min-width: 800px) {
    margin:0;
    max-width: 75vw;
    margin-left: 25vw;
    padding: 4rem 5rem;
  }
`;

export const SingleProductWrapper = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  .back-arrow {
    padding-bottom: 1rem;

    .icon{
      width: 1rem;
      font-size: 1.5rem;
      color: #00008b;
      cursor: pointer;
    }

    .icon:hover{
      width: 2rem;
      font-size: 2rem;
      color: #003883;
      cursor: pointer;
    }
  }

  @media screen and (min-width: 800px) {
    padding-top: 3rem;
    /* justify-content: space-between; */
  }
`;
export const AlignButtonCenter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
