import React from "react";
import * as GetIcon from "@primer/octicons-react";
import Icons from "../ShareCopy";
import { CustomInputContainer } from "./inputstyle";
const Input = React.forwardRef((props,registers,ref ) => {
  let {...register} = registers || {name: ''}
  // console.log(errors)
  const {
    label,
    id,
    error: propsError,
    style,
    onChange,
    defaultValue,
    isRequired,
    placeholder,
    "data-name": dataName,
    className,
    name,
    icon,
    iconType,
    defaultType,
    type,
    onKeyUp,
    disabled,
    onBlur,
    maxLength,
  } = props;
  let styled = { ...style, outline: "none !important" };

  if (type.toUpperCase() === "OTP") {
    styled = {
      ...styled,
      letterSpacing: "50px",
      border: "none",
      background: "none",
      outline: "0px",
      boxShadow: "none",
      fontSize: "50px",
      textTransform: "uppercase",
      marginBottom: "-25px",
      // textAlign: "left",
    
    };
  }

  let typeIcon = <></>;
  if (icon && GetIcon[icon]) {
    const Icon = GetIcon[icon];
    // styled.opacity = "0.6";
    if (iconType) {
      typeIcon = (
        <div className="password-icon">
          <Icons className="small-icon" size="medium" icon={iconType} />
        </div>
      );
    } else {
      typeIcon = (
        <div className="password-icon">
          <Icon className="small-icon" size="medium" />
        </div>
      );
    }
  }
  return (
    <CustomInputContainer>
      <label htmlFor={id}> {label} </label>
      <div className={"input-group " + className}>
        <input
        
          register={register || ref}
          id={id}
          className={" " + (propsError ? "error" : "")}
          style={styled}
          onChange={onChange}
          onKeyUp={onKeyUp}
          type={type === "otp" ? "text" : type || defaultType}
          defaultValue={defaultValue}
          required={isRequired}
          placeholder={placeholder || label}
          name={name || dataName}
          onBlur={onBlur}
          {...(props.value ? { value: props.value } : {})}
          disabled={disabled}
          maxLength={maxLength}
        />
        {typeIcon}
      </div>

      {propsError && (
        <>
          <span className="text-danger">{propsError.message}</span> <br />
        </>
      )}
      <br />
    </CustomInputContainer>
  );
});

Input.defaultProps = {
  htmlFor: "app",
  id: "app",
  isRequired: false,
  defaultValue: "",
  type: "text",
  style: {},
  onChange: () => {},
  onKeyUp: () => {},
};

export default Input;
