import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Heading, { GoBackHeading } from "../../components/Utils/Heading";
import SuccessfulAccount from "../../components/Alert/SuccessfulAlert";
import countDown from "../../components/CountDown";
import { OtpPageContainer} from "./otpstyles";
import Spinner from "react-bootstrap/Spinner";
import useAxios from "../../components/customHook/useAxios";
import { redirectAuth } from "../../utils/helpers";
import { encryptPassCode } from "../../utils";
import { Helmet } from "react-helmet";
import OtpInput from "react-otp-input";


const OtpPage = () => {
  const [seconds, minutes, resetTime] = countDown();

  const [successAlert, openSuccessAlert] = useState(false);
  const [passCode, setPassCode] = useState("");
  const [message, setMessage] = useState(
    "Account verified successfully, you are now unlimited."
  );
  const [validateRegistration, { data, error }, isLoading] = useAxios("post");
  const [retryResend, { data: retryData, error: retryError }] =
    useAxios("post");

  
  useEffect(() => {
    if (retryData) {
      toast.success("OTP token has been resent.");
    } else if (retryError) {
      toast.error(error.message);
    }
  }, [retryData]);

  useEffect(() => {
    if (data && !isLoading && !error) {

      if (
        typeof window !== "undefined" &&
        localStorage.getItem("typeEndPoint") === "/auth/verify/otp"
      ) {
        toast.success(message);
        toast.clearWaitingQueue();
        openSuccessAlert(true);
      } else {
        redirectAuth("/change-password");
      }
    } else if (error) {
      toast.error(error.error || error.message);
      toast.clearWaitingQueue();
    }
  }, [data]);

  const handleChange = (passCode) => setPassCode(passCode);

  function handleRegister() {
    const endPoint = localStorage.getItem("typeEndPoint");

    // setRememberUser(remember);
    if (typeof window !== "undefined") {
      const fieldName = localStorage.getItem("field");
      const value = localStorage.getItem(fieldName);
      if (!isLoading) {
        const fieldValue =
          value && value !== "undefined" ? value : Number(999 * 999 * 999);
        validateRegistration(endPoint, {
          [fieldName]: fieldValue,
          passCode: passCode,
        }, true);
      }
    }
  }

  function resendOtp() {
    const endPoint = localStorage.getItem("typeEndPoint");
    const fieldName = localStorage.getItem("field");
    const value = localStorage.getItem(fieldName);
    resetTime();
    // toast.success('OTP token has been resent.')
    retryResend(endPoint + "/resend", { id: value }, true);
  }
  let history = useHistory();

  const handleGoBack = () => {
    history.push("/login");
  }
  return (
    <OtpPageContainer>
      <Helmet>
        <title>Enter OTP</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      {successAlert && (
        <SuccessfulAccount
          message={message}
          onClose={openSuccessAlert}
        />
      )}
      <div className="row bg-white">
        <div className="col-12 col-sm text-dark p-4">
          <div className="default-align-card">
            <GoBackHeading text="" className="pb-4" style={{ cursor: "pointer" }} onGoBack={handleGoBack} />
            <Heading text="Enter OTP" />

            <div className="my-4 py-2" />
            <p>
              A one time verification pin has been sent to your registered phone
              number.
            </p>
            <span className="text-danger d-block text-center">
              {error?.error || error?.message}{" "}
            </span>
            <div className="userInput">
              <OtpInput
                value={passCode}
                onChange={handleChange}
                numInputs={5}
                isInputNum={true}
              />
            </div>
            <button
              onClick={handleRegister}
              className="btn default my-4"
              disabled={passCode.length < 5 || isLoading || data}
            >
              {isLoading || data ? (
                <Spinner
                  animation="grow"
                  role="status"
                  variant="light"
                  size="sm"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Verify"
              )}
            </button>
            <span className="w-25 mx-auto default d-block text-center text-dark border p-2">
              {`${minutes} : ${seconds < 10 ? "0" + seconds : seconds} `}
            </span>
            <button
              className="btn text-theme border my-3 mx-auto d-block"
              style={{
                maxWidth: "150px",
                backgroundColor: "#fff",
                color: "#be5f05",
              }}
              onClick={resendOtp}
              disabled={minutes === 0 && seconds === 0 ? false : true}
            >
             Resend Otp
            </button>
          </div>
        </div>
      </div>
    </OtpPageContainer>
  );
};
export default OtpPage;
