import styled from "styled-components";

export const Container = styled.div`
  
`;

export const DisplayWrapper = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5); */
`;

export const NoTransaction = styled.div`
padding: 1rem 1.4rem;
`;
export const HeaderWrapper = styled.div`
  
`

export const BodyWrapper = styled.div`
margin-top: 6rem;
margin: 6rem auto 0 auto;
    max-width: 500px;
height: 100%;
  .title {
  padding: 0rem 1.4rem;
  color: darkblue;
  /* margin-top: 6rem; */
}
.btn-search-wrapper {
  padding: 1rem 1.4rem;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 45%;
  grid-gap: 5%;
  justify-content: space-between;
  align-items: center;
}
@media screen and (min-width: 800px) {
        max-width: 75vw;
        margin-left: 25%;
        padding: 0 5rem;
    }

    @media screen and (min-width: 1000px) {
        max-width: 75vw;
        margin-left: 25%;
        padding: 0 7rem;
    }
    @media screen and (min-width: 1200px) {
        max-width: 75vw;
        margin-left: 25%;
        padding: 0 9rem;
    }
    @media screen and (min-width: 1500px) {
        max-width: 75vw;
        margin-left: 25%;
        padding: 0 15rem;
    }
`;
