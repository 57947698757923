import * as yup from "yup";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const cardSchema = (product) =>{
  return  yup.object().shape({
    vendorId: yup.string().required(),
    amount: yup.string().matches(/^[0-9]/, "Enter a valid amount").required("Amount Required").default(product?.amount?.toString()),
    paymentFrom: yup.string().trim().matches(/^[a-zA-Z]+ [a-zA-Z]+$/, "Enter first name and last name only without special characters").required( "Full name is required").default(product?.paymentFrom),
    expirationDate: yup.string().matches(/^(0[1-9]|1[0-2])\/?(([0-9]{4}|[0-9]{2})$)/g, "invalid card expiration date").required('Expiry Date is required.'),
    narration: yup.string().min(5).required(),
    number: yup.string().min(16, 'Invalid card number').max(25, 'Invalid card number').required('Credit Card number is required.'),
    cvv: yup.string().min(3).max(8).required('CVV is required.'),
    pin: yup.string(),
    customerId: yup.string().min(5),
    email: yup.string().email(),
    currency: yup.string().default(product?.currency),
    phoneNumber:yup.string().matches(phoneRegExp, 'Phone number is not valid')
  });
}
