const generate = (length, digit = false) => {
    let generatedSecret = '';
    const numbers = '0123456789';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    if (digit) {
      const numbersLength = numbers.length;
      for (let i = 0; i < length; i++) {
        generatedSecret += numbers.charAt(Math.floor(Math.random() * numbersLength));
      }
      return generatedSecret;
    }
    for (let i = 0; i < length; i++) {
      generatedSecret += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return generatedSecret;
  };
  
  export default generate;
  