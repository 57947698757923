import React from "react";
import {
  Container,
  TransactionListWrapper,
  NoTransaction,
  ColorGenerator,
} from "./TransactionList.styles";
import Files from "../../assets/files.svg";
import Moment from "react-moment";

function TransactionsList({ transactions }) {

  function generateRandomColor(transaction) {
    const colorType = transaction?.status?.toLowerCase()
    switch(colorType){
      case "successful": 
      return " #28a745";
      case "pending": 
      return "#7c6d6d"
      default:
        return "#ff3737"
    }
  }
 
  return (
    <Container>
      {transactions.length ? (
        transactions.map((transaction, id) => (
          <div key={id}>
            <TransactionListWrapper >
              <ColorGenerator color={generateRandomColor(transaction)} />
              <div>
                <p>
                  Transfer {transaction?.status} <br />
                  {transaction?.paymentFrom} <i>
                  {transaction?.productName} ({transaction?.productCode})
              </i>
                </p>
                <h6>{transaction?.productName} </h6>
              </div>
              <div className="flex-end">
                <p className="amount">{transaction?.currency} {transaction?.amount}</p>
                <Moment fromNow>{transaction?.createdAt}</Moment>
              </div>
            </TransactionListWrapper>
          </div>
        ))
      ) : (
        <NoTransaction className="no-transaction">
          <img className="img-2" src={Files} alt="empty" />
          <p>You currently do not have any transaction</p>
        </NoTransaction>
      )}
    </Container>
  );
}

export default TransactionsList;
