import styled from "styled-components";


export const Container = styled.div`
margin-top: 2rem;
.paginationBttns{
    /* width: 80%; */
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
    text-decoration: none;
}

.paginationBttns a{
    padding: 10px;
    margin: 8px;
    border: 1px solid #003883;
    cursor: pointer;
    color: #003883;
    border-radius: 5px;
    text-decoration: none;
}

.paginationBttns a:hover {
    color:#fff;
    background: #009cb6;

}

.paginationActive a {
    color: #fff;
    background: #003883;
}
.paginationDisabled a{
    color: rgba(255, 255, 255, 0.1);
    background-color: #009cb6;
    opacity: 0.1;
}

`;