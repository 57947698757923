const Cardinal = window.Cardinal;

Cardinal?.configure({
  logging: {
    level: "off",
  },
  extendedtimeout: 80000,
  maxRequestRetries: 1,
});

export default Cardinal;
