import React, { useState, useEffect, memo } from "react";
import { useParams, useRouteMatch, useHistory } from "react-router-dom";
import Spinner from "../../components/Loader/Spinner";
import useAxios from "../../components/customHook/useAxios";
import PageNotFound from "../404Page";
// import axios from "axios";
// import http from "../../services/http";

function RedirectPage() {
  let { id } = useParams();
  const [getToken, setToken] = useState(null);
  let match = useRouteMatch("/:id");
  const { push } = useHistory();

  if (id.includes("@")) {
    let split = id.split("@");
    id = split[1];
  }
  const redirectToProduct = () => {
    push({
      pathname: `${match.url}/store`,
      state: { vendor: getToken },
    });
  };
  const redirectToSendMoney = async () => {
   
    push({
      pathname: `/${id}/send-money`,
      state: {vendor:getToken },
    });
  };
  const [getVendor, { data, error }, isLoading] = useAxios("get");
  async function handleLogin(userName) {
    getVendor(`/account/vendor/${userName }`);
  }
  useEffect(() => {
    if (!isLoading) {
      handleLogin(id);
    }
  }, [id]);

  useEffect(() => {
    if (data && data !== null) {
      setToken(data);
    }
  }, [data, error]);

  useEffect(() => {
    window.localStorage?.removeItem('vendor');
    if (getToken !== undefined && getToken !== null && !error) {
      window.localStorage.setItem("vendor", JSON.stringify(getToken));
      if (!match.url.includes("@")) {
        setTimeout(() => {
          redirectToProduct();
        }, 2000);
      } else {
        setTimeout(() => {
          redirectToSendMoney();
        }, 2000);
      }
    }
  }, [getToken]);

  return error ? (
    <PageNotFound />
  ) : (
    <div>
      <Spinner tag={""} />
    </div>
  );
}

export default memo(RedirectPage);
