import React, { useState, useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { BiArrowBack } from "react-icons/bi";

import { decryptPayload, formatCardNumber, formatExpiration, redirect } from "../../utils/helpers";
import validCardType from "../../utils/card";
import { checkDailyTransactionLimit, createTransaction, make3DPayment } from "../../services/auth.http";

import AccessImage from "../../assets/img/access.png";
import { UserContext } from "../../store/userStore/user.context";
import Input from "../CustomInput";
import PasswordInput from "../CustomInput/PasswordInput";
import CustomButton from "../CustomButton";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router";

import {
  Container,
  HeaderWrapper,
  BodyWrapper,
  Textwrapper,
  InputFieldWrapper,
  FooterWrapper,
  TextArea,
  CvvExpDateWrapper,
} from "./Payment.styles";

import generate from "../../utils/generaterandom";
// import { useEffect } from "react";
// import { generateSessionId } from "../../services/payment/paymentservice";
import CardinalUtilsV2 from "../../services/payment/cardinalutilv2";
import { toast } from "react-toastify";
import { initCardinal, sendToCardinal } from "../../services/payment/cardinalutilhook";


function PaymentPage({ ...props }) {
  let value = props.location?.state?.detail;
  const searchParams = props.location?.search;
  const vendor = useContext(UserContext) || value.vendor;
  const { product, quantity} = value;
  
  const [errorMessage, setErrorMessage] = useState("");
  const [cardType, setCardType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { push } = useHistory();

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const cardSchema = yup.object().shape({
    vendorId: yup.string().required(),
    amount: yup.string().matches(/^[0-9]/, "Enter a valid amount"),
    paymentFrom: yup.string().trim().matches(/^[a-zA-Z]+ [a-zA-Z]+$/, "Enter first name and last name only without special characters").required( "Full name is required").default(product?.paymentFrom),
    expirationDate: yup.string().matches(/^(0[1-9]|1[0-2])\/?(([0-9]{4}|[0-9]{2})$)/g, "invalid card expiration date").required('Expiry Date is required.'),
    narration: yup.string().min(5).required(),
    number: yup.string().min(16, 'Invalid card number').max(25, 'Invalid card number').required('Credit Card number is required.'),
    cvv: yup.string().min(3).max(4).required('CVV is required.'),
    pin: yup.string(),
    customerId: yup.string().min(5),
    email: yup.string().email(),
    currency: yup.string().default(product?.currency),
    phoneNumber:yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(cardSchema),
  });

  const handleCardType = ({ target }) => {
    const getCard = validCardType(target.value);
    if (getCard) {
      setCardType(getCard + "Icon");
    }
  };

  const successAlert = toast.success;
  const errorAlert = toast.error;

  const makePayment = async (payload) => {
    try {
      setIsLoading(true)
      const unformatAmount =
      product?.price?.replaceAll(',', '') || payload?.amount?.replaceAll(',', '');

      const amount = unformatAmount * quantity || unformatAmount;
      const currency = product?.currency || payload.currency;
      const reconciliationId = `SWIFTCRD${generate(10)}`;

      const checkLimitPayload = {
        vendorId: Number(vendor.id),
        amount
      }
      const checkLimit = await checkDailyTransactionLimit(checkLimitPayload);
      const decryptLimit = decryptPayload(decryptPayload(checkLimit?.data?.data));
  
      if(checkLimit?.status === 200 && decryptLimit?.exceedLimit === false){
        const { ReferenceId, SessionId } = await initCardinal({ currency, amount: amount.toString() }, {
          setIsLoading, errorAlert
        });
        const transPayload = {
          merchantRef: reconciliationId,
          accountNumber: vendor.accountNumber,
          storeName: vendor.storeName,
          amount,
          totalAmount: amount,
          vendorId: Number(vendor.id),
          currency,
          reconciliationId,
          refId: reconciliationId,
          productName: product.name || 'N/A',
          productCode: product.code || 'N/A',
          narration: payload.narration,
          channel: payload.channel || "CARD",
          paymentFrom: payload?.paymentFrom,
          transactionCurrencyType: currency.toLowerCase() === 'usd' ? 'INTERNATIONAL' : 'LOCAL',
          transactionRef: reconciliationId,
          quantity
        }
  
        const addTrans = await createTransaction(transPayload);
        const decrypt = decryptPayload(decryptPayload(addTrans?.data?.data));
  
        if ([200, 201].includes(addTrans.status)) {

        
          // Start Cardinal stuffs...
          await sendToCardinal({
            card: payload?.number,
            cvv: payload?.cvv,
            expireDate: payload?.expirationDate,
            accountNumber: vendor?.accountNumber,
            amount: amount.toString(),
            quantity,
            vendorId: Number(vendor?.id),
            currency: product?.currency || payload?.currency,
            narration: payload?.narration,
            channel: payload?.channel || "CARD",
            paymentFrom: payload?.paymentFrom,
            payerName: payload?.paymentFrom,
            merchantRef: reconciliationId,
            ReferenceId,
            spTransactionId: decrypt.transactionRef,
            productName: product?.name || 'SEND-MONEY',
            cardType,
            vendor,
            SessionId
          }, {
            successAlert, errorAlert, setIsLoading, back: backToCheckout
          })
        } else {
          errorAlert('Server Error Occured.. ');
        }
      }else{
        setIsLoading(false);
        setErrorMessage('Daily limit exceeded..')
        return errorAlert('Daily limit exceeded.. ');
      }
      
      

    } catch (error) {
      setIsLoading(false);
      return errorAlert('Server error occured.. ');
    }
  }

  function backToCheckout() {
    // push({
    //   pathname: `/${vendor?.defaultUrl}/checkout`,
    //   search: `${searchParams}`,
    //   state: { detail: value },
    // });
    setTimeout(() => {
      redirect(`/${vendor?.defaultUrl}`)
    }, 1500)
  }
  return (
    <Container>
      <HeaderWrapper>
        <div className="back-arrow" onClick={backToCheckout}>
          <BiArrowBack className="icon" />
        </div>
        <h3>{vendor.storeName}</h3>
      </HeaderWrapper>
      <BodyWrapper>
        <Textwrapper>
          <div>Order Amount:</div>{" "}
          <div>
            {" "}
            {product.currency} {value?.totalAmount}
          </div>
        </Textwrapper>
        <Textwrapper>
          <div>{product.description}</div>
        </Textwrapper>
        <InputFieldWrapper>
          <form onSubmit={handleSubmit(makePayment)}>
            <Input
              id="paymentFrom"
              name="paymentFrom"
              onChange={(e) => {
                setError("paymentFrom", {
                  type: "manual",
                  message: "Dont Forget Your Username Should Be Cool!",
                });
              }}
              {...register("paymentFrom")}
              label={"Depositor’s fullname"}
              placeholder="Fullname eg. John Doe"
            />
            {errors.paymentFrom && (
              <div className="text-danger" style={{textAlign:'left', marginTop:"-1rem", marginBottom:'0.7rem'}}>{errors.paymentFrom.message[0].toUpperCase() +errors.paymentFrom.message.slice(1) }</div>
            )}
            <input
              type="hidden"
              value={vendor.id}
              onChange={(e) => {
                setError("vendorId", {
                  type: "manual",
                  message: "No vendorId!",
                });
              }}
              name="vendorId"
              {...register("vendorId")}
            />
            {errors.vendorId && (
              <div className="text-danger">{errors.vendorId.message}</div>
            )}
            <Input
              id="narration"
              name="narration"
              onChange={(e) => {
                setError("narration", {
                  type: "manual",
                  message: "Narration field cannot be empty!",
                });
              }}
              {...register("narration")}
              label={"Narration"}
              placeholder="Comment to vendor"
            />
            {errors.narration && (
              <div className="text-danger"style={{textAlign:'left', marginTop:"-1rem", marginBottom:'0.7rem'}}>{errors.narration.message[0].toUpperCase() +errors.narration.message.slice(1) }</div>
            )}
            <label htmlFor="deliveryAddress">Delivery address</label>
            <TextArea
              id="deliveryAddress"
              name="delivery"
              {...register("delivery")}
              onChange={(e) => {
                setError("delivery", {
                  type: "manual",
                  message: "Enter an Address!",
                });
              }}
              size="4"
              placeholder="Delivery Address"
              className="mb-4"
            />
            {/* {errors.delivery && <div className="text-danger">{errors.delivery.message}</div>} */}
            <Input
              name="number"
              onChange={(e) => {
                setError("number", {
                  type: "manual",
                  // message: "Enter a valid card number!"
                });
              }}
              {...register("number")}
              label={"Credit card number"}
              icon={"CreditCardIcon"}
              iconType={cardType}
              placeholder="0000 0000 0000 0000"
              onKeyUp={formatCardNumber}
              onBlur={handleCardType}
              style={{ fontSize: "1.05rem", color: "#000000" }}

            />
            {errors.number && (
              <div className="text-danger"style={{textAlign:'left', marginTop:"-1rem", marginBottom:'0.7rem'}}>{errors.number.message[0].toUpperCase() +errors.number.message.slice(1) }</div>
            )}
            <CvvExpDateWrapper>
              <div>
                <Input
                  id="expirationDate"
                  name="expirationDate"
                  label={"Expiry date"}
                  placeholder="MM/YY"
                  onKeyUp={formatExpiration}
                  onChange={(e) => {
                    setError("expirationDate", {
                      type: "manual",
                      message: "The expiring date is invalid!",
                    });
                  }}
                  {...register("expirationDate")}
                  size="5"
                  style={{ fontSize: "1.05rem", color: "#000000" }}
                />
                {errors.expirationDate && (
                  <div className="text-danger"style={{textAlign:'left', marginTop:"-1rem", marginBottom:'0.7rem'}}>{errors.expirationDate.message[0].toUpperCase() +errors.expirationDate.message.slice(1) }
                  </div>
                )}
              </div>
              <div>
                <Input
                  id="cvv"
                  name="cvv"
                  
                  size="4"
                  label={"CVV/CVN"}
                  onChange={(e) => {
                    setError("cvv", {
                      type: "manual",
                      message: "The Cvv is invalid!",
                    });
                    
                  }}
                  {...register("cvv")}
                  placeholder="****"
                  type="password"
                  style={{ fontSize: "1.05rem", color: "#000000" }}
                />
                {errors.cvv && (
                  <div className="text-danger"style={{textAlign:'left', marginTop:"-1rem", marginBottom:'0.7rem'}}>{errors.cvv.message[0].toUpperCase() +errors.cvv.message.slice(1) }</div>
                )}
              </div>
            </CvvExpDateWrapper>

            {cardType === "VerveCardIcon" && (
              <PasswordInput
                name="pin"
                label={
                  <>
                    PIN{" "}
                    <small className="text-danger">(verve cards only)</small>
                  </>
                }
                placeholder="****"
                {...register("pin")}
                size="4"
                isRequired={false}
              />
            )}

            {cardType === "VerveCardIcon" && (
              <Input
                id="email"
                name="email"
                {...register("email")}
                label={"Email"}
                placeholder="Email"
              />
            )}
            {errors.email && (
              <div className="text-danger"style={{textAlign:'left', marginTop:"-1rem", marginBottom:'0.7rem'}}>{errors.email.message[0].toUpperCase() +errors.email.message.slice(1) }</div>
            )}

            {cardType === "VerveCardIcon" && (
              <Input
                id="phoneNumber"
                name="phoneNumber"
                {...register("phoneNumber")}
                label={"Phone Number"}
                placeholder="Phone Number"
              />
            )}
            {errors.phoneNumber && (
              <div className="text-danger"style={{textAlign:'left', marginTop:"-1rem", marginBottom:'0.7rem'}}>{errors.phoneNumber.message[0].toUpperCase() +errors.phoneNumber.message.slice(1) }</div>
            )}
            <br />

            <div className="text-danger">{errorMessage}</div>

            <CustomButton
              shape=""
              width="100%"
              style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => console.log()}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  animation="grow"
                  role="status"
                  variant="light"
                  size="sm"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Pay now"
              )}
            </CustomButton>
          </form>
        </InputFieldWrapper>
      </BodyWrapper>
      <FooterWrapper>
        <span>
          <img
            src={AccessImage}
            style={{ width: "40px", height: "40px" }}
            alt="access logo"
          />
          <i>Verified by Access</i>
        </span>
      </FooterWrapper>
    </Container>
  );
}

export default PaymentPage;
