import styled from 'styled-components';
export const Container = styled.div`
    padding: 1rem 1.8rem;
`;

export const TitleWrapper = styled.h3`
    margin-bottom: 2rem;
@media screen and (min-width: 768px) {
        font-size: 2rem;
    }
`;

export const StoreName = styled.div`
    width: 100%;
    text-align: center;
`;

export const BodyWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    max-width: 500px;
`;

export const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1rem;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 1rem;
`;

export const ImageWrapper = styled.div`
    width: 12rem;
    height: 12rem;
    margin: auto;
    /* margin-bottom: 3rem; */

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
`;

export const TextWrapper = styled.p`
     margin-bottom: 1rem;
@media screen and (min-width: 768px) {
        font-size: 1.5rem;
    }
`;
export const ContentWrapper = styled.p`
    color: ${(props)=>props.color};
     margin-bottom: 1rem;
     font-weight: bold;
@media screen and (min-width: 768px) {
        font-size: 1.5rem;
    }
`;

export const Underline = styled.div`
    width: 100%;
    border: 1px solid gray;
`;

export const ShareDetailWrapper = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    padding: 1rem 1.4rem;
/* border: 1px solid red; */

.back-arrow{
    position: absolute;
      right: 0;
      top: 0;
      padding-top: 1rem;
       padding-right: 1rem; 
}
`;

export const EmailShareWrapper = styled.div`
    width: 70%;
`;
export const SocialMediaShareWrapper = styled.div`
    width: 20%;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
`;
