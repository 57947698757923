import React, { useContext, useEffect, useState } from "react";
import { Header, InputConatiner, BusinessCardRequestContainer } from "./styles";
import InputField from "../../components/Input";
import Button from "../../components/Button";
// import Navbar from "../../components/dashboardHeader";
import Navbar from "../../components/dashboardHeader";
import bankBranches from "../../utils/branches.json";
import environment from "../../environment";
// import Input from "../Form/Input/Input";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { useFormik } from "formik";
import { useHistory, withRouter } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import useAxios from "../../components/customHook/useAxios";
import { requestBusinessCardSchema } from "../../schema/auth";
// import Select from "../Form/Select/Index";
import { UserContext } from "../../store/userStore/user.context";
import CustomSelect from "../../components/CustomSelect";
import TitleComponent from "../../components/TitleComponent";
import bankCardTypes from "../../utils/cardTypes.json";
import Error from "../../components/Error";
import { toastify } from "../../components/Toast";

const BusinessCardRequest = ({ match, location }) => {
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [branches, setBranches] = useState([]);
  const [cardTypes, setCardType] = useState([]);
  const [requestBusinessCard, response, isLoading] = useAxios("post");
  const [getBranches, bResponse, bisLoading] = useAxios("get");
  const [getCardTypes, cResponse, cisLoading] = useAxios("get");
  // const { register, handleSubmit, errors, reset } = useForm({
  //   resolver: yupResolver(requestBusinessCardSchema),
  // });

  const { push } = useHistory();

  const user = useContext(UserContext);

  useEffect(() => {
    if (
      !bisLoading &&
      !bResponse.data &&
      !bResponse.error &&
      !branches.length &&
      !cisLoading &&
      !cResponse.data &&
      !cResponse.error &&
      !cardTypes.length
    ) {
      getBranches("/utils/branches/array");
      getCardTypes("/utils/cards/array");
    }
  }, [
    bResponse.data,
    bResponse.error,
    bisLoading,
    branches,
    cResponse.data,
    cResponse.error,
    cardTypes,
    cisLoading,
    getBranches,
    getCardTypes,
  ]);

  if (
    !bisLoading &&
    bResponse.data &&
    !bResponse.error &&
    !branches.length &&
    !cisLoading &&
    cResponse.data &&
    !cResponse.error &&
    !cardTypes.length
  ) {
    setBranches(bResponse?.data);
    setCardType(cResponse?.data);
  }

  useEffect(() => {
    if (response?.data) {
      setMessage(response?.data?.message);
      toast.success(response.data.message);
      toast.clearWaitingQueue();
    } else if (response.error) {
      setErrorMessage(response?.error?.error);
      toast.error(response.error.error);
      toast.clearWaitingQueue();
    }
  }, [response]);
  const isDisabled = isLoading;

  useEffect(() => {
    const branchOptions = bankBranches.map((branch) => ({
      value: branch.branch_name,
      label: branch.branch_name,
      pickUpBranch: branch.branch_code
    }));

    const cardOptions = bankCardTypes.map((card) => ({
      value: card.card_type,
      label: card.card_type,
      cardProductId: card.card_productid
    }));

    setBranches(branchOptions);
    setCardType(cardOptions);
  }, []);

  let localStorage = {
    getItem: () => {},
    setItem: () => {},
  };

  if (typeof window !== "undefined") {
    localStorage = window.localStorage;
  }

  const serviceName = {
    name: process.env.APP_NAME || "ACCESSGATE_SERVER_1",
    version: "0.1",
  };

  const token = localStorage?.getItem("dpAuth");

  const formik = useFormik({
    initialValues: {
      accountNumber: user.accountNumber,
      phoneNumber: user.phoneNumber,
      nameOnCard: user.userName,
      address: "",
      branchCode: "",
      cardType: "",
      street: "",
      cardProductId: "",
      pickupBranch: ""
    },
    validationSchema: requestBusinessCardSchema,
    onSubmit: async (payload) => {
      try {
        if (!Object.keys(payload).length) return;
        payload.id = user.id;

        if (!isLoading && payload) {
          const response = await axios.post(
            environment.BACKEND_URL + "/auth/vendor/cardrequest",
            payload,
            {
              headers: {
                "Content-Type": "application/json",
                signature: JSON.stringify(serviceName),
                Authorization: token ? `Bearer ${token}` : null,
              },
            }
          );
          const { data } = response;

          if (data?.status === 200) {
            toastify("Your Business Card has been requested succcessfully", "success")
            push(`/dashboard`);
          } else {
            toastify("Business card request failed", "error");
          }
        }
      } catch (e) {
        if (e?.response?.status === 401) {
          toast.error(e.response.data.error);
          push('/login');
        } else if (e.response?.status === 400) {
          toastify(e?.response.data.error)
        } else if (e?.response?.data?.error) {
          toastify(e.response.data.error);
        } else {
          toastify("Business Card request failed.");
        }
      }
    },
  });

  return (
    <>
      <TitleComponent title="Request Business Card" />
      <Navbar storeName={user?.storeName} />
      <BusinessCardRequestContainer>
        <Header>Business Card Request Page</Header>
        <form onSubmit={formik.handleSubmit}>
          <InputConatiner>
            <InputField
              inputTitle="Access Bank Account Number"
              // className="input-field"
              type="number"
              name="accountNumber"
              value={user.accountNumber}
              disabled
              placeholder="1224602258"
            />
            <InputField
              inputTitle="Phone Number"
              placeholder="081 ***"
              name="phoneNumber"
              label={"Phone number"}
              onChange={formik.handleChange}
              defaultValue={formik.values.phoneNumber}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <Error>{formik.errors.phoneNumber}</Error>
            ) : null}
            <InputField
              inputTitle="Name on Card"
              name="nameOnCard"
              placeholder="John Doe LLT"
              onChange={formik.handleChange}
              defaultValue={formik.values.nameOnCard}
            />
            {formik.touched.nameOnCard && formik.errors.nameOnCard ? (
              <Error>{formik.errors.nameOnCard}</Error>
            ) : null}
            <CustomSelect
              isSearch
              name="branchCode"
              label="Type And Select PickUp branch"
              options={branches}
              onChange={(value) => {
                formik.setFieldValue("branchCode", value.value)
                formik.setFieldValue("pickupBranch", value.pickUpBranch)
              }
              }
            />
            {formik.touched.branchCode && formik.errors.branchCode ? (
              <Error>{formik.errors.branchCode}</Error>
            ) : null}
            <CustomSelect
              isSearch
              name="cardType"
              label="Type And Select Card Type"
              options={cardTypes}
              onChange={(value) => {
                formik.setFieldValue("cardType", value.value)
                formik.setFieldValue("cardProductId", value.cardProductId)
              }
            }
            />
            {formik.touched.cardType && formik.errors.cardType ? (
              <Error>{formik.errors.cardType}</Error>
            ) : null}
            <InputField
              name="address"
              placeholder="John Doe_llt"
              inputTitle="Your address"
              onChange={formik.handleChange}
              value={formik.values.address}
            >
              <p className="colored-span">(please include lga and state)</p>
            </InputField>
            {formik.touched.address && formik.errors.address ? (
              <Error>{formik.errors.address}</Error>
            ) : null}

            <InputField
              inputTitle="Street/Landmark"
              name="street"
              placeholder="John Doe_llt"
              onChange={formik.handleChange}
              value={formik.values.street}
            />
            {formik.touched.street && formik.errors.street ? (
              <Error>{formik.errors.street}</Error>
            ) : null}
          </InputConatiner>
          <Button
            className="profile-update-button"
            disabled={isLoading}
            type="submit"
            text={
              isLoading ? (
                <Spinner
                  animation="grow"
                  role="status"
                  variant="light"
                  size="sm"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Request Card"
              )
            }
          />
        </form>
        {message}
      </BusinessCardRequestContainer>
      <ToastContainer />
    </>
  );
};

export default withRouter(BusinessCardRequest);
