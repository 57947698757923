import styled from "styled-components";

export const Container = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;
export const HeaderWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  h3 {
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    font-size: 2rem;
  }
`;
export const Textwrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0;
  @media screen and (min-width: 768px) {
    font-size: 2rem;
  }
`;
export const BodyWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 1rem 2rem;

  label {
    text-align: left;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: baseline;
  }
`;
export const InputFieldWrapper = styled.div`
  padding-top: 2rem;
`;
export const FooterWrapper = styled.div`
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  text-align: center;
`;
export const TextArea = styled.textarea`
  width: 100%;
  padding: 0.8rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
  background-color: #f1f1f1;
  border: 1px solid #b3b3b3;
  color: #495057;
  text-align: left;
`;

export const CvvExpDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1rem;
`;
