import axios from 'axios';
import environment from '../environment';

let localStorage = {
  getItem: () => {},
  setItem: () => {},
};
if (typeof window !== 'undefined') {
  localStorage = window.localStorage;
}
const serviceName = {
  name: process.env.APP_NAME || 'ACCESSGATE_SERVER_1',
  version: '0.1',
};
const notLogin = JSON.parse(localStorage.getItem('vendor'));
const tokenNotLogin = notLogin && notLogin.token
const token = localStorage?.getItem('dpAuth') || tokenNotLogin;


// axios.interceptors.response.use(function (response) {
//   return response;
// }, function (error) {
  
//   return Promise.reject(error);
// });

// process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = '0';
const http = (headers, multi) =>
  axios.create({
    baseURL: environment.BACKEND_URL,
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': multi ? 'multipart/form-data' : 'application/json',
      Accept: 'application/json',
      signature: JSON.stringify(serviceName),
      Authorization: token ? `Bearer ${token}` : null,
      // 'X-Frame-Options': 'SAMEORIGIN',
      // key: 'Content-Security-Policy',
      // value:
      //   "default-src * data: 'unsafe-inline' 'unsafe-eval'; worker-src * data: blob:; script-src * data: blob: 'unsafe-eval' 'unsafe-inline';'frame-ancestors' 'none';",
      // httpsAgent: new https.Agent({  
        // rejectUnauthorized: false,
      // }),
      ...headers,
    },
  });
  
export const allowedMethods = ['POST', 'GET', 'PATCH', 'DELETE', 'PUT'];

export function getHttpInstance({ method, headers }) {
  method = method.toString().toUpperCase();
  if (!allowedMethods.includes(method)) {
    throw new Error(`"${method}" as a method is not allowed.`);
  }
  const http = httpHeaders(headers);
  const instance = {
    POST: http.post,
    DELETE: http.delete,
    PATCH: http.patch,
    GET: http.get,
    PUT: http.put,
  };
  return instance[method];
}

export const httpHeaders = http;

const httpV2 = (headers, multi) => 
  axios.create({
    baseURL: environment.BACKEND_URL_V2,
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': multi ? 'multipart/form-data' : 'application/json',
      Accept: 'application/json',
      signature: JSON.stringify(serviceName),
      Authorization: token ? `Bearer ${token}` : null,
      // 'X-Frame-Options': 'SAMEORIGIN',
      // key: 'Content-Security-Policy',
      // value:
      //   "default-src * data: 'unsafe-inline' 'unsafe-eval'; worker-src * data: blob:; script-src * data: blob: 'unsafe-eval' 'unsafe-inline';'frame-ancestors' 'none';",
      // httpsAgent: new https.Agent({  
        // rejectUnauthorized: false,
      // }),
      ...headers,
    },
  });

export const httpv2 = httpV2();
export default http();
