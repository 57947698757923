import styled from "styled-components";

export const TransactionsContainer = styled.div`
  width: 100%;
  padding: 1rem 1rem 1.5rem;
  /* padding-top: 4rem; */
  margin-top: 5rem;
  max-width: 400px;
  margin: 0 auto;
  padding-top: 7rem;

  & > .paginationWrapper {
    .previous {
      list-style: none;
    }
  }
  & > .paginationItem {
    list-style-type: none;
  }

  .title {
    /* text-align: left; */
    font-size: 1.2em;
    margin-bottom: 2rem;
    font-weight: 500;
    color: #030f4f;
  }

  .transaction-info {
    margin-top: 5rem;
    p {
      font-size: 0.85em;
      color: #343a40;
      font-weight: 600;

      :not(:last-of-type) {
        margin-bottom: 0.5rem;
      }
    }
  }

  .transaction-notification-wrapper {
    margin: 0;
    margin-right: 1.5rem;

    .transaction-notification {
      border-bottom: 1px solid grey;

      .transaction-title {
        display: flex;
        align-items: center;
        width: 100%;

        .successful {
          background-color: #28a745;
        }

        .status {
          width: 1rem;
          height: 1rem;
          border-radius: 1rem;
        }

        .pending {
          background: #975a5a;
        }
      }

      .paid-by {
        margin-left: 1rem;
      }
    }
  }

  .paginationBttns {
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
    text-decoration: none;
    width: max-content;
    margin: auto;
  }

  .paginationBttns a {
    width: 100%;
    padding: 10px;
    margin: 8px;
    border: 1px solid #003883;
    cursor: pointer;
    color: #003883;
    border-radius: 5px;
    text-decoration: none;
  }

  .paginationBttns a:hover {
    color: #fff;
    background: #009cb6;
  }

  .paginationActive a {
    color: #fff;
    background: #003883;
  }

  @media screen and (min-width: 575px) {
    max-width: 720px;
    padding: 0 3rem;
    margin: auto;
    margin-top: 7rem;
  }

  @media screen and (min-width: 800px) {
    max-width: 70vw;
    position: absolute;
    right: 2rem;
    padding: 0 10%;
  }
`;

export const SearchTransactions = styled.div`
  width: 100%;
  display: flex;
  height: 3.5rem;
  border: 1px solid #acacac;
  align-items: center;
  border-radius: 5px;

  .input {
    outline: none;
    padding: 0.8rem;
    flex: 1;
    height: 100%;
    border: none;
    border-right: 1px solid #acacac;
    ::placeholder {
      font-size: 0.9em;
    }
  }

  .filter-button {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0.5rem 1rem;
    border: none;
    background-color: #fff5eb;
    color: #be5f05;
    font-weight: 600;

    &:focus {
      outline: none;
    }

    &::after {
      display: inline-block;
      margin-left: 0.255rem;
      vertical-align: 0.255rem;
      content: "";
      border-top: 0.255rem solid;
      border-right: 0.255rem solid transparent;
      border-bottom: none;
      border-left: 0.255rem solid transparent;
      margin-left: 0.8rem;
    }
  }
`;

export const FilterDropdown = styled.div`
  position: absolute;
  display: block;
  padding: 0.5rem 0;
  color: #212529;
  font-weight: 400;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  background: white;
  transition: all 0.5s;

  p {
    padding: 0.5rem 1rem;
    padding-right: 1.5rem;
    font-size: 0.9em;
    cursor: pointer;
    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
    &:hover {
      color: #16181b;
      background-color: #e9ecef;
    }
  }
`;
