import http from '../../services/http';
import { decryptPayload } from '../../utils/helpers';

export async function getServerSidePropsProduct(query) {
    const notFound = '/page/not-found?=' + Math.random();
    try {
      const vendorsProduct = await http.get(`product/${query.pid}/view/${query.store}`);
      let decryptedData = decryptPayload(vendorsProduct?.data?.data)
      if (vendorsProduct?.data?.data) {
        return {
          product: decryptedData.product,
          vendor: decryptedData.vendor,
        }
      }
  
    } catch (e) {
      return {
        redirect: {
          destination: notFound,
          permanent: false,
        },
      };
    }
  }