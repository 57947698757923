import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import defaultImage from "../../assets/store-icon.svg";
import { productSchema } from "../../schema/auth";
import { httpHeaders } from "../../services/http";
import { UserContext } from "../../store/userStore/user.context";
import CustomButton from "../CustomButton";
import Input from "../CustomInput";
import Header from "../dashboardHeader";
import ImgPrev from "../ImagePreview";
import InputField from "../Input";
import { toastify } from "../Toast";
import Heading from "../Utils/Heading";
import Spinner from "react-bootstrap/Spinner";
import {
  BodyWrapper,
  Container,
  FormSelect,
  FormWrapper,
  ImageWrapper,
  InputTitleWrapper,
  SelectTextWrapper,
  TextArea,
  TextWrapper,
} from "./CreateProduct.styles";
import { decryptPayload } from "../../utils/helpers";

function AddProduct() {
  const user = useContext(UserContext);
  const [message, setMessage] = useState(false);
  const [forms, setForms] = useState("NGN");
  const [loading, setIsloading] = useState(false)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(productSchema),
  });

  const [productImage, setProductImage] = useState("");
  const { push } = useHistory();

  const handleChange = (e) => {
    if (e.target?.value) {
      setForms(e.target.value);
    }
  };

  const handlePhoto = (value) => {
    setProductImage(value);
  };

  const submitForm = async (data) => {
    try {
      if (!Object.keys(data).length) return;
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("price", data.price.replace(/[^0-9]/gi, ""));
      formData.append("qty", data.qty);
      formData.append("vendorId", user.id);
      formData.append("image", productImage);
      formData.append("currency", forms);

      setIsloading(true);

      const product = await httpHeaders({}, true).post(
        "/product/add",
        formData
      );

      if (product && product.data) {
        setIsloading(false)
        toastify(product.data?.data?.message, "success");
        setMessage(product.data?.data?.message);

        setTimeout(() => {
          push("/dashboard/products");
        }, 2000);
        reset();
      }
    } catch (e) {
      setIsloading(false)
      if (e?.response?.status === 401) {
        toastify(e.response.data.error, "error");
        push("/login");
      } else if (e?.response?.data?.error) {
        toastify(e?.response?.data?.error, "error");
      } else {
        toastify("could not add product.", "error");
      }
    }
  };
  
  const [numberStates, setNumberStates] = useState({
    price: "",
    qty: "",
  });

  const checkInput = (e) => {
    const { value, name } = e.currentTarget;
    const re = /^[0-9\b]+$/;

    if (value === "" || re.test(value)) {
      setNumberStates((prev) => ({ ...prev, [name]: value }));
      setError(name, {
        type: "manual",
        message: "",
      });
    }
  };
const click =()=>{

}
  return (
    <Container>
      <Helmet>
        <title>Create Product</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Header storeName={user.storeName} />
      <BodyWrapper>
        <TextWrapper className="update-profile-header">
          <Heading text="Add Product" />
        </TextWrapper>

        <FormWrapper onSubmit={handleSubmit(submitForm)}>
          <ImageWrapper style={{ marginBottom: "1rem" }}>
            <ImgPrev imgs={handlePhoto} imgSrc={defaultImage} />
          </ImageWrapper>
          <Input
            id="name"
            name="name"
            onChange={(e) => {
              setError("name", {
                type: "manual",
                message: "Enter Product Name!",
              });
            }}
            {...register("name")}
            label={"Name"}
            placeholder="Product Name e.g Iphone 6"
          />
          {errors.name && (
            <div style={{ marginTop: "-1rem" }} className="text-danger">
              {errors.name.message[0].toUpperCase() +
                errors.name.message.slice(1)}
            </div>
          )}
          <SelectTextWrapper>
            <InputTitleWrapper>
              <span>Description (50 words max)</span>
            </InputTitleWrapper>

            <TextArea
              id="description"
              name="description"
              onChange={(e) => {
                setError("description", {
                  type: "manual",
                  message: "Enter product description!",
                });
              }}
              {...register("description")}
              size="4"
              placeholder="Product Description"
              className="mb-4"
            />
            {errors.description && (
              <div style={{ marginTop: "-1rem" }} className="text-danger">
                {errors.description.message[0].toUpperCase() +
                  errors.description.message.slice(1)}
              </div>
            )}
          </SelectTextWrapper>
          <SelectTextWrapper style={{ marginBottom: "1.5rem" }}>
            <InputTitleWrapper>
              <span>Currency</span>
            </InputTitleWrapper>
            <FormSelect name="currency" value={forms} onChange={handleChange}>
              <option value="NGN">NGN</option>
              {user?.transactionCurrency?.toUpperCase() === "INTERNATIONAL" && (
                <option value="USD">USD</option>
              )}
            </FormSelect>
          </SelectTextWrapper>
          <InputField
            style={{ margin: "1rem 0" }}
            id="price"
            name="price"
            value={numberStates.price}
            {...register("price")}
            onChange={(e) => {
              checkInput(e);
              setError("price", {
                type: "manual",
                message: "",
              });
            }}
            inputTitle="Price"
            placeholder="1000"
          />
          {errors.price && (
            <div
              style={{ marginTop: "-1rem", marginBottom: "1rem" }}
              className="text-danger"
            >
              {errors.price.message}
            </div>
          )}
          <InputField
            id="qty"
            name="qty"
            value={numberStates.qty}
            {...register("qty")}
            onChange={(e) => {
              checkInput(e);
              setError("qty", {
                type: "manual",
                message: "",
              });
            }}
            inputTitle="Quantity"
            placeholder="eg 10"
          />
          {errors.qty && (
            <div
              style={{ marginTop: "-1rem", marginBottom: "1rem" }}
              className="text-danger"
            >
              {errors.qty.message}
            </div>
          )}
          <div className="text-success d-block m-3">{message}</div>
          <CustomButton
            shape=""
            width="100%"
            style={{
              padding: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={click}
            type="submit"
          >
            {loading ? (
            <Spinner animation="grow" role="status" variant="light" size="sm">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            " Add Product"
          )}
          </CustomButton>
        </FormWrapper>
      </BodyWrapper>
    </Container>
  );
}

export default AddProduct;
