import React, { useState, useEffect, useContext, memo } from "react";

import { useLocation, useHistory } from "react-router-dom";
import Spinner from "../Loader/Spinner";
import { Container } from "./PaymentConfirmPage";
import { UserContext } from "../../store/userStore/user.context";
import useAxios from "../customHook/useAxios";
// import { encryptAccount, searchArrayOfObject } from "../../utils/helpers";
import { updateTransaction, updateProductQuantity } from "../../services/auth.http";

function PaymentConfirmPage() {

  const [getTransaction, { data, error:errorsFromBEnd }, isLoading] = useAxios("get");
  const [transactions, setTransaction] = useState({});
  const [error, setError] = useState(false);
  const [, setIsLoading] = useState(false);
  const [, setErrorMessage] = useState("");

  const { push } = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const vendor = useContext(UserContext) ||
    JSON.parse(window.localStorage?.getItem("vendor"));
  
  const getQty = JSON.parse(window.localStorage?.getItem("qty"));
  const getProduct =JSON.parse(window.localStorage?.getItem("product"));
// console.log(getProduct)
  let transactionId = query.get("transactionId");
  let status = query.get("status");
  let merchantRef = query.get("merchantRef");
  let errors = query.get("error") || null;
  const PaymentDetail = { transactionId, status, merchantRef, errors };
  

  const notEmpty = (trans) => {
    if (!trans) return false;
    if (Object.entries(trans).length > 0) return true;
    return false;
  }

  useEffect(() => {
    if (
      notEmpty(vendor) &&
      !isLoading &&
      !errorsFromBEnd &&
      !notEmpty(data) &&
      !notEmpty(transactions)
    ) {
      getTransaction(
        "/transaction/" + merchantRef
      );
    }
  }, [transactions]);

  if (
    vendor &&
    data &&
    !error &&
    !notEmpty(transactions)
  ) {
    setTransaction(data);
  }

  useEffect(() => {
      if (notEmpty(transactions)) {
        transactions.status = "SUCCESSFUL";
        newTransaction(transactions);
        updateProduct()
      }
     
  },[ transactions])

  const updateProduct = async () => {
    let updated =  await updateProductQuantity([getProduct?.code, getQty])
    if (updated) {
      window.localStorage.removeItem('product')
      window.localStorage.removeItem('qty')
    }
    
  }
  const newTransaction = async (val) => {
    try {
      setIsLoading(true);
      let paym = await updateTransaction(val);
      if (paym?.data?.data?.status === 200 && paym?.data?.data?.success) {
       
        return "Transaction updated successful";
      } else if (paym?.error?.status === 400) {
        setErrorMessage(
          paym.error.error.error.message || paym.error.error.error.reason
        );
      } else {
        setErrorMessage("Transaction not updated");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setErrorMessage(err);
    }
  };

  const redirectToReceipt = () => {
    push({
      pathname: `/${vendor?.defaultUrl}/receipt`,
      search: `?transaction=${PaymentDetail?.merchantRef}`,
      // state: { detail: { product: PaymentDetail, vendor: vendor } },
    });
  };

  useEffect(() => {
    let timeout = null;
    if (!error && PaymentDetail?.errors) {
      setError(true);
    } else if (PaymentDetail?.transactionId) {
      if (typeof window !== "undefined") {
          timeout = setTimeout(() => {
            redirectToReceipt()
        }, 1000);
      }
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [error]);

  return error ? (
    <Container>Invalid Payment details!...</Container>
  ) : (
    <Container>
      <Spinner tag={"Validating payment..."} />
    </Container>
  );
}

export default memo(PaymentConfirmPage);
