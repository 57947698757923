import React from "react";
import Line from "../../assets/line-1.svg";
import BackArrow from "../../assets/arrows-back.svg";

const Heading = React.forwardRef(({ children, className, text, fontSize }, ref) => {
  return (
    <div className={"Heading " + className} style = {{fontWeight: "600",
      fontSize: "1.875rem",
      lineHeight: "30px",
      color: "black",
      width: "100%",
      }}>
      {children ? children : <span style={{display:"block",fontSize:fontSize?? "1.875rem"}}>{text}</span>}
      <img src={Line} alt="" style={{display:"block"}}/>
    </div>
  );
});

export const GoBackHeading = React.forwardRef(
  (
    { children, text, className, noArrow, onGoBack, onClick, ...props },
    ref
  ) => {
    const handleClick = () => {
      onClick = () => {
        
      };
      onGoBack();
    };
    return (
        <>
      <div className={"Heading back " + className}>
        <div onClick={handleClick} className={"arrow"}>
          {!noArrow ? <img src={BackArrow} alt=""/> : <></>}
        </div>
        <div className="text">{children || text}</div>
      </div>
      </>
    );
  }
);
GoBackHeading.defaultProps = {
  onGoBack: () => {
    if (typeof window !== "undefined") {
      window.history.go(-1);
    }
  },
  onClick: () => {},
  noArrow: false,
};
export default Heading;
