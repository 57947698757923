import React, { useState } from "react";
import Heading from "../../components/Utils/Heading";
import Spinner from "react-bootstrap/Spinner";
import PasswordInput from "../../components/CustomInput/PasswordInput";
import { ChangePasswordContainer } from "./changepasswordstyles";
import useAxios from "../../components/customHook/useAxios";
import { clearAllSessionExcept } from "../../utils/helpers";
import TitleComponent from "../../components/TitleComponent";
import SuccessfulAccount from "../../components/Alert/SuccessfulAlert";

const ChangePassword = ({ ...props }) => {
  const user = props.location.state;
  const [resetPassword, { data, error }, isLoading] = useAxios("post");
  const [newPassword, setPassword] = useState(null);
  const [confirmNewPassword, setPassword2] = useState(null);
  const [message, setMessage] = useState("");
  const submit = () => {
    if (!confirmNewPassword || !newPassword) {
      setMessage("Password fields are required and should match");
      return;
    }
    if (!isLoading) {
      setMessage("");
      const fieldName = localStorage.getItem("field");
      const value = localStorage.getItem(fieldName);
      resetPassword("/auth/changePassword", {
        [fieldName || "id"]: value || user,
        confirmNewPassword,
        newPassword,
      }, true);
    }
  };

  if (data && !isLoading && !error) {
    clearAllSessionExcept();
  }
  return (
    <ChangePasswordContainer>
      <TitleComponent title="Change Password Swift pay" />
      <div className="default-align-card">
        <br /> <br />
        <Heading text="Change Password" />
        <div style={{marginTop: "1.25rem"}}>
        <span className="text-danger">
          {message || error?.reason || error?.error || error?.message}{" "}
        </span>
        {data ? (
          <>
            <SuccessfulAccount
              title="Password Changed Successfully"
              message="Your password has been changed successfully, proceed to login"
            />
          </>
        ) : (
          <>
            <div>
              <PasswordInput
                data-name="password"
                label={"New password"}
                placeholder="***"
                onKeyUp={({ target }) => setPassword(target.value)}
                autoComplete="off"
              />
              <PasswordInput
                data-name="password2"
                label={"Confirm new password"}
                placeholder="***"
                onKeyUp={({ target }) => setPassword2(target.value)}
                autoComplete="off"
              />
              <button className="btn default my-3" onClick={submit}>
                {isLoading ? (
                  <Spinner
                    animation="grow"
                    role="status"
                    variant="light"
                    size="sm"
                  >
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  "Change Password"
                )}{" "}
              </button>
            </div>
          </>
        )}
      </div>
      </div>
    </ChangePasswordContainer>
  );
};
export default ChangePassword;
