import React, { useState } from "react";
import { QRWrapper, QRImage, QRText, ShareDetailsWrapper } from "./styles";
import DownloadIcon from "../../assets/download.svg";
import CopyIcon from "../../assets/copy.svg";
import ShareIcon from "../../assets/share2.svg";
import ShareSocialMedia from "../Share";

const QRCode = ({ details }) => {
  const { text, url, download, copy, qrImage } = details;

  const [showPaymentLink, setShowPaymentLink] = useState(false);
  const [showProductLink, setShowProductLink] = useState(false);
  const [sharePaymentUrl, setSharePaymentUrl] = useState(false);
  // const [shareUrl, setShareUrl] = useState(false);

  const togglePaymentLink = () => {
    if (showPaymentLink) {
      setShowPaymentLink(false);
    } else {
      setShowPaymentLink(true);
      setTimeout(() => {
        setShowPaymentLink(false);
      }, 10000);
    }
  };

  let timeout = null;
  const toggleProductLink = () => {
    clearTimeout(timeout);
    if (showProductLink) {
      setShowProductLink(false);
    } else {
      timeout = setTimeout(() => {
        setShowProductLink(true);
      }, 10000);
      setShowProductLink(false);
    }
  };

  // const toggleDetailsShare = () => setShareUrl((prev) => !prev);
  const togglePaymentShare = () => setSharePaymentUrl((prev) => !prev);

  return (
    <QRWrapper onClick={"payment" ? togglePaymentLink : toggleProductLink}>
      <QRText>{text}</QRText>
      <QRImage src={qrImage} alt="QRCode" id="qrCodeEl" />
      <p className="url">{url}</p>
      {showPaymentLink && (
        <ShareDetailsWrapper>
          <div className="action" onClick={download}>
            <img src={DownloadIcon} alt="download-icon" />
            <p>Download to gallery</p>
          </div>
          <div className="action" onClick={copy}>
            <img src={CopyIcon} alt="copy-icon" />
            <p>Copy link to clipboard</p>
          </div>
          <div className="action" onClick={togglePaymentShare}>
            {sharePaymentUrl ? (
              <ShareSocialMedia url={url} />
            ) : (
              <>
                <img src={ShareIcon} alt="share-icon" />
                <p>Share on social media</p>
              </>
            )}
          </div>
        </ShareDetailsWrapper>
      )}
    </QRWrapper>
  );
};

export default QRCode;
